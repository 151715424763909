<template>
  <ExpansionPanels title="Frequently Asked Questions">
    <Panel>
      <template v-slot:header>
        1. Presentation elements are not displayed correctly
      </template>
      <template v-slot:content>
        <p>
          Is that the problem are you experiencing in the following snapshots?
        </p>
        <ImageContainer src="faq/faq11.png" />
        <p>(page 13-25 of the downloaded report)</p>
        <ImageContainer src="faq/faq12.png" />
        <p>(page 26 of the downloaded report)</p>
        <p>
          In that case is not a software problem, but a settings item. MAT tool
          has a feature to select the output compatibility of the report (the
          options are compatible with Google Slides or PowerPoint), please go to
          my account, and select the right output format you will need
          (depending on which Software you will intend to use to open the .pptx
          file) as the menu here:
        </p>
        <ImageContainer src="faq/faq13.png" />
        <ImageContainer src="faq/faq14.png" />
      </template>
    </Panel>
    <Panel>
      <template v-slot:header>
        2. Problems with the Download of the report
      </template>
      <template v-slot:content>
        <p>
          <b>Speed in download:</b> The new Download includes not only the
          report generation plotting but the aggregation of all answers, the
          calculation, and the Auto findings calculation and generation.
          Therefore depending on the Report setup, it could take some seconds
          until the file is downloaded. We have implemented a gray-out button
          activation (when clicking download) in order not to affect the current
          download or to increase the queue. Therefore please wait until the
          Report is downloaded. In any case, the speed of report download has
          increased from version 1.7.0 -> 2.0.0 to over 250%
        </p>
        <p>
          <b>The report file is not downloading:</b> In some rare cases, we have
          experienced some reports not downloading. This was related to server
          connections and time-out before version 1.7.0. This has been corrected
          in version 2.0.0. In any case, if you experience this download issue,
          most of the time a second download action might solve the issue. If
          you keep experiencing this anyway, please contact second-level support
          using our HELP -> Contact us and feel the template or write us an
          E-mail (MAT_support@appliedai.de). appliedAI has the option to extract
          your report and generate for you.
        </p>
        <ImageContainer src="faq/faq21.png" />
      </template>
    </Panel>
    <Panel>
      <template v-slot:header>3. Can I reopen a closed assessment?</template>
      <template v-slot:content>
        <p>
          No. Once you close the assessment there is no option to change
          organizational settings, add groups, or re-open the assessment.
        </p>
      </template>
    </Panel>
    <Panel>
      <template v-slot:header>4. Can I unmerge groups?</template>
      <template v-slot:content>
        <p>
          No. To ensure the MAT Tool database is using your last merge
          selection, MAT tool does not allow you to save different merging
          settings. Therefore please download the original unmerged slides
          selection if you want to keep the original unmerged report, as you
          will be not able to download it after you select a specific merge.
        </p>
      </template>
    </Panel>
    <Panel>
      <template v-slot:header
        >5. I have no Benchmark downloaded in my PowerPoint report.
      </template>
      <template v-slot:content>
        <p>If you have this text on page 26 of your downloaded report:</p>
        <ImageContainer src="faq/faq51.png" />
        <p>
          This is because either you did not select anything in the Benchmark
          menu, or your selection did not enough assessment as the warning might
          have shown you as follows:
        </p>
        <ImageContainer src="faq/faq52.png" />
        <p>
          In that case, please change the Benchmark selection in order to get
          this message:
        </p>
        <ImageContainer src="faq/faq53.png" />
        <p>
          Your benchmark information will be then downloaded in your report.
        </p>
      </template>
    </Panel>
    <Panel>
      <template v-slot:header>6. I cannot log in to the MAT tool.</template>
      <template v-slot:content>
        <p>
          Please use the reset option in the login or contact
          MAT_support@appliedai.de
        </p>
      </template>
    </Panel>
  </ExpansionPanels>
</template>

<script>
import ImageContainer from '@/components/ImageContainer';
import ExpansionPanels from '@/components/ExpansionPanels';
import Panel from '@/components/Panel';

export default {
  name: 'FAQ',
  components: {
    ImageContainer,
    ExpansionPanels,
    Panel,
  },
};
</script>

<!-- <style scoped>
.panel-container {
  margin: 0 auto;
  width: 900px;
  padding: 20px;
}

.panel-content {
  padding-top: 26px;
}

.v-expansion-panel-header--active {
  background-color: #00747b;
  color: #fff !important;
  font-weight: 500;
}

.v-expansion-panel-header--active >>> .mdi-chevron-down {
  color: #fff !important;
}
</style> -->
