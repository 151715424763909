<template>
  <div class="ClusterDetails">
    <div class="wrapper">
      <Header />
      <div class="main main-raised">
        <loading-indicator :is-loading="isLoading" />
        <div class="section challenge-content">
          <div class="container">
            <div class="md-layout">
              <div class="md-layout-item md-size-80 mx-auto align-center">
                <v-btn color="primary" plain :to="`/landing/${assessment_id}`">← Results</v-btn>
                <br v-if="report_data['cluster_name']!=='Ambition'"/>
                <v-btn v-if="report_data['cluster_name']!=='Ambition'" color="primary" plain @click="goToDimension(true)">← Previous dimension</v-btn>
                <v-btn v-if="report_data['cluster_name']!=='Steering'" color="primary" plain @click="goToDimension(false)" style="float:right">Next dimension →</v-btn>
                <div class="cluster-name" v-if="report_data && 'cluster_name' in report_data">
                  <h1>{{ report_data["cluster_name"] }}</h1>
                </div>
                <div class="cluster-heading" v-if="report_data && 'cluster_description' in report_data">
                  {{ report_data["cluster_description"] }}
                </div>
                <div class="cluster-table">
                  <md-table v-model="searched" md-sort="position" md-sort-order="asc" md-card>
                    <md-table-empty-state>
                      <v-progress-circular color="primary lighten-2" indeterminate />
                    </md-table-empty-state>
                    <md-table-row class="row-level" slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Required for level" class="col-level">
                        {{ item.level }}
                      </md-table-cell>
                      <md-table-cell class="col-description" md-label="Description">
                        {{ item.text }}
                      </md-table-cell>
                      <md-table-cell class="col-status" md-label="Status">
                        <statusChart :item="item" :color="color" :groupNames="groupNames" />
                      </md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
                <table-legend :groupNames="groupNames" :color="color"/>
                <div class="cluster-level-description" v-if="report_data && 'cluster_description_for_level' in report_data">
                  <h2>
                    Your current Level of Maturity is Level
                    {{ report_data["maturity_level"] }}
                  </h2>
                  {{ report_data["cluster_description_for_level"] }}
                </div>
                <div class="question-level-recommendations" v-if="report_data && 'question_level_recommendations' in report_data">
                  <h2>Based on your responses this is what we recommend:</h2>
                  <ul>
                    <li class="recommendation-listitem" v-for="question in report_data['question_level_recommendations']" :key="question.recommendation">
                      {{ question.recommendation }}
                    </li>
                  </ul>
                </div>
                <div class="resources" v-if="report_data && 'resources' in report_data">
                  <h2>Further readings and resources that might help you:</h2>
                  <ul>
                    <li v-for="resource in report_data['resources']" :key="resource.resource_id">
                      <a :href="resource.resource_link" class="resource-link" target="_blank">
                        {{ resource.resource_text }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="services" v-if="report_data && 'services' in report_data">
                  <h2>
                    Services that can help you to accelerate to the next
                    maturity level in {{ report_data["cluster_name"] }}:
                  </h2>
                  <div v-for="service in report_data['services']" :key="service.service_id">
                    <h3>{{ service.service_name }}</h3>
                    <h4>
                      by
                      <a v-bind:href="service.organization_website" target="_blank">
                        {{ service.organization_name }}
                      </a>
                    </h4>
                    <p v-html="service.service_description"></p>
                    <a v-if="service.service_mail" :href="'mailto:' + service.service_mail + '?subject=Please%20get%20in%20touch%20with%20me%20regarding ' + service.service_name + '&body=Add%20a%20message%20here%20or%20simply%20hit%20send.%20We%20will%20get%20back%20to%20you!'" target="_blank">
                      <v-btn>Get in touch</v-btn>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3/dist/d3.min';

import statusChart from '@/components/StatusChart';
import LoadingIndicator from '@/components/LoadingIndicator';
import Header from '@/components/Header';
import TableLegend from '@/components/TableLegend';

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  bodyClass: 'landing-page',
  components: {
    Header,
    statusChart,
    LoadingIndicator,
    TableLegend
  },
  props: {
    isLoading: Boolean,
  },
  mounted() {
    this.assessment_id = this.$route.params.assessment_id;
    this.cluster_id = this.$route.params.cluster_id;
    this.loadClusterDetailData();
    this.loadAutoReportData();
  },
  data: () => ({
    assessment_id: '',
    cluster_id: '',
    searched: [],
    groupNames: [],
    search: null,
    color: () => {},
  }),
  computed: {
    ...mapGetters({
      questionsAndRatingsByClusterId: 'assessments/questionsAndRatingsByClusterId',
    }),
    ...mapState({
      report_data: state => state.assessments.autoReportData,
      maturityData: state => state.assessments.maturityData,
    }),
  },
  methods: {
    ...mapActions({
      getMaturityData: 'assessments/getMaturityData',
      getClusterDetailData: 'assessments/getClusterDetailData',
      getAutoReportData: 'assessments/getAutoReportData',
    }),
    goToDimension(showPrevDimension) {
      let foundIndex = this.maturityData.findIndex((matData) => (matData.id === this.report_data['cluster_id']));
      this.$router.push({
        name: 'clusterdetails',
        params: {
          assessment_id: this.assessment_id,
          cluster_id: showPrevDimension? this.maturityData[foundIndex-1].id : this.maturityData[foundIndex+1].id
        }
      });
    },
    loadAutoReportData() {
      this.getAutoReportData({
        cluster_id: this.cluster_id,
        assessment_id: this.assessment_id,
      });
    },
    loadClusterDetailData() {
      const params = {
        cluster_id: this.cluster_id,
        assessment_id: this.assessment_id,
      };

      this.getMaturityData({
        assessment_id: this.assessment_id,
        cb: (() => this.getClusterDetailData({ 
          params, 
          cb:((data) => {
            const getGroupNames = (data) => Object.keys(data);
            this.groupNames = getGroupNames(data[0].rating);
            this.color = d3.scaleOrdinal(d3.schemeTableau10).domain(this.groupNames);
            this.searched = data;
          })
        }))
      });
    },
  },
};

/* Participants View  */

// workaround to be able to also include chart.js in generated PDFs with wkhtmltopdf
// https://stackoverflow.com/questions/30888530/render-js-charts-in-pdf-using-wkhtmltopdf-tool

// consider alternative to: wkhtmltopdf
// https://django-puppeteer-pdf.readthedocs.io/en/latest/usage.html

// ('use strict');
// (function (setLineDash) {
//   CanvasRenderingContext2D.prototype.setLineDash = function () {
//     if (!arguments[0].length) {
//       arguments[0] = [1, 0];
//     }
//     // Now, call the original method
//     return setLineDash.apply(this, arguments);
//   };
// })(CanvasRenderingContext2D.prototype.setLineDash);
// Function.prototype.bind =
//   Function.prototype.bind ||
//   function (thisp) {
//     var fn = this;
//     return function () {
//       return fn.apply(thisp, arguments);
//     };
//   };
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}
.md-card {
  margin: auto;
  vertical-align: center;
}
.md-field {
  max-width: 400px;
}
.md-table {
  width: 100% !important;
  height: 100% !important;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.row-level {
  text-align: left;
}
.col-level {
  text-align: center;
}
.col-status {
  width: 320px;
}
.recommendation-listitem {
  margin-bottom: 10px;
}
.resource-link::after {
  font-family: "Material Design Icons", ui-rounded, monospace;
  content: "\F03CC";
  color: grey;
  margin-left: 0.2em;
}
.resource-link:hover {
  color: #00747a !important;
}
.cluster-table {
  margin-top: 32px;
}
</style>
