<template>
  <v-icon :title="title">{{ icon }}</v-icon>
</template>

<script>
export default {
  name: 'AssessmentStatus',
  props: {
    status: String,
  },
  computed: {
    title: function () {
      return this.status;
    },
    icon: function () {
      switch (this.status) {
      case 'created':
        return 'mdi-file-outline';
      case 'running':
        return 'mdi-clock-fast';
      case 'completed':
        return 'mdi-check-all';
      }
      return 'mdi-warning';
    },
  },
};
</script>

<style scoped></style>
