var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { attrs: { id: "material-kit" } }, [
      !_vm.$auth.loading
        ? _c(
            "div",
            { class: { "nav-open": _vm.NavbarStore.showNavbar } },
            [
              _c("router-view", {
                attrs: {
                  name: "header",
                  authenticated: _vm.$auth.isAuthenticated,
                  userRole: _vm.$auth.role
                },
                on: {
                  doLogin: _vm.doLogin,
                  doLogout: _vm.doLogout,
                  goToProfile: _vm.goToProfile
                }
              }),
              _c(
                "div",
                [
                  _c("router-view", {
                    key: _vm.$route.path,
                    attrs: {
                      authenticated: _vm.$auth.isAuthenticated,
                      isLoading: _vm.isLoading,
                      userRole: _vm.$auth.role
                    },
                    on: { goToProfile: _vm.goToProfile }
                  })
                ],
                1
              ),
              _c("router-view", { attrs: { name: "footer" } })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }