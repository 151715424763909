var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "main main-raised" },
        [
          _c("loading-indicator", { attrs: { "is-loading": _vm.loading } }),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "v-responsive",
                { staticClass: "md-alignment-centered" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_vm._v("← Back")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-50 md-small-size-100 mx-auto"
                    },
                    [
                      _c("div", [
                        _c("h3", { staticClass: "text-primary" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.urlFormType ===
                                  _vm.formTypes.certifiedPartner
                                  ? "Addition of certified partner"
                                  : "Addition of national host"
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "form",
                          {
                            staticClass: "md-layout",
                            attrs: { novalidate: "" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.createOrganization.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "md-card",
                              [
                                _c(
                                  "md-card-content",
                                  [
                                    _c(
                                      "md-field",
                                      {
                                        class:
                                          !_vm.$v.organizationName.required &&
                                          _vm.submitStatus === "ERROR"
                                            ? "md-invalid"
                                            : ""
                                      },
                                      [
                                        _c("label", [
                                          _vm._v("Organization name:")
                                        ]),
                                        _c("md-input", {
                                          model: {
                                            value: _vm.organizationName,
                                            callback: function($$v) {
                                              _vm.organizationName = $$v
                                            },
                                            expression: "organizationName"
                                          }
                                        }),
                                        !_vm.$v.organizationName.required &&
                                        _vm.submitStatus === "ERROR"
                                          ? _c(
                                              "span",
                                              { staticClass: "md-error" },
                                              [
                                                _vm._v(
                                                  "Organization name is a required field"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm.urlFormType ===
                                    _vm.formTypes.certifiedPartner
                                      ? _c(
                                          "md-field",
                                          { staticClass: "custom-no-border" },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.nationalHostsWithNA,
                                                name: "affiliatedNationalHost",
                                                "item-value": "host_name",
                                                "item-text": function(item) {
                                                  return item.host_name
                                                },
                                                label:
                                                  "Affiliated national host:",
                                                "persistent-hint": "",
                                                "persistent-placeholder": ""
                                              },
                                              on: {
                                                change: _vm.setSelectedCountry
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "md-field",
                                      {
                                        class:
                                          !_vm.$v.country.required &&
                                          _vm.submitStatus === "ERROR"
                                            ? "md-invalid custom-no-border"
                                            : "custom-no-border"
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.countries,
                                            name: "country",
                                            "item-text": "name",
                                            "item-value": "code",
                                            label: "Select county",
                                            "persistent-hint": "",
                                            "persistent-placeholder": "",
                                            disabled:
                                              _vm.countryDropdownDisabled
                                          },
                                          model: {
                                            value: _vm.country,
                                            callback: function($$v) {
                                              _vm.country = $$v
                                            },
                                            expression: "country"
                                          }
                                        }),
                                        !_vm.$v.country.required &&
                                        _vm.submitStatus === "ERROR"
                                          ? _c(
                                              "span",
                                              { staticClass: "md-error" },
                                              [_vm._v("Please select country")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-field",
                                      {
                                        class:
                                          (!_vm.$v.contactPersonsEmail
                                            .required ||
                                            !_vm.$v.contactPersonsEmail.mail) &&
                                          _vm.submitStatus === "ERROR"
                                            ? "md-invalid"
                                            : ""
                                      },
                                      [
                                        _c("label", [
                                          _vm._v("Contact person's email:")
                                        ]),
                                        _c("md-input", {
                                          model: {
                                            value: _vm.contactPersonsEmail,
                                            callback: function($$v) {
                                              _vm.contactPersonsEmail = $$v
                                            },
                                            expression: "contactPersonsEmail"
                                          }
                                        }),
                                        !_vm.$v.contactPersonsEmail.required &&
                                        _vm.submitStatus === "ERROR"
                                          ? _c(
                                              "span",
                                              { staticClass: "md-error" },
                                              [
                                                _vm._v(
                                                  "Please insert email address"
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.$v.contactPersonsEmail.email &&
                                        _vm.submitStatus === "ERROR"
                                          ? _c(
                                              "span",
                                              { staticClass: "md-error" },
                                              [
                                                _vm._v(
                                                  "Please insert valid email address"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "md-card-actions",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          type: "submit"
                                        }
                                      },
                                      [_vm._v("Save")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("br")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }