<template>
  <div>
    <h4>Overview of participations</h4>
    <bar-chart-participation :chartData="participantsdata"/>
    <div id="tooltip"></div>
  </div>
</template>

<script>
import { BarChartParticipation } from '@/components';

export default {
  name: 'OverviewOfParticipantsChart',
  components: {
    BarChartParticipation
  },
  props: {
    participantsdata: { type: Object, required: true }
  },
};
</script>
