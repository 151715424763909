<template>
  <v-progress-linear
    :style="{ visibility: isLoading ? 'visible' : 'hidden' }"
    indeterminate
    color="primary"
  />
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    isLoading: Boolean,
  },
};
</script>

<style scoped></style>
