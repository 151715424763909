import { http } from '@/utils';

export default {
  getOrganizations() {
    return http.get('api/v1/users/partner_organizations/');
  },
  getOrganization(id) {
    return http.get(`api/v1/users/partner_organizations/${id}`);
  },
  getOrganizationCollaborators() {
    return http.get('api/v1/users/collaborators/');
  },
  updateOrganization(id, payload) {
    return http.patch(`api/v1/users/partner_organizations/${id}/`, payload);
  },
  changeUserMail(oldMail, newMail) {
    return http.post(`api/v1/users/partner_organizations/${oldMail}/`, newMail);
  },
  createOrganization(payload) {
    return http.post('api/v1/users/partner_organizations/', payload);
  },
  getListOfCountries() {
    return http.get('api/v1/users/partner_countries/');
  },
  getAllNationalHosts() {
    return http.get('api/v1/users/national_hosts/');
  },
  getUsersForOrganization(organization_id) {
    return http.get(`api/v1/users/organizations/${organization_id}/`);
  }
};

  