<template>
 <div class="wrapper">
   <Header />
    <div class="main main-raised">
      <loading-indicator :is-loading="user.loading" />
      <div class="container">
        <v-responsive class="md-alignment-centered">
          <v-btn color="primary" plain @click="$router.go(-1)">← Back</v-btn>
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <div>
                <h3 class="text-primary">{{ user.role }} / {{ user.name }}</h3>
                <form novalidate class="md-layout" @submit.prevent="updateDetailsForOtherUser">
                  <md-card>
                    <md-card-content>
                      <md-field>
                        <label>Name:</label>
                        <md-input v-model="username"></md-input>
                      </md-field>
                      <md-field>
                        <label>E-mail address:</label>
                        <md-input disabled v-model="user.email"></md-input>
                      </md-field>
                      <md-field>
                        <label for="selectedRole">Role</label>
                        <md-select name="selectedRole" id="selectedRole" v-model="selectedRole" >
                          <md-option value="Account Master">Account Master</md-option>
                        </md-select>
                      </md-field>
                    </md-card-content>
                    <md-card-actions>
                      <v-btn color="primary" type="submit">Update</v-btn>
                    </md-card-actions>
                  </md-card>
                </form>
              </div>
            </div>
        </v-responsive>
      </div>
    </div>
    <snackbar />
  </div>
</template>

<script>
import backend_connector from '@/mixins/backend_connector';
import { validationMixin } from 'vuelidate';
import { LoadingIndicator, Header, Snackbar } from '@/components/';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'UserChangeAccountType',
  mixins: [backend_connector, validationMixin],
  components: {
    LoadingIndicator,
    Header,
    Snackbar
  },
  bodyClass: 'index-page',
  data: () => ({
    selectedRole: null,
    newUsername: '',
  }),
  created() {
    this.getUserAccountById(this.$route.params.user_id);
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    username: {
      get() {
        return this.user.name;
      },
      set(value) {
        this.newUserName = value;
      }
    },
  },
  methods: {
    ...mapActions({
      getUserAccountById: 'user/getUserById',
      showSnackBarAction: 'assessments/showSnackBar',
      updateDetailsForOtherUserAction: 'user/updateDetailsForOtherUser',
    }),
    async updateDetailsForOtherUser () {
      let payload = {};
      if (this.newUserName!==this.user.name){
        payload.item_name = 'name';
        payload.item_value = this.newUserName;
      }
      if(this.selectedRole) {
        payload.item_role = true;
      }
      this.updateDetailsForOtherUserAction({
        userId: this.$route.params.user_id, 
        payload, 
        cb: () => {
          this.showSnackBarAction('Update successfull!');
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        }});
    },
  }
};
</script>
