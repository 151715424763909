import { getInstance } from './index';

export const authGuard = (to, from, next) => {
  const authService = getInstance();
  const { authorize } = to.meta;
  const { name, params } = to;
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated){
      const currentUser = authService.getUserRole();
      if (!authorize.includes(currentUser)) {
        return next({ path: '/invalid' });
      } if (name==='createOrganization' && params.organization_type!=='national-host' && params.organization_type!=='certified-partner') {
        return next({ path: '/pageNotFound' });
      } if (name==='userAccountCreate' && params.account_type!=='account-master' && params.account_type!=='account-user') {
        return next({ path: '/pageNotFound' });
      } if (name==='successfulCreation' && performance.getEntriesByType('navigation')[0].type==='reload') {
        return next();
      } if (name==='successfulCreation' && from.name!=='config') {
        return next({ path: '/pageNotFound' });
      } else {
        return next();
      }
    }
  
    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};