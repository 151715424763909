import { Roles } from '@/auth/roles';

export const getOrganizationName = (state) => {
  return state.organization.details.name;
};

export const getOrganizationCountry = (state) => {
  return state.organization.details.country;
};

export const getOrganizationContactPerson = (state) => {
  return state.organization.details.contact_person;
};

export const getOrganizationType = (state) => {
  return state.organization.details.type;
};

export const getMasterAccounts = (state) => {
  return state.organization.users? state.organization.users.filter(user => user.role===Roles.AccountMaster) : [];
};

export const getUserAccounts = (state) => {
  return state.organization.users? state.organization.users.filter(user => user.role===Roles.AccountUser) : [];
};

export const allOrganizations = (state) => { 
  return state.list;
};
   
export const getOrganization = (state, name) => { 
  return state.list.filter(org => org.name == name)[0];
};

export const getUserTypeAccountsPerOrganization = (state, orgName, userType) => {
  let currentOrganization = state.list.filter(org => org.name == orgName)[0];
  return currentOrganization.accounts.filter(account => account.type === userType);
};
  
