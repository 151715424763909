<template>
<div>
    <div v-if="userRole!=='Admin'">
        <Index :userRole="userRole" :isLoading="isLoading" />
    </div>
    <div v-if="userRole==='Admin'">
        <AccountDetails :userRole="userRole"/>
    </div>  
</div>
</template>

<script>
import AccountDetails from '@/views/AccountDetails.vue';
import Index from '@/views/Index.vue';

export default {
  name: 'MainPage',
  components: {
    AccountDetails,
    Index
  },
  props: {
    isLoading: Boolean,
    userRole: String
  },
};
</script>

