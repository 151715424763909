<template>
  <div>
    <md-card v-if="groups.length > 0">
      <md-card-content>
        <md-table v-model="groups" style="background-color: rgba(255, 255, 255, 0.4)">
           <md-table-row>
            <md-table-head md-numeric>Name</md-table-head>
            <md-table-head>Description</md-table-head>
            <md-table-head>Version</md-table-head>
            <md-table-head v-if="status === 'running'">Action</md-table-head>
            <md-table-head v-if="status === 'completed'">#participants</md-table-head>
            <md-table-head v-if="status === 'completed'">Action</md-table-head>
          </md-table-row>
          <md-table-row slot="md-table-row" v-for="(item, index) in groups" v-bind:key="item.group_name" :class="getClass(item)" md-fixed-header>
            <md-table-cell md-label="Group Name">{{ item.group_name }}</md-table-cell>
            <md-table-cell md-label="Description">{{ item.group_desc }}</md-table-cell>
            <md-table-cell md-label="Survey Version">{{ item.version_name }}</md-table-cell>
            <md-table-cell v-if="status === 'running'" md-label="Collector URL">
              <v-btn color="success" @click="clipboard(item.collector_url)" :disabled="!areTermsAccepted">
                Copy
              </v-btn>
            </md-table-cell>
            <md-table-cell v-if="status === 'created'">
              <div class="md-lg delete_cell">
                <v-btn icon class="ma-2" color="red" dark @click="confirmDeletion(item.group_id, item.group_name, item.collector_id)">
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </div>
            </md-table-cell>
            <md-table-cell v-if="status === 'completed'" md-label="Participants:">{{ item.group_participation_count }}</md-table-cell>
            <md-table-cell v-if="status === 'completed'">
              <md-field>
              <label for="mergeGropus">Merge group with:</label>
              <md-select v-model="groupToMergeWith[index].groupToMergeWith" @md-selected="onGroupselect(index, item.group_id)" :disabled="filterGroupsByVersionName(item.group_id, item.version_name).length===0">
                <md-option v-for="gr in filterGroupsByVersionName(item.group_id, item.version_name)" :value="gr.group_id" v-bind:key="gr.group_id">{{ gr.group_name }}</md-option>
              </md-select>
              </md-field>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>

    <form novalidate @submit.prevent="validateGroupForm" v-if="!checkIfSuccessPage() && status === 'created'">
      <md-card class="md-layout-item">
        <md-card-content>
          <div class="md-title">New Group</div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item group-name md-small-size-100">
              <md-field :class="getValidationClass('group_name')">
                <label for="group_name">Group Name</label>
                <md-input id="group_name" name="group_name" v-model="form.group_name" :disabled="sending" maxlength='20'/>
                <span class="md-error" v-if="!$v.form.group_name.required">Group name is required</span>
                <span class="md-error" v-if="!$v.form.group_name.maxLength">Group name is too long. Please insert maximum of 20 characters</span>
                <span class="md-error" v-if="!$v.form.group_name.noDotsInGroupName">Group name should not contain any dots</span>
                
              </md-field>
            </div>
            <div v-if="userRole!==Roles.Admin" class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('without_tech_questions')">
                <label for="without_tech_questions">Group Version</label>
                <md-select name="without_tech_questions" id="without_tech_questions" v-model="form.without_tech_questions" md-dense :disabled="sending">
                  <md-option key="tech" :value="0">including technical questions</md-option>
                  <md-option key="non-tech" :value="1">not including technical questions</md-option>
                </md-select>
                <span class="md-error">Group version is a required field</span>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('group_desc')">
                <label for="group_desc">Group Description</label>
                <md-input id="group_desc" name="group_desc" v-model="form.group_desc" :disabled="sending" />
                <span class="md-error" v-if="!$v.form.group_desc.required">Group description is a required field</span>
              </md-field>
            </div>
          </div>
          <div v-if="userRole===Roles.Admin" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('collector_id')">
                <label for="collector_id">Collector ID</label>
                <md-input id="collector_id" type="number" name="collector_id" v-model="form.collector_id" :disabled="sending" />
                <span class="md-error" v-if="!$v.form.collector_id.required">Collector ID is a required field</span>
              </md-field>
            </div>
          </div>
          <v-progress-linear indeterminate v-if="sending" />
        </md-card-content>
        <md-card-actions>
          <v-btn color="primary" type="submit" :disabled="sending">Create Group</v-btn>
        </md-card-actions>
      </md-card>
    </form>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

  <popup-modal ref="mergeGroupsPopUp">
    <form novalidate @submit.prevent="validateMergedGroupForm">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <h3>Please insert values for the merged group</h3>
        </div>
        <div class="md-layout-item md-size-100">
          <md-field :class="getValidationClassMergeGroupsForm('merged_group_name')">
            <label for="merged_group_name">Name:</label>
            <md-input id="merged_group_name" name="merged_group_name" v-model="mergeGroupForm.merged_group_name" maxlength='20' />
            <span class="md-error" v-if="!$v.mergeGroupForm.merged_group_name.required" >Group name is required</span>
            <span class="md-error" v-if="!$v.mergeGroupForm.merged_group_name.maxLength" >Group name can't be longer than 20 characters</span>
            <span class="md-error" v-if="!$v.mergeGroupForm.merged_group_name.noDotsInGroupName">Group name should not contain any dots</span>
          </md-field>
        </div>
        <div class="md-layout-item md-size-100">
          <md-field :class="getValidationClassMergeGroupsForm('merged_group_description')">
            <label for="merged_group_description">Description:</label>
            <md-input id="merged_group_description" name="merged_group_description" v-model="mergeGroupForm.merged_group_description" />
            <span class="md-error" v-if="!$v.mergeGroupForm.merged_group_description.required" >Group description is required</span>
            <span class="md-error" v-if="!$v.mergeGroupForm.merged_group_description.maxLength" >Group description can't be longer than 500 characters</span>
          </md-field>
        </div>
        <div class="md-layout-item md-layout md-size-100 md-alignment-center-space-between top-margin">
          <v-btn class="md-layout-item md-size-30" color="warning" @click="_cancel">Cancel</v-btn>
          <v-btn class="md-layout-item md-size-30" color="success"  type="submit">Merge groups</v-btn>
        </div>
      </div>
    </form>
  </popup-modal>
  <snackbar />
  </div>
</template>

<script>
import { ConfirmDialogue, PopupModal, Snackbar } from './';
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { logger } from '@/utils';
import { Roles } from '@/auth/roles';


const noDotsInGroupName = (value) => value.indexOf('.') ==-1;

export default {
  name: 'GroupTable',
  components: { ConfirmDialogue, PopupModal, Snackbar },
  mixins: [validationMixin],
  props: {
    sme_version: {
      type: Number,
      // required: true, needs to be selected first so commented out
    },
    assessment_id: {
      type: Number,
      required: true,
    },
    // groups should only be changeable when assessment is active
    status: {
      type: String,
      required: true,
    },
    areTermsAccepted: {
      type: Boolean,
      required: true,
    },
    userRole: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapState({
      groups: state => state.assessments.groups,
      sending: state => state.assessments.loading,
      snackbarText: state => state.assessments.snackbarText,
      showSnackbar:state => state.assessments.showSnackbar,
    }),
    Roles() {
      return Roles;
    },
  },
  data: () => ({
    answeredSaved: false,
    lastUser: null,
    userSaved: false,
    selected: {},
    form: {
      group_name: '',
      without_tech_questions: null,
      group_desc: '',
      group_size: 0,
      group_participant_goal: 0,
      collector_id: ''
    },
    viewOrConfig: true,
    groupToMergeWith: [],
    tableRowIndex: null,
    mergeGroupForm: {
      merged_group_name: '',
      merged_group_description: null
    }
  }),
  mounted() {
    // fetch list and populate groups
    // with only groups that are available in this assessment
    this.getGroupsAction({ 
      assessment_id: this.assessment_id,
      cb: (response) => {
        if (response.length>0){
          this.groupToMergeWith = response.map(res => {
            return {...res};
          }); 
        }
      }});
  },
  // TODO: more in-depth validations can be done here.
  // see docs of vuelidate for more options.

  // TODO: sme_version needs to be integrated into validation.
  // the input element is in Config.vue (parent) however.
  validations: {
    form: {
      group_name: {
        required,
        maxLength: maxLength(20),
        noDotsInGroupName,
      },
      without_tech_questions: {
        required: requiredIf(function () {
          return this.userRole!==Roles.Admin;
        }),
      },
      group_desc: {
        required,
      },
      collector_id: {
        required: requiredIf(function () {
          return this.userRole===Roles.Admin;
        })
      },
    },
    mergeGroupForm: {
      merged_group_name: {
        required,
        maxLength: maxLength(20),
        noDotsInGroupName,
      },
      merged_group_description: {
        required,
        maxLength: maxLength(500),
      },
    }
  },
  methods: {
    ...mapActions({
      getGroupsAction: 'assessments/getGroups',
      createGroupAction: 'assessments/createGroup',
      deleteGroupAction: 'assessments/deleteGroup',
      hideSnackBarAction: 'assessments/hideSnackBar',
      showSnackBarAction: 'assessments/showSnackBar',
      mergeGroupAction: 'assessments/mergeGroups'
    }),
    
    async onGroupselect(index, groupToBeMerged) {
      if(this.tableRowIndex === index && !this.groupToMergeWith[this.tableRowIndex].groupToMergeWith) return;
      this.tableRowIndex = index;
      const ok = await this.show();
      
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        this.mergeGroupAction({ payload: {
          new_group_name: this.mergeGroupForm.merged_group_name,
          new_group_description: this.mergeGroupForm.merged_group_description,
          assessment_id: this.assessment_id,
          root_group_id: groupToBeMerged,
          selected_group_id: this.groupToMergeWith[index].groupToMergeWith
        }, cb: (() => {
          this.showSnackBarAction('Groups merged successfully!');
          this.$emit('onMerge');
          this.getGroupsAction({ 
            assessment_id: this.assessment_id,
            cb: (response) => {
              this.groupToMergeWith = response.map(res => {
                return {...res};
              }); 
            }});
          
        })
        });
      }

    },
    filterGroupsByVersionName(groupId, versionName){
      return this.groups.filter(group => group.group_id!==groupId && group.version_name === versionName);
    },
    checkIfSuccessPage() {
      return window.location.pathname.includes('success');
    },
    hideSnackBar(){
      this.hideSnackBarAction();
    },
    createGroup() {
      logger.info('Creating group, ' + this.form.group_name + ', for assessment: ' + this.assessment_id );
      let isUnique = true;
      this.groups.map(group => {
        if(group.group_name===this.form.group_name) {
          isUnique = false;
          return;
        }
      });
      if(!isUnique) {
        this.showSnackBarAction('Group name must be unique!');
        return;
      }
      var payload;
      if (this.userRole===Roles.Admin){
        payload = {
          assessment_id: this.assessment_id,
          group_name: this.form.group_name,
          // characteristics of each version in the DB
          // without_tech_questions: this.form.without_tech_questions,
          // passed from form in parent
          sme_version: 0, // always go with big company for now, sme version not integrated yet. Otherwise: this.sme_version,
          group_desc: this.form.group_desc,
          group_size: this.form.group_size,
          group_participant_goal: this.form.group_participant_goal,
          collector_id: parseInt(this.form.collector_id)
        };
      } else {
        payload = {
          assessment_id: this.assessment_id,
          group_name: this.form.group_name,
          without_tech_questions: this.form.without_tech_questions,
          sme_version: 0,
          group_desc: this.form.group_desc,
          group_size: this.form.group_size,
          group_participant_goal: this.form.group_participant_goal,
        };
      }
      this.createGroupAction(payload);
      this.clearForm();
    },
    async confirmDeletion(group_id, group_name, collector_id) {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Delete Group',
        message:
          'Are you sure you want to delete the group ' +
          group_name +
          ' from this assessment?',
        okButton: 'Yes, delete group',
        cancelButton: 'No, go back',
      });
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        this.delGroup(group_id, collector_id);
      }
    },
    delGroup(group_id, collector_id) {
      logger.info('Deleting group, ' + group_id + ', from assessment: ' + this.assessment_id);
      const query_params = {
        group_id: group_id,
        assessment_id: this.assessment_id,
        collector_id: collector_id,
      };
      this.deleteGroupAction(query_params);
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
    },
    getValidationClassMergeGroupsForm(fieldName) {
      const field = this.$v.mergeGroupForm[fieldName];
      if (field) {
        return { 'md-invalid': field.$invalid && field.$dirty, };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.group_name = '';
      this.form.without_tech_questions = null;
      this.form.group_desc = '';
      this.form.group_size = 0;
      this.form.group_participant_goal = 0;
      this.form.collector_id = '';
    },
    validateGroupForm() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.createGroup();
      }
    },
    validateMergedGroupForm() {
      this.$v.mergeGroupForm.$touch();
      if (!this.$v.mergeGroupForm.$invalid) {
        this.$v.$reset();
        this.resolvePromise(true);
        this.$refs.mergeGroupsPopUp.close();
      }
    },
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3,
    }),
    clipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // this.showSnackbar = true;
          this.showSnackBarAction('Survey link copied to clipboard.');
        })
        .catch((err) => {
          alert('Error in copying text: ' + err);
        });
    },
    show() {
      this.$refs.mergeGroupsPopUp.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.$v.$reset();
      this.mergeGroupForm.merged_group_name = '';
      this.mergeGroupForm.merged_group_description = null;
      this.groupToMergeWith[this.tableRowIndex].groupToMergeWith = null;
      this.$refs.mergeGroupsPopUp.close();
      this.rejectPromise(new Error('User cancelled the dialogue'));
      
    },
  },
};
</script>

<style lang="scss">
.md-count {
  top: 10px !important;
}
</style>
