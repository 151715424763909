import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import Axios from 'axios';
import { http, logger } from '@/utils';
import * as Sentry from '@sentry/vue';
import { Roles } from './roles';
import router from '@/router';

/* Action to perform after authentication */
const onRedirectCallback = (appState) => {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        role: '',
        organization: null
      };
    },
    methods: {
      getUserRole(){
        let roles = [];
        const userRole = this.user[process.env.VUE_APP_AUTH0_AUDIENCE + '/roles'][0];
        Object.values(Roles).forEach(val => roles.push(val));
        if (roles.includes(userRole)) {
          this.role = userRole;
          return userRole;
        } else {
          throw new Error('Invalid user role from auth0');
        }
      },
      getUserOrganization(){
        const userOrg = this.user[process.env.VUE_APP_AUTH0_AUDIENCE + '/organization']? this.user[process.env.VUE_APP_AUTH0_AUDIENCE + '/organization'][0] : null;
        this.organization = userOrg;
        return userOrg;
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        let result = this.auth0Client.logout({
          ...o,
          returnTo: window.location.origin,
        });

        if (result === undefined) {
          result = Promise.resolve();
        }

        result.then(() => {
          console.log('Clearing local state');
          Axios.defaults.headers.common['Authorization'] = undefined;
          Sentry.configureScope((scope) => scope.setUser(null));
        });
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri,
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const {appState} = await this.auth0Client.handleRedirectCallback();

          this.error = null;

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
        logger.error(e);
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (this.isAuthenticated) {
          const token = await this.auth0Client.getTokenSilently();
          if (token) {
            http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          }
        }
        this.user = await this.auth0Client.getUser();
        if (this.user && process.env.VUE_APP_SENTRY_LOG_PII === 'true') {
          Sentry.setUser({
            id: this.user.sub,
            username: this.user.sub,
            email: this.user.email,
          });
        }
        this.loading = false;
      }
    }
  });
  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
