var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "main main-raised" }, [
          _c("h2", { staticClass: "text-center" }, [_vm._v("Licenses")]),
          _c("p", { staticClass: "md-layout-item" }, [
            _vm._v(
              " This tool has been made using a variety of third-party open-source components. We thank the authors and acknowledge their contributions on this page. "
            )
          ]),
          _c(
            "div",
            { staticClass: "card-container" },
            _vm._l(_vm.dependencies, function(dependency) {
              return _c(
                "v-card",
                {
                  key: dependency.name + dependency.version,
                  staticClass: "dependency-card"
                },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(dependency.name) + " "),
                    dependency.repository
                      ? _c(
                          "a",
                          {
                            staticClass: "repository-link",
                            attrs: {
                              href: dependency.repository,
                              target: "_blank"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  "aria-label": "Repository link",
                                  "aria-hidden": "false",
                                  role: "img"
                                }
                              },
                              [_vm._v(" mdi-open-in-new ")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  dependency.author
                    ? _c("v-card-subtitle", [
                        _vm._v(_vm._s(dependency.author) + " ")
                      ])
                    : _vm._e(),
                  _c(
                    "v-card-text",
                    [
                      _vm._v(" Licensed under "),
                      _c("strong", [_vm._v(_vm._s(dependency.license))]),
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "600" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    dependency.license && dependency.licenseText
                                      ? _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [_vm._v(" Show license text ")]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _vm._v(_vm._s(dependency.license))
                              ]),
                              _c("v-card-text", [
                                _c("p", {
                                  staticClass: "license-text",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.reflowHtml(dependency.licenseText)
                                    )
                                  }
                                })
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }