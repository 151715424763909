<template>
  <parallax class="page-header header-filter" :style="headerStyle">
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="image-wrapper">
          <div class="brand">
            <h1>Maturity Assessment Tool</h1>
            <h3>Assess your organization's usage of AI. By appliedAI.</h3>
          </div>
        </div>
      </div>
    </div>
  </parallax>
</template>
<script>
export default {
  name: 'Header',
  props: {
    image: {
      type: String,
      default: require('@/assets/img/vue-mk-header.jpg'),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
};
</script>
