// mixin object for commonly used functions to read/write data from/to the backend

import assessments from '@/api/assessments';

export default {
  methods: {
    async assessmentValidForStart(assessment_id) {
      const questionsAnswered = await allBenchmarkingQuestionsAnswered(
        assessment_id
      );
      console.log('Questions answered', questionsAnswered);
      const groupsExist = await groupsExistForAssessment(assessment_id);
      console.log('Groups exist', groupsExist);

      const messages = [];
      if (!questionsAnswered) {
        const msg =
          'You have to answer all questions about your organization before you can start the assessment.';
        messages.push(msg);
      }
      if (!groupsExist) {
        const msg =
          'You have to create at least one group for this assessment before it can be started.';
        messages.push(msg);
      }

      if (questionsAnswered && groupsExist) {
        return true;
      } else {
        return messages.join(' ');
      }
    },
  },
};

// some functions that should not be called from frontend components directly

function allBenchmarkingQuestionsAnswered(assessment_id) {
  return new Promise((resolve, reject) => {
    assessments.getQuestions(assessment_id)
      .then((response) => {
        if (response.status !== 200) {
          if (response.status === 404) {
            resolve(false);
            return;
          } else {
            reject();
          }
        }

        //check if status is 200, but the returned array is this detail thingy
        if ('detail' in response.data) {
          resolve(false);
          return;
        }

        for (const data of response.data) {
          if (data['question_id'] !== 5) {
            if (data['answer_text'] === 'not_answered') {
              resolve(false);
              return;
            }
          }
        }
        resolve(true);
      })
      .catch((error) => {
        console.debug(
          'Error when checking benchmarking questions and answers for assessment: ',
          error
        );
        reject(error);
      });
  });
}

function groupsExistForAssessment(assessment_id) {
  return new Promise((resolve, reject) => {
    assessments.getGroups(assessment_id)
      .then((response) => {
        switch (response.status) {
        case 200:
          if ('detail' in response.data || response.data.length === 0) {
            resolve(false);
            return;
          }
          resolve(true);
          return;
        default:
          resolve(false);
        }
      })
      .catch((error) => {
        console.debug('Error when getting groups for assessment: ', error);
        reject(error);
      });
  });
}
