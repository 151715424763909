var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.groups.length > 0
        ? _c(
            "md-card",
            [
              _c(
                "md-card-content",
                [
                  _c(
                    "md-table",
                    {
                      staticStyle: {
                        "background-color": "rgba(255, 255, 255, 0.4)"
                      },
                      model: {
                        value: _vm.groups,
                        callback: function($$v) {
                          _vm.groups = $$v
                        },
                        expression: "groups"
                      }
                    },
                    [
                      _c(
                        "md-table-row",
                        [
                          _c("md-table-head", { attrs: { "md-numeric": "" } }, [
                            _vm._v("Name")
                          ]),
                          _c("md-table-head", [_vm._v("Description")]),
                          _c("md-table-head", [_vm._v("Version")]),
                          _vm.status === "running"
                            ? _c("md-table-head", [_vm._v("Action")])
                            : _vm._e(),
                          _vm.status === "completed"
                            ? _c("md-table-head", [_vm._v("#participants")])
                            : _vm._e(),
                          _vm.status === "completed"
                            ? _c("md-table-head", [_vm._v("Action")])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._l(_vm.groups, function(item, index) {
                        return _c(
                          "md-table-row",
                          {
                            key: item.group_name,
                            class: _vm.getClass(item),
                            attrs: {
                              slot: "md-table-row",
                              "md-fixed-header": ""
                            },
                            slot: "md-table-row"
                          },
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Group Name" } },
                              [_vm._v(_vm._s(item.group_name))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Description" } },
                              [_vm._v(_vm._s(item.group_desc))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Survey Version" } },
                              [_vm._v(_vm._s(item.version_name))]
                            ),
                            _vm.status === "running"
                              ? _c(
                                  "md-table-cell",
                                  { attrs: { "md-label": "Collector URL" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "success",
                                          disabled: !_vm.areTermsAccepted
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.clipboard(
                                              item.collector_url
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Copy ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.status === "created"
                              ? _c("md-table-cell", [
                                  _c(
                                    "div",
                                    { staticClass: "md-lg delete_cell" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: {
                                            icon: "",
                                            color: "red",
                                            dark: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.confirmDeletion(
                                                item.group_id,
                                                item.group_name,
                                                item.collector_id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [_vm._v("mdi-delete")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm.status === "completed"
                              ? _c(
                                  "md-table-cell",
                                  { attrs: { "md-label": "Participants:" } },
                                  [
                                    _vm._v(
                                      _vm._s(item.group_participation_count)
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.status === "completed"
                              ? _c(
                                  "md-table-cell",
                                  [
                                    _c(
                                      "md-field",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "mergeGropus" } },
                                          [_vm._v("Merge group with:")]
                                        ),
                                        _c(
                                          "md-select",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.filterGroupsByVersionName(
                                                  item.group_id,
                                                  item.version_name
                                                ).length === 0
                                            },
                                            on: {
                                              "md-selected": function($event) {
                                                return _vm.onGroupselect(
                                                  index,
                                                  item.group_id
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.groupToMergeWith[index]
                                                  .groupToMergeWith,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.groupToMergeWith[index],
                                                  "groupToMergeWith",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "groupToMergeWith[index].groupToMergeWith"
                                            }
                                          },
                                          _vm._l(
                                            _vm.filterGroupsByVersionName(
                                              item.group_id,
                                              item.version_name
                                            ),
                                            function(gr) {
                                              return _c(
                                                "md-option",
                                                {
                                                  key: gr.group_id,
                                                  attrs: { value: gr.group_id }
                                                },
                                                [_vm._v(_vm._s(gr.group_name))]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.checkIfSuccessPage() && _vm.status === "created"
        ? _c(
            "form",
            {
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateGroupForm.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "md-card",
                { staticClass: "md-layout-item" },
                [
                  _c(
                    "md-card-content",
                    [
                      _c("div", { staticClass: "md-title" }, [
                        _vm._v("New Group")
                      ]),
                      _c("div", { staticClass: "md-layout md-gutter" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item group-name md-small-size-100"
                          },
                          [
                            _c(
                              "md-field",
                              { class: _vm.getValidationClass("group_name") },
                              [
                                _c("label", { attrs: { for: "group_name" } }, [
                                  _vm._v("Group Name")
                                ]),
                                _c("md-input", {
                                  attrs: {
                                    id: "group_name",
                                    name: "group_name",
                                    disabled: _vm.sending,
                                    maxlength: "20"
                                  },
                                  model: {
                                    value: _vm.form.group_name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "group_name", $$v)
                                    },
                                    expression: "form.group_name"
                                  }
                                }),
                                !_vm.$v.form.group_name.required
                                  ? _c("span", { staticClass: "md-error" }, [
                                      _vm._v("Group name is required")
                                    ])
                                  : _vm._e(),
                                !_vm.$v.form.group_name.maxLength
                                  ? _c("span", { staticClass: "md-error" }, [
                                      _vm._v(
                                        "Group name is too long. Please insert maximum of 20 characters"
                                      )
                                    ])
                                  : _vm._e(),
                                !_vm.$v.form.group_name.noDotsInGroupName
                                  ? _c("span", { staticClass: "md-error" }, [
                                      _vm._v(
                                        "Group name should not contain any dots"
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.userRole !== _vm.Roles.Admin
                          ? _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-small-size-100"
                              },
                              [
                                _c(
                                  "md-field",
                                  {
                                    class: _vm.getValidationClass(
                                      "without_tech_questions"
                                    )
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: { for: "without_tech_questions" }
                                      },
                                      [_vm._v("Group Version")]
                                    ),
                                    _c(
                                      "md-select",
                                      {
                                        attrs: {
                                          name: "without_tech_questions",
                                          id: "without_tech_questions",
                                          "md-dense": "",
                                          disabled: _vm.sending
                                        },
                                        model: {
                                          value:
                                            _vm.form.without_tech_questions,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "without_tech_questions",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.without_tech_questions"
                                        }
                                      },
                                      [
                                        _c(
                                          "md-option",
                                          { key: "tech", attrs: { value: 0 } },
                                          [
                                            _vm._v(
                                              "including technical questions"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "md-option",
                                          {
                                            key: "non-tech",
                                            attrs: { value: 1 }
                                          },
                                          [
                                            _vm._v(
                                              "not including technical questions"
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("span", { staticClass: "md-error" }, [
                                      _vm._v(
                                        "Group version is a required field"
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "md-layout md-gutter" }, [
                        _c(
                          "div",
                          { staticClass: "md-layout-item md-small-size-100" },
                          [
                            _c(
                              "md-field",
                              { class: _vm.getValidationClass("group_desc") },
                              [
                                _c("label", { attrs: { for: "group_desc" } }, [
                                  _vm._v("Group Description")
                                ]),
                                _c("md-input", {
                                  attrs: {
                                    id: "group_desc",
                                    name: "group_desc",
                                    disabled: _vm.sending
                                  },
                                  model: {
                                    value: _vm.form.group_desc,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "group_desc", $$v)
                                    },
                                    expression: "form.group_desc"
                                  }
                                }),
                                !_vm.$v.form.group_desc.required
                                  ? _c("span", { staticClass: "md-error" }, [
                                      _vm._v(
                                        "Group description is a required field"
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm.userRole === _vm.Roles.Admin
                        ? _c("div", { staticClass: "md-layout md-gutter" }, [
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-small-size-100"
                              },
                              [
                                _c(
                                  "md-field",
                                  {
                                    class: _vm.getValidationClass(
                                      "collector_id"
                                    )
                                  },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "collector_id" } },
                                      [_vm._v("Collector ID")]
                                    ),
                                    _c("md-input", {
                                      attrs: {
                                        id: "collector_id",
                                        type: "number",
                                        name: "collector_id",
                                        disabled: _vm.sending
                                      },
                                      model: {
                                        value: _vm.form.collector_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "collector_id",
                                            $$v
                                          )
                                        },
                                        expression: "form.collector_id"
                                      }
                                    }),
                                    !_vm.$v.form.collector_id.required
                                      ? _c(
                                          "span",
                                          { staticClass: "md-error" },
                                          [
                                            _vm._v(
                                              "Collector ID is a required field"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.sending
                        ? _c("v-progress-linear", {
                            attrs: { indeterminate: "" }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "md-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            disabled: _vm.sending
                          }
                        },
                        [_vm._v("Create Group")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("confirm-dialogue", { ref: "confirmDialogue" }),
      _c("popup-modal", { ref: "mergeGroupsPopUp" }, [
        _c(
          "form",
          {
            attrs: { novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.validateMergedGroupForm.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "md-layout" }, [
              _c("div", { staticClass: "md-layout-item md-size-100" }, [
                _c("h3", [_vm._v("Please insert values for the merged group")])
              ]),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-100" },
                [
                  _c(
                    "md-field",
                    {
                      class: _vm.getValidationClassMergeGroupsForm(
                        "merged_group_name"
                      )
                    },
                    [
                      _c("label", { attrs: { for: "merged_group_name" } }, [
                        _vm._v("Name:")
                      ]),
                      _c("md-input", {
                        attrs: {
                          id: "merged_group_name",
                          name: "merged_group_name",
                          maxlength: "20"
                        },
                        model: {
                          value: _vm.mergeGroupForm.merged_group_name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.mergeGroupForm,
                              "merged_group_name",
                              $$v
                            )
                          },
                          expression: "mergeGroupForm.merged_group_name"
                        }
                      }),
                      !_vm.$v.mergeGroupForm.merged_group_name.required
                        ? _c("span", { staticClass: "md-error" }, [
                            _vm._v("Group name is required")
                          ])
                        : _vm._e(),
                      !_vm.$v.mergeGroupForm.merged_group_name.maxLength
                        ? _c("span", { staticClass: "md-error" }, [
                            _vm._v(
                              "Group name can't be longer than 20 characters"
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.mergeGroupForm.merged_group_name.noDotsInGroupName
                        ? _c("span", { staticClass: "md-error" }, [
                            _vm._v("Group name should not contain any dots")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-100" },
                [
                  _c(
                    "md-field",
                    {
                      class: _vm.getValidationClassMergeGroupsForm(
                        "merged_group_description"
                      )
                    },
                    [
                      _c(
                        "label",
                        { attrs: { for: "merged_group_description" } },
                        [_vm._v("Description:")]
                      ),
                      _c("md-input", {
                        attrs: {
                          id: "merged_group_description",
                          name: "merged_group_description"
                        },
                        model: {
                          value: _vm.mergeGroupForm.merged_group_description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.mergeGroupForm,
                              "merged_group_description",
                              $$v
                            )
                          },
                          expression: "mergeGroupForm.merged_group_description"
                        }
                      }),
                      !_vm.$v.mergeGroupForm.merged_group_description.required
                        ? _c("span", { staticClass: "md-error" }, [
                            _vm._v("Group description is required")
                          ])
                        : _vm._e(),
                      !_vm.$v.mergeGroupForm.merged_group_description.maxLength
                        ? _c("span", { staticClass: "md-error" }, [
                            _vm._v(
                              "Group description can't be longer than 500 characters"
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-layout md-size-100 md-alignment-center-space-between top-margin"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "md-layout-item md-size-30",
                      attrs: { color: "warning" },
                      on: { click: _vm._cancel }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "md-layout-item md-size-30",
                      attrs: { color: "success", type: "submit" }
                    },
                    [_vm._v("Merge groups")]
                  )
                ],
                1
              )
            ])
          ]
        )
      ]),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }