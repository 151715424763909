var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("AI maturity across dimensions")
      ]),
      _c("h4", [
        _vm._v(
          "Below you find your maturity score for different dimensions that are being assessed by the AI maturity tool. To access the detailed insights into each of the dimensions use the respective buttons on the tiles below."
        )
      ]),
      _c("div", { staticClass: "md-layout" }, [
        _vm.maturityData.length > 0
          ? _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-medium-size-100 md-large-size-50"
              },
              [
                _c("v-simple-table", {
                  staticClass: "simple-table",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("thead", { staticClass: "hidden-md-and-down" }, [
                              _c("tr", [
                                _c("th"),
                                _c("th", [
                                  _c(
                                    "div",
                                    { staticClass: "green-cell header-text" },
                                    [_vm._v("Current Level")]
                                  )
                                ])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.maturityData.slice(0, 5), function(
                                item
                              ) {
                                return _c("tr", { key: item.name }, [
                                  _c(
                                    "td",
                                    { staticClass: "green-cell add-opacity" },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "borderedFlexContainer" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fillWidth hidden-md-and-down"
                                          },
                                          [
                                            _vm.isLoading &&
                                            !_vm.powerPointDownloadLoading
                                              ? _c("loading-indicator", {
                                                  attrs: {
                                                    "is-loading": _vm.isLoading
                                                  }
                                                })
                                              : _vm._e(),
                                            !_vm.isLoading ||
                                            _vm.powerPointDownloadLoading
                                              ? _c(
                                                  "horizontal-arrow-bar-chart",
                                                  {
                                                    attrs: {
                                                      matScoreValue:
                                                        item.maturity_score,
                                                      selectorId: item.position
                                                    }
                                                  }
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "right-square green-cell"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  Math.floor(
                                                    item.maturity_score
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2600687421
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.maturityData.length > 0
          ? _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-medium-size-100 md-large-size-50"
              },
              [
                _c("v-simple-table", {
                  staticClass: "simple-table",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("thead", { staticClass: "hidden-md-and-down" }, [
                              _c("tr", [_c("th"), _c("th")])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.maturityData.slice(5, 10), function(
                                item
                              ) {
                                return _c("tr", { key: item.name }, [
                                  _c(
                                    "td",
                                    { staticClass: "green-cell add-opacity" },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "borderedFlexContainer" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fillWidth hidden-md-and-down"
                                          },
                                          [
                                            _vm.isLoading &&
                                            !_vm.powerPointDownloadLoading
                                              ? _c("loading-indicator", {
                                                  attrs: {
                                                    "is-loading": _vm.isLoading
                                                  }
                                                })
                                              : _vm._e(),
                                            !_vm.isLoading ||
                                            _vm.powerPointDownloadLoading
                                              ? _c(
                                                  "horizontal-arrow-bar-chart",
                                                  {
                                                    attrs: {
                                                      matScoreValue:
                                                        item.maturity_score,
                                                      selectorId: item.position
                                                    }
                                                  }
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "right-square green-cell"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  Math.floor(
                                                    item.maturity_score
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    430096132
                  )
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.maturityDataLoading
        ? _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary lighten-2" }
          })
        : _vm._e(),
      !_vm.isLoading || _vm.powerPointDownloadLoading
        ? _c(
            "div",
            { staticClass: "md-layout md-alignment-top-center" },
            _vm._l(_vm.maturityData, function(ref) {
              var id = ref.id
              var name = ref.name
              var maturity_score = ref.maturity_score
              return _c(
                "div",
                {
                  key: id,
                  staticClass:
                    "md-layout-item md-medium-size-100 md-large-size-33"
                },
                [
                  _c(
                    "md-card",
                    {
                      staticClass: "modified-cards",
                      attrs: { "md-with-hover": "" }
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(name))]),
                      _c("h4", [
                        _vm._v(
                          "Level " + _vm._s(Math.floor(maturity_score)) + " / 4"
                        )
                      ]),
                      _c("md-card-content", [
                        _c(
                          "div",
                          { staticClass: "md-card-content" },
                          [
                            _c("maturity-pie-chart", {
                              attrs: {
                                name: name ? name.replace(/\s+/g, "") : "",
                                score: maturity_score
                              }
                            }),
                            _vm.maturityDataLoading
                              ? _c(
                                  "p",
                                  { staticClass: "data-fetching-error" },
                                  [_vm._v("Chart could not be loaded.")]
                                )
                              : _vm._e(),
                            _c("br"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.goToDetailViewForClusterID(
                                      id,
                                      maturity_score
                                    )
                                  }
                                }
                              },
                              [_vm._v("Detailed Analysis")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.isLoading || _vm.powerPointDownloadLoading
        ? _c("div", { staticClass: "md-layout" }, [
            _c("div", { staticClass: "md-layout-item" }, [
              _c("h3", [
                _vm._v(
                  "You are interested in discussing your assessment results with experts?"
                )
              ]),
              _c("p", [
                _vm._v(
                  "We offer in depth-workshops, that help you to build your strategic AI roadmap based on the results of your maturity assessment and exchange with leading AI experts on best practices and approaches. Just drop us a message via the button below!"
                )
              ]),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "mailto:info@appliedai.de?subject=Please%20get%20in%20touch%20with%20me%20regarding%20my%20maturity%20assessment%20results&body=Add%20a%20message%20here%20or%20simply%20hit%20send.%20We%20will%20get%20back%20to%20you!"
                  }
                },
                [_c("md-button", [_vm._v("Get in touch")])],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }