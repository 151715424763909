var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "main main-raised" },
        [
          _c("loading-indicator", {
            attrs: { "is-loading": _vm.organization.loading }
          }),
          _c(
            "div",
            { staticClass: "container card-container" },
            [
              _c(
                "v-responsive",
                { staticClass: "md-alignment-centered" },
                [
                  _vm.userRole !== _vm.Roles.Admin
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", plain: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [_vm._v("←Back")]
                      )
                    : _vm._e(),
                  _vm.userRole === _vm.Roles.Admin
                    ? _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-size-50 md-small-size-100 mx-auto"
                          },
                          [
                            _c(
                              "md-card",
                              { staticClass: "md-layout" },
                              [
                                _c("md-card-content", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-100 md-small-size-100 mx-auto"
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "text-primary" },
                                        [_vm._v("Account details")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-100 md-small-size-100 mx-auto"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "boldText" },
                                            [_vm._v("Role: ")]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(_vm.user.role) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "boldText" },
                                            [_vm._v("Name: ")]
                                          ),
                                          _vm._v(_vm._s(_vm.user.name) + " "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "stretched-link",
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateUserDetails(
                                                    "name"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("[change]")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "boldText" },
                                            [_vm._v("E-mail address: ")]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(_vm.user.email) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "custom-link",
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateAdminPassword()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "[Reset admin password here]"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.userRole === _vm.Roles.AccountMaster ||
                  _vm.userRole === _vm.Roles.AccountUser
                    ? [
                        _c(
                          "div",
                          { staticClass: "md-layout" },
                          [
                            _c(
                              "md-card",
                              {
                                staticClass:
                                  "md-layout-item md-size-60 md-small-size-100 mx-auto"
                              },
                              [
                                _c("md-card-content", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-100 md-small-size-100 mx-auto mb-8"
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "text-primary" },
                                        [_vm._v("Account details")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-100 md-small-size-100 mx-auto"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "boldText" },
                                            [_vm._v("Role: ")]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(_vm.user.role) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "boldText" },
                                            [_vm._v("Name: ")]
                                          ),
                                          _vm._v(_vm._s(_vm.user.name) + " "),
                                          _vm.userRole ===
                                          _vm.Roles.AccountMaster
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "stretched-link",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateUserDetails(
                                                        "name"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("[change]")]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "boldText" },
                                            [_vm._v("E-mail address: ")]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(_vm.user.email) + " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "md-layout" },
                          [
                            _c(
                              "md-card",
                              {
                                staticClass:
                                  "md-layout-item md-size-60 md-small-size-100 mx-auto"
                              },
                              [
                                _c("md-card-content", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-100 md-small-size-100 mx-auto mb-8"
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "text-primary" },
                                        [_vm._v("Settings")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-100 md-small-size-100 mx-auto"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-100"
                                        },
                                        [
                                          _c("b", [
                                            _vm._v('"Download Report slides"')
                                          ]),
                                          _vm._v(
                                            " mode. Optimise downloaded file format for: "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "md-layout mx-auto" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md-layout-item md-size-50"
                                            },
                                            [
                                              _c(
                                                "md-radio",
                                                {
                                                  attrs: { value: true },
                                                  on: {
                                                    change:
                                                      _vm.savePrefferedOptimization
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.optimizePresentationForGSlides,
                                                    callback: function($$v) {
                                                      _vm.optimizePresentationForGSlides = $$v
                                                    },
                                                    expression:
                                                      "optimizePresentationForGSlides"
                                                  }
                                                },
                                                [_vm._v("Google slides")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md-layout-item md-size-50"
                                            },
                                            [
                                              _c(
                                                "md-radio",
                                                {
                                                  attrs: { value: false },
                                                  on: {
                                                    change:
                                                      _vm.savePrefferedOptimization
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.optimizePresentationForGSlides,
                                                    callback: function($$v) {
                                                      _vm.optimizePresentationForGSlides = $$v
                                                    },
                                                    expression:
                                                      "optimizePresentationForGSlides"
                                                  }
                                                },
                                                [_vm._v("PowerPoint")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "label",
                                              {
                                                staticStyle: {
                                                  "font-size": "12px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "* Information: Select the platform/software you will work on the downloaded slides, so the plotting of the slides will be optimised for the selected one."
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c("br"),
                  _vm.userRole === _vm.Roles.Admin
                    ? [
                        _c("div", { staticClass: "md-layout" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-50 md-medium-size-50 mx-auto"
                            },
                            [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-40 md-large-size-100 mb-10 mx-auto"
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          "x-large": "",
                                          color: "success"
                                        },
                                        on: { click: _vm.newAssessment }
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("add")]
                                        ),
                                        _vm._v("  Populate Assessment ")
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-60 md-large-size-100 mx-auto"
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          "x-large": "",
                                          color: "success"
                                        },
                                        on: { click: _vm.downloadBenchmarkData }
                                      },
                                      [
                                        _vm.showDownloadingLoader
                                          ? [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  color: "white",
                                                  indeterminate: ""
                                                }
                                              }),
                                              _vm._v("  ")
                                            ]
                                          : _vm._e(),
                                        _c("v-icon", [
                                          _vm._v(
                                            "mdi-archive-arrow-down-outline"
                                          )
                                        ]),
                                        _vm._v("   Global Benchmark Database ")
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "md-layout" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-50 md-medium-size-50 mx-auto my-xl-0 my-sm-10"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    color: "success",
                                    disabled: _vm.isAutoFindingsLoading
                                  },
                                  on: { click: _vm.handleFileInputClick }
                                },
                                [
                                  _vm.isAutoFindingsLoading
                                    ? [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            color: "white",
                                            indeterminate: ""
                                          }
                                        }),
                                        _vm._v("  ")
                                      ]
                                    : _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("add")]
                                      ),
                                  _vm._v("  Update Findings Library ")
                                ],
                                2
                              ),
                              _c("input", {
                                ref: "fileInput",
                                staticClass: "d-none",
                                attrs: { type: "file", accept: ".xlsx" },
                                on: {
                                  change: _vm.onFileSelected,
                                  click: _vm.clearSelectedValue
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm.userRole !== _vm.Roles.AccountUser &&
                        !_vm.organizations.loading
                          ? _c("div", { staticClass: "md-layout" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-50 md-small-size-100 mx-auto"
                                },
                                [
                                  _c("h3", { staticClass: "text-primary" }, [
                                    _vm._v("Account management")
                                  ])
                                ]
                              )
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "md-layout" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-50 md-small-size-100 mx-auto"
                            },
                            [
                              _vm.organizations.length === 0
                                ? _c("div", { staticClass: "md-layout-item" }, [
                                    _c("h4", [
                                      _vm._v("No organizations added yet")
                                    ])
                                  ])
                                : _c("div", { staticClass: "md-layout" }, [
                                    _c(
                                      "div",
                                      { staticClass: "md-layout-item" },
                                      _vm._l(_vm.organizations, function(org) {
                                        return _c(
                                          "div",
                                          {
                                            key: org.country_name,
                                            staticClass: "borderBox"
                                          },
                                          [
                                            _c(
                                              "h4",
                                              { staticClass: "headerBoldText" },
                                              [
                                                _vm._v(
                                                  _vm._s(org.country_name) + " "
                                                ),
                                                org.nationalHost
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "stretched-link",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.goToPage(
                                                              "organizationView",
                                                              {
                                                                id:
                                                                  org
                                                                    .nationalHost
                                                                    .id
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            org.nationalHost
                                                              .name +
                                                              " (National host)"
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        "National host has not been added"
                                                      )
                                                    ])
                                              ]
                                            ),
                                            _vm._l(
                                              org.certifiedPartners,
                                              function(certifiedPartner) {
                                                return org.certifiedPartners
                                                  .length > 0
                                                  ? _c(
                                                      "h6",
                                                      {
                                                        key:
                                                          certifiedPartner.name,
                                                        staticClass:
                                                          "sub-header",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.goToPage(
                                                              "organizationView",
                                                              {
                                                                id:
                                                                  certifiedPartner.id
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "stretched-link"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                certifiedPartner.name +
                                                                  " (certified partner)"
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            org.certifiedPartners.length == 0
                                              ? _c(
                                                  "h6",
                                                  {
                                                    staticClass: "natHostName"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Certified partners have not been added yet!"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      }),
                                      0
                                    )
                                  ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "md-layout" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-50 md-small-size-100 mx-auto"
                            },
                            [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-50 md-small-size-100"
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "fixed-width-btn",
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToPage(
                                              "createOrganization",
                                              {
                                                organization_type:
                                                  "certified-partner"
                                              }
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Add certified partner")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-50 md-small-size-100 fixed-width-btn"
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "fixed-width-btn",
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToPage(
                                              "createOrganization",
                                              {
                                                organization_type:
                                                  "national-host"
                                              }
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Add national host")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ])
                      ]
                    : _vm._e(),
                  _vm.userRole === _vm.Roles.AccountMaster
                    ? [
                        _vm.collaboratorAccounts.length > 0
                          ? _c("div", { staticClass: "md-layout" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-60 md-small-size-100 mx-auto"
                                },
                                [
                                  _c("h4", { staticClass: "boldText" }, [
                                    _vm._v("Collaborators:")
                                  ])
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._l(_vm.collaboratorAccounts, function(
                          collaborator
                        ) {
                          return _c(
                            "div",
                            {
                              key: collaborator.email,
                              staticClass: "md-layout"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-size-60 md-small-size-100 mx-auto"
                                },
                                [
                                  _c("div", { staticClass: "md-layout" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-50 md-small-size-100"
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "span",
                                                        _vm._g(
                                                          _vm._b(
                                                            {},
                                                            "span",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              collaborator.name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(collaborator.email))
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-50 md-small-size-100"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "md-layout" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "md-layout-item md-small-size-66 md-large-size-66",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateDetailsForOtherUser(
                                                      collaborator.id,
                                                      "name"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "stretched-link"
                                                  },
                                                  [_vm._v("[change name]")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "md-layout-item md-small-size-33 md-large-size-33",
                                                on: {
                                                  click: function($event) {
                                                    _vm.deleteUser(
                                                      collaborator.id,
                                                      collaborator.name +
                                                        " (" +
                                                        collaborator.email +
                                                        ")"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "stretched-link"
                                                  },
                                                  [_vm._v("[delete]")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        _c("br"),
                        _c("div", { staticClass: "md-layout" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-60 md-small-size-100 mx-auto"
                            },
                            [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-50 md-small-size-100"
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "fixed-width-btn",
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToPage(
                                              "userAccountCreate",
                                              {
                                                account_type: "account-user",
                                                organization_id:
                                                  _vm.organization
                                                    .organization_id,
                                                userRole: _vm.userRole
                                              }
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Add collaborator")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("popup-modal", { ref: "deletePopUp" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item md-size-100" }, [
            _c("h2", [_vm._v("Are you sure you want to delete this account?")]),
            _c("h3", [_vm._v(_vm._s(_vm.deletePopUpTitle))])
          ]),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-layout md-size-100 md-alignment-center-space-between"
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "md-layout-item md-size-30",
                  attrs: { color: "warning" },
                  on: {
                    click: function($event) {
                      return _vm._cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "md-layout-item md-size-30",
                  attrs: { color: "success" },
                  on: {
                    click: function($event) {
                      return _vm._confirm()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ])
      ]),
      _c("confirm-dialogue", { ref: "confirmDialogue" }),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }