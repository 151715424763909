<template>
  <div class="md-wrapper">
    <Header />
    <div class="main main-raised" id="scrollSection">
      <loading-indicator :is-loading="isLoading" />
      <div class="section">
        <div class="container">
          <div class="md-layout md-gutter md-alignment-center">
            <div
              class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
            >
              <h2 class="ext-center">
                Results of <strong>{{ this.assessment.name }}</strong>
              </h2>
              <p>
                We have put together some guidance for working with your
                maturity assessment results at
                <a
                  href="https://www.appliedAI.de/maturity-assessment-interpretation"
                  target="_blank"
                  >https://www.appliedAI.de/maturity-assessment-interpretation</a
                >
              </p>
            </div>
          </div>
          <div class="features text-center">
            <div class="md-layout md-gutter md-alignment-center">
              <div
                class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
              >
                <overview-of-participants-chart
                  v-if="showParticipantsOverview"
                  :participantsdata="participantsdata"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary lighten-2"
                />
              </div>
            </div>
            <div class="md-layout md-gutter md-alignment-center">
              <div
                class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
              >
                <v-expansion-panels focusable>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Group merge menu</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <group-table
                        ref="configGroupTable"
                        :sme_version="sme_version"
                        :assessment_id="this.assessment_id"
                        :status="this.assessment.status || ''"
                        :areTermsAccepted="
                          this.assessment.terms_of_use_accepted || false
                        "
                        :userRole="userRole"
                        @onMerge="fetchData"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Benchmark menu</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <download-assessment-data-buttons
                        :assessment_id="this.assessment_id"
                        :industry="this.assessment.industry || ''"
                        :size="this.assessment.size || ''"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
            <div class="md-layout md-gutter mt-5">
              <div
                class="md-layout-item md-layout md-size-66 md-xsmall-size-100 mx-auto"
              >
                <div class="md-layout md-size-50">
                  <div class="md-layout-item text-left">
                    <v-btn
                      color="primary"
                      @click="downloadReportSlides"
                      :disabled="powerPointDownloadLoading"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary lighten-2"
                        v-if="powerPointDownloadLoading"
                      />
                      <v-icon v-else>mdi-archive-arrow-down-outline</v-icon>
                      &nbsp;Download report slides
                    </v-btn>
                  </div>
                  <div
                    class="md-layout-item md-size-100 text-xs-center text-md-left"
                  >
                    <label v-if="shouldOptimizeForGSlides" class="helper-text"
                      >* Presentation will be optimized for Google Slides</label
                    >
                    <label v-if="!shouldOptimizeForGSlides" class="helper-text"
                      >* Presentation will be optimized for Power Point</label
                    >
                  </div>
                  <div
                    class="md-layout-item md-size-100 text-xs-center text-md-left"
                  >
                    <md-checkbox
                      class="md-primary"
                      v-model="shouldIncludeAutofindings"
                      >Autofindings included</md-checkbox
                    >
                  </div>
                </div>
                <div class="md-layout md-size-50">
                  <div class="md-layout-item text-right">
                    <vue-excel-xlsx
                      :data="excelData"
                      :columns="columns"
                      :file-name="this.assessment.name + ' Sum Data'"
                      :file-type="'xlsx'"
                      :sheet-name="'sheetname'"
                    >
                      <v-btn color="primary">
                        <v-icon>mdi-archive-arrow-down-outline</v-icon>
                        &nbsp;Download summary data
                      </v-btn>
                    </vue-excel-xlsx>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="md-layout grey lighten-5 md-gutter md-alignment-center mt-10 pb-10"
            >
              <div
                class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100"
              >
                <ai-across-dimensions
                  :isLoading="isLoading"
                  :powerPointDownloadLoading="powerPointDownloadLoading"
                  :assessment_id="this.assessment_id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <snackbar />
  </div>
</template>

<script>
import { http } from '@/utils';

import {
  LoadingIndicator,
  Header,
  Snackbar,
  OverviewOfParticipantsChart,
  AiAcrossDimensions,
  DownloadAssessmentDataButtons,
  GroupTable,
} from '@/components';
import { mapActions, mapState } from 'vuex';

export default {
  bodyClass: 'landing-page',
  components: {
    Header,
    LoadingIndicator,
    Snackbar,
    OverviewOfParticipantsChart,
    AiAcrossDimensions,
    DownloadAssessmentDataButtons,
    GroupTable,
  },
  props: {
    isLoading: Boolean,
    userRole: String,
  },
  data() {
    return {
      sme_version: null,
      assessment_id: -1,
      shouldIncludeAutofindings: true,
    };
  },
  computed: {
    ...mapState({
      assessment: (state) => state.assessments.assessment,
      maturityData: (state) => state.assessments.maturityData,
      maturityDataLoading: (state) => state.assessments.maturityDataLoading,
      powerPointDownloadLoading: (state) =>
        state.assessments.powerPointDownloadLoading,
      participantsLoading: (state) => state.assessments.participantsLoading,
      participantsdata: (state) => state.assessments.participants,
      benchmarkFilters: (state) => state.assessments.benchmarkFilters,
      shouldOptimizeForGSlides: (state) =>
        state.user.preferredOptimizationForGSlide,
    }),
    dimensions() {
      const maturityData = this.maturityData;
      if (maturityData[0] && maturityData[0].questionsAndRatings?.length > 0) {
        const findGroupNames = maturityData[0].questionsAndRatings[0].rating;
        const dimensions = [
          {
            label: 'Dimension',
            field: 'dimension',
          },
          {
            label: 'Maturity level',
            field: 'maturity_score',
          },
          {
            label: 'Dimension',
            field: 'dimension',
          },
          {
            label: 'Item',
            field: 'item',
          },
          {
            label: 'Total Average',
            field: 'totalAverage',
          },
        ];
        for (let key in findGroupNames) {
          if (key != 'all_participants') {
            dimensions.push({ label: 'Average ' + key, field: key });
          }
        }
        dimensions.push({ label: 'N/A (total) ', field: 'naTotal' });
        dimensions.push({
          label: 'Completed (total) ',
          field: 'completedTotal',
        });
        dimensions.push({
          label: 'Non completed (total) ',
          field: 'nonCompletedTotal',
        });

        for (let key in findGroupNames) {
          if (key != 'all_participants') {
            dimensions.push({ label: 'N/A (' + key + ')', field: 'N/A' + key });
            dimensions.push({
              label: 'Completed/Non completed (' + key + ')',
              field: 'com/noncom/' + key,
            });
          }
        }
        return dimensions;
      }
      return [];
    },
    excelData() {
      let maturityData = this.maturityData;
      if (maturityData[0] && maturityData[0].questionsAndRatings?.length > 0) {
        let toBeCreatedFinal = [];
        maturityData.forEach((matData) => {
          matData.questionsAndRatings &&
            matData.questionsAndRatings.forEach((item, i) => {
              let toBeCreated = {};
              this.dimensions.forEach((dim) => {
                toBeCreated['dimension'] = i === 0 ? matData.name : '';
                toBeCreated['maturity_score'] =
                  i === 0 ? matData.maturity_score : '';
                toBeCreated['item'] = item.text;
                toBeCreated['totalAverage'] =
                  item.rating.all_participants.rating__avg || 'N/A';
                if (
                  dim.field != 'dimension' &&
                  dim.field != 'item' &&
                  dim.field != 'totalAverage' &&
                  dim.field != 'naTotal' &&
                  dim.field != 'completedTotal' &&
                  dim.field != 'nonCompletedTotal' &&
                  dim.field != 'maturity_score' &&
                  !dim.field.startsWith('N/A') &&
                  !dim.field.startsWith('com/noncom/')
                ) {
                  toBeCreated[dim.field] =
                    item.rating[dim.field].rating__avg || 'N/A';
                }
                toBeCreated['naTotal'] =
                  item.participants_statistics.na['all_participants'];
                toBeCreated['completedTotal'] =
                  item.participants_statistics.completed['all_participants'];
                toBeCreated['nonCompletedTotal'] =
                  item.participants_statistics.non_completed[
                    'all_participants'
                  ];
                if (dim.field.startsWith('N/A')) {
                  toBeCreated[dim.field] =
                    item.participants_statistics.na[
                      dim.field.replace('N/A', '')
                    ];
                }
                if (dim.field.startsWith('com/noncom/')) {
                  const completedNum =
                    item.participants_statistics.completed[
                      dim.field.replace('com/noncom/', '')
                    ];
                  const nonCompletedNum =
                    item.participants_statistics.non_completed[
                      dim.field.replace('com/noncom/', '')
                    ];
                  toBeCreated[dim.field] =
                    completedNum + ' / ' + nonCompletedNum;
                }
              });
              toBeCreatedFinal.push(toBeCreated);
            });
        });
        return toBeCreatedFinal;
      }
      return [];
    },
    columns() {
      return this.dimensions;
    },
    showParticipantsOverview() {
      return (
        !this.participantsLoading && this.participantsdata.labels?.length > 0
      );
    },
  },
  mounted() {
    this.assessment_id = parseInt(this.$route.params.assessment_id);
    this.fetchData();
  },
  beforeDestroy() {
    this.resetStore();
  },
  methods: {
    ...mapActions({
      getMaturityData: 'assessments/getMaturityData',
      getAssessment: 'assessments/getAssessment',
      getAssessmentBenchmarkingData: 'assessments/getAssessmentBenchmarkingData',
      getParticipants: 'assessments/getParticipants',
      getMatGroups: 'assessments/getMatGroups',
      getClusterDetailData: 'assessments/getAllClustersDetailData',
      resetStore: 'assessments/resetStore',
      downloadPowerpointSlidesAction: 'assessments/downloadPowerpointSlides',
      getTypesOfCompanies: 'assessments/getTypesOfCompanies',
      showSnackBarAction: 'assessments/showSnackBar',
      getPreferredOptimizationForPresentationAction:
        'user/getPreferredOptimizationForPresentation',
    }),
    downloadReportSlides() {
      this.downloadPowerpointSlidesAction({
        payload: {
          assessment_id: this.assessment_id,
          benchmark_data: this.benchmarkFilters,
          include_autofindings: this.shouldIncludeAutofindings,
        },
        cb: (msg, file) => {
          const url = window.URL.createObjectURL(new Blob([file]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.assessment.name + ' Report.pptx');
          document.body.appendChild(link);
          link.click();
          this.showSnackBarAction(msg);
          http.defaults.responseType = '';
        },
      });
    },
    fetchData() {
      this.getMaturityData({
        assessment_id: this.assessment_id,
        cb: () => this.getClusterDetailData(this.assessment_id),
      });
      this.getAssessment(this.assessment_id);
      this.getAssessmentBenchmarkingData(this.assessment_id);
      this.getParticipants(this.assessment_id);
      this.getMatGroups(this.assessment_id);
      this.getTypesOfCompanies(this.assessment_id);
      this.getPreferredOptimizationForPresentationAction();
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}
.md-has-textarea + .md-layout {
  margin-top: 15px;
}
.data-fetching-error {
  color: red;
  border: 1px solid red;
}
.md-button {
  color: teal;
}
#tooltip {
  padding: 5px;
  background: #ffffff;
  color: black;
  opacity: 0;
  border-radius: 5px;
  pointer-events: none;
  font-size: 12px;
  height: 32px;
  text-align: center;
}
.helper-text {
  font-size: 13px;
}
</style>
