import organization from '../../../api/organization';
import user from '../../../api/user';
import { tackleErrorMessage } from '../../../api';


export const getOrganizations = async ({ commit, dispatch }) => {
  try {
    const response = await organization.getOrganizations();
    commit('setOrganizations', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getOrganizationDetails = async ({ commit, dispatch }, id) => {
  commit('setOrganizationLoading', true);
  try {
    const response = await organization.getOrganization(id);
    commit('setOrganization', response.data);
    commit('setOrganizationLoading', false);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
    commit('setOrganizationLoading', false);
  }
};

export const getOrganizationCollaborators = async ({ commit, dispatch }) => {
  commit('setOrganizationLoading', true);
  try {
    const response = await organization.getOrganizationCollaborators();
    commit('setOrganization', response.data);
    commit('setOrganizationLoading', false);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
    commit('setOrganizationLoading', false);
  }
};

export const updateOrganization = async ({ dispatch }, { organization_id, payload, cb }) => {
  try {
    await organization.updateOrganization(organization_id, payload);
    cb();
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const createOrganization = async ({ dispatch }, { type, name, country, email, cb }) => {
  try {
    await organization.createOrganization({ type, name, country, email } );
    cb();
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const changeUserMail = async ({ dispatch }, { oldMail, newMail}) => {
  try {
    await organization.changeUserMail(oldMail, newMail);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const deleteUser = async ({ commit, dispatch }, { id, cb }) => {
  try {
    await user.deleteUser(id);
    commit('removeUserFromList', id);
    cb('User successfully deleted!');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getAllNationalHosts = async ({ commit, dispatch }) => {
  try {
    const response = await organization.getAllNationalHosts();
    commit('setNationalHosts', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getListOfCountries = async ({ commit, dispatch }) => {
  try {
    const response = await organization.getListOfCountries();
    commit('setCountries', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const updateDetailsForOtherUser = async ({ commit, dispatch }, { userId, payload, cb }) => {
  try {
    await user.updateDetailsForOtherUser(userId, payload);
    commit('updateCollaboratorUserProperty', { userId, fieldName: payload.item_name, value: payload.item_value });
    cb();
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};