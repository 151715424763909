var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass: "footer",
      class: ((_obj = {}), (_obj["footer-" + _vm.type] = _vm.type), _obj),
      attrs: { "data-background-color": _vm.backgroundColor }
    },
    [
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c("div", { staticClass: "copyright" }, [
          _vm._v(
            _vm._s(_vm.$store.state.main.packageVersion) +
              " © " +
              _vm._s(_vm.year) +
              " by "
          ),
          _c(
            "a",
            {
              attrs: { href: "https://www.appliedai.de/en/", target: "_blank" }
            },
            [_vm._v("appliedAI Initiative.")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", [
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.appliedai.de/en/philosophy/",
                target: "_blank"
              }
            },
            [_vm._v("About Us")]
          )
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.appliedai.de/en/privacy-policy/",
                target: "_blank"
              }
            },
            [_vm._v("Privacy Policy")]
          )
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.appliedai.de/en/imprint/",
                target: "_blank"
              }
            },
            [_vm._v("Imprint")]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }