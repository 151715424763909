import { render, staticRenderFns } from "./OrganizationView.vue?vue&type=template&id=6d817b6c&"
import script from "./OrganizationView.vue?vue&type=script&lang=js&"
export * from "./OrganizationView.vue?vue&type=script&lang=js&"
import style0 from "./OrganizationView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VResponsive,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/builds/iaai/products/maturity-assessment-tool/mat-website/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d817b6c')) {
      api.createRecord('6d817b6c', component.options)
    } else {
      api.reload('6d817b6c', component.options)
    }
    module.hot.accept("./OrganizationView.vue?vue&type=template&id=6d817b6c&", function () {
      api.rerender('6d817b6c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/organization/OrganizationView.vue"
export default component.exports