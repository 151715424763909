<template>
  <footer class="footer" :class="{ [`footer-${type}`]: type }" :data-background-color="backgroundColor">
    <div class="container">
      <nav>
        <ul>
          <li><a href="https://www.appliedai.de/en/philosophy/" target="_blank">About Us</a></li>
          <li><a href="https://www.appliedai.de/en/privacy-policy/" target="_blank">Privacy Policy</a></li>
          <li><a href="https://www.appliedai.de/en/imprint/" target="_blank">Imprint</a></li>
        </ul>
      </nav>
      <div class="copyright">{{ $store.state.main.packageVersion }} &copy; {{ year }} by <a href="https://www.appliedai.de/en/" target="_blank">appliedAI Initiative.</a></div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
