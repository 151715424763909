var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "md-layout md-gutter grey lighten-5 mt-10 mb-10 pt-10 pb-10"
      },
      [
        _c(
          "div",
          {
            staticClass: "md-layout-item md-size-100 mx-auto text-center mb-10"
          },
          [
            _vm._v(
              " Please define the benchmark companies that you want to be included in the report "
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-layout md-alignment-center-right" },
          [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-60 md-medium-size-100" },
              [
                _c(
                  "md-field",
                  [
                    _c("label", { attrs: { for: "sizes" } }, [_vm._v("Size:")]),
                    _c(
                      "md-select",
                      {
                        attrs: {
                          name: "sizes",
                          id: "sizes",
                          multiple: "",
                          "md-dense": ""
                        },
                        model: {
                          value: _vm.selectedSizes,
                          callback: function($$v) {
                            _vm.selectedSizes = $$v
                          },
                          expression: "selectedSizes"
                        }
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass:
                              "md-list-item md-menu-item md-theme-default"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "md-list-item-content md-ripple" },
                              [
                                _c(
                                  "md-checkbox",
                                  {
                                    staticClass:
                                      "md-primary select-all-checkbox",
                                    on: {
                                      change: function($event) {
                                        return _vm.toggleSelectAllSizes()
                                      }
                                    },
                                    model: {
                                      value: _vm.allSizesSelected,
                                      callback: function($$v) {
                                        _vm.allSizesSelected = $$v
                                      },
                                      expression: "allSizesSelected"
                                    }
                                  },
                                  [_vm._v("All")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._l(_vm.typesByRevenueSize, function(revenueSizes) {
                          return [
                            _c(
                              "md-option",
                              {
                                key: revenueSizes.text_en,
                                attrs: { value: revenueSizes.text_en }
                              },
                              [_vm._v(_vm._s(revenueSizes.text_en))]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-layout md-alignment-center-left" },
          [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-60 md-medium-size-100" },
              [
                _c(
                  "md-field",
                  [
                    _c("label", { attrs: { for: "industries" } }, [
                      _vm._v("Industry:")
                    ]),
                    _c(
                      "md-select",
                      {
                        attrs: {
                          name: "industries",
                          id: "industries",
                          multiple: "",
                          "md-dense": ""
                        },
                        model: {
                          value: _vm.selectedIndustries,
                          callback: function($$v) {
                            _vm.selectedIndustries = $$v
                          },
                          expression: "selectedIndustries"
                        }
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass:
                              "md-list-item md-menu-item md-theme-default"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "md-list-item-content md-ripple" },
                              [
                                _c(
                                  "md-checkbox",
                                  {
                                    staticClass:
                                      "md-primary select-all-checkbox",
                                    on: {
                                      change: function($event) {
                                        return _vm.toggleSelectAllIndustries()
                                      }
                                    },
                                    model: {
                                      value: _vm.allIndustriesSelected,
                                      callback: function($$v) {
                                        _vm.allIndustriesSelected = $$v
                                      },
                                      expression: "allIndustriesSelected"
                                    }
                                  },
                                  [_vm._v("All")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._l(_vm.typesByIndustry, function(industries) {
                          return [
                            _c(
                              "md-option",
                              {
                                key: industries.text_en,
                                attrs: { value: industries.text_en }
                              },
                              [_vm._v(_vm._s(industries.text_en))]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-size-100 md-layout md-alignment-center-left"
          },
          [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-60 md-medium-size-100" },
              [
                _c(
                  "md-checkbox",
                  {
                    staticClass: "md-primary all-countries",
                    model: {
                      value: _vm.allCountriesIncluded,
                      callback: function($$v) {
                        _vm.allCountriesIncluded = $$v
                      },
                      expression: "allCountriesIncluded"
                    }
                  },
                  [_vm._v("Include all countries in the benchmark")]
                )
              ],
              1
            )
          ]
        ),
        _vm.responseMsg
          ? _c(
              "div",
              {
                class:
                  _vm.showSuccessMsg +
                  " md-layout-item md-size-100 mx-auto text-center mb-10"
              },
              [_vm._v(" " + _vm._s(_vm.responseMsg) + " ")]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-size-100 md-layout md-alignment-center-left"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      disabled: _vm.disabledApplyButton
                    },
                    on: { click: _vm.applyBenchmarkFilters }
                  },
                  [
                    _vm.apiLoading
                      ? _c("v-progress-circular", {
                          attrs: {
                            indeterminate: "",
                            color: "primary lighten-2"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" Apply selection ")
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }