<template>
    <component :is="variant"><slot></slot></component>
</template>

<script>

export default {
  name: 'Heading',
  props: {
    variant: {
      type: String,
      default: 'h3'
    },
  },
};
</script>

<style scoped>
h3 {
  font-weight: 500;
  background-color: #00747b;
  padding-left: 10px;
  color: white;
}
h4 {
  font-weight: 600;
  background-color: #c1c7b0;
  padding-left: 10px;
}
</style>
