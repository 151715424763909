var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-wrapper" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "main main-raised", attrs: { id: "scrollSection" } },
        [
          _c("loading-indicator", { attrs: { "is-loading": _vm.isLoading } }),
          _c("div", { staticClass: "section" }, [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "md-layout md-gutter md-alignment-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
                    },
                    [
                      _c("h2", { staticClass: "ext-center" }, [
                        _vm._v(" Results of "),
                        _c("strong", [_vm._v(_vm._s(this.assessment.name))])
                      ]),
                      _vm._m(0)
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "features text-center" }, [
                _c(
                  "div",
                  { staticClass: "md-layout md-gutter md-alignment-center" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
                      },
                      [
                        _vm.showParticipantsOverview
                          ? _c("overview-of-participants-chart", {
                              attrs: { participantsdata: _vm.participantsdata }
                            })
                          : _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                color: "primary lighten-2"
                              }
                            })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout md-gutter md-alignment-center" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center"
                      },
                      [
                        _c(
                          "v-expansion-panels",
                          { attrs: { focusable: "" } },
                          [
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _vm._v("Group merge menu")
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c("group-table", {
                                      ref: "configGroupTable",
                                      attrs: {
                                        sme_version: _vm.sme_version,
                                        assessment_id: this.assessment_id,
                                        status: this.assessment.status || "",
                                        areTermsAccepted:
                                          this.assessment
                                            .terms_of_use_accepted || false,
                                        userRole: _vm.userRole
                                      },
                                      on: { onMerge: _vm.fetchData }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _vm._v("Benchmark menu")
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c("download-assessment-data-buttons", {
                                      attrs: {
                                        assessment_id: this.assessment_id,
                                        industry:
                                          this.assessment.industry || "",
                                        size: this.assessment.size || ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("div", { staticClass: "md-layout md-gutter mt-5" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-layout md-size-66 md-xsmall-size-100 mx-auto"
                    },
                    [
                      _c("div", { staticClass: "md-layout md-size-50" }, [
                        _c(
                          "div",
                          { staticClass: "md-layout-item text-left" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.powerPointDownloadLoading
                                },
                                on: { click: _vm.downloadReportSlides }
                              },
                              [
                                _vm.powerPointDownloadLoading
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary lighten-2"
                                      }
                                    })
                                  : _c("v-icon", [
                                      _vm._v("mdi-archive-arrow-down-outline")
                                    ]),
                                _vm._v("  Download report slides ")
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-size-100 text-xs-center text-md-left"
                          },
                          [
                            _vm.shouldOptimizeForGSlides
                              ? _c("label", { staticClass: "helper-text" }, [
                                  _vm._v(
                                    "* Presentation will be optimized for Google Slides"
                                  )
                                ])
                              : _vm._e(),
                            !_vm.shouldOptimizeForGSlides
                              ? _c("label", { staticClass: "helper-text" }, [
                                  _vm._v(
                                    "* Presentation will be optimized for Power Point"
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-size-100 text-xs-center text-md-left"
                          },
                          [
                            _c(
                              "md-checkbox",
                              {
                                staticClass: "md-primary",
                                model: {
                                  value: _vm.shouldIncludeAutofindings,
                                  callback: function($$v) {
                                    _vm.shouldIncludeAutofindings = $$v
                                  },
                                  expression: "shouldIncludeAutofindings"
                                }
                              },
                              [_vm._v("Autofindings included")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "md-layout md-size-50" }, [
                        _c(
                          "div",
                          { staticClass: "md-layout-item text-right" },
                          [
                            _c(
                              "vue-excel-xlsx",
                              {
                                attrs: {
                                  data: _vm.excelData,
                                  columns: _vm.columns,
                                  "file-name":
                                    this.assessment.name + " Sum Data",
                                  "file-type": "xlsx",
                                  "sheet-name": "sheetname"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  { attrs: { color: "primary" } },
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-archive-arrow-down-outline")
                                    ]),
                                    _vm._v("  Download summary data ")
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout grey lighten-5 md-gutter md-alignment-center mt-10 pb-10"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100"
                      },
                      [
                        _c("ai-across-dimensions", {
                          attrs: {
                            isLoading: _vm.isLoading,
                            powerPointDownloadLoading:
                              _vm.powerPointDownloadLoading,
                            assessment_id: this.assessment_id
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " We have put together some guidance for working with your maturity assessment results at "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.appliedAI.de/maturity-assessment-interpretation",
            target: "_blank"
          }
        },
        [_vm._v("https://www.appliedAI.de/maturity-assessment-interpretation")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }