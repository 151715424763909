<template>
  <div>
    <div class="md-layout md-gutter grey lighten-5 mt-10 mb-10 pt-10 pb-10">
      <div class="md-layout-item md-size-100 mx-auto text-center mb-10">
        Please define the benchmark companies that you want to be included in the report
      </div>
      <div class="md-layout-item md-layout md-alignment-center-right">
        <div class="md-layout-item md-size-60 md-medium-size-100">
          <md-field>
            <label for="sizes">Size:</label>
            <md-select v-model="selectedSizes" name="sizes" id="sizes" multiple md-dense>
              <li class="md-list-item md-menu-item md-theme-default">
                <div class="md-list-item-content md-ripple">
                  <md-checkbox class='md-primary select-all-checkbox' v-model="allSizesSelected" @change="toggleSelectAllSizes()">All</md-checkbox>
                </div>
              </li>
              <template v-for="revenueSizes in typesByRevenueSize" >
                <md-option v-bind:key="revenueSizes.text_en" :value="revenueSizes.text_en"
                  >{{ revenueSizes.text_en }}</md-option>
              </template>
            </md-select>
          </md-field>
        </div>
      </div>
      <div class="md-layout-item md-layout md-alignment-center-left">
        <div class="md-layout-item md-size-60 md-medium-size-100">
          <md-field>
            <label for="industries">Industry:</label>
            <md-select v-model="selectedIndustries" name="industries" id="industries" multiple md-dense>
              <li class="md-list-item md-menu-item md-theme-default">
                <div class="md-list-item-content md-ripple">
                  <md-checkbox class='md-primary select-all-checkbox' v-model="allIndustriesSelected" @change="toggleSelectAllIndustries()">All</md-checkbox>
                </div>
              </li>
              <template v-for="industries in typesByIndustry" >
                  <md-option v-bind:key="industries.text_en" :value="industries.text_en"
                    >{{ industries.text_en }}</md-option>
                </template>
            </md-select>
          </md-field>
        </div>
      </div>
      <div class="md-layout-item md-size-100 md-layout md-alignment-center-left">
        <div class="md-layout-item md-size-60 md-medium-size-100">
         <md-checkbox class='md-primary all-countries' v-model="allCountriesIncluded">Include all countries in the benchmark</md-checkbox>
        </div>
      </div>
      <div v-if="responseMsg" :class="showSuccessMsg + ' md-layout-item md-size-100 mx-auto text-center mb-10'">
        {{ responseMsg }}
      </div>
      <div class="md-layout-item md-size-100 md-layout md-alignment-center-left">
        <div class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto text-center">
          <v-btn color="primary" :disabled="disabledApplyButton" @click="applyBenchmarkFilters">
             <v-progress-circular indeterminate color="primary lighten-2" v-if="apiLoading"/>
              Apply selection
          </v-btn>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'DownloadAssessmentDataButtons',
  props: {
    assessment_id: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      required: true
    },
    industry: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      selectedSizes: [this.size],
      selectedIndustries: [this.industry],
      disabledApplyButton: true,
      allIndustriesSelected: false,
      allSizesSelected: false,
      allCountriesIncluded: false,
      responseMsg: false,
      showSuccessMsg: false
    };
  },
  computed: {
    ...mapState({
      typesByRevenueSize: state => state.assessments.typesByRevenueSize,
      typesByIndustry: state => state.assessments.typesByIndustry,
      apiLoading: state => state.assessments.apiLoading,
    }),
  },
  watch: {
    selectedSizes: function() {
      if(this.disabledApplyButton)
        this.disabledApplyButton = false;
    },
    selectedIndustries: function() {
      if(this.disabledApplyButton)
        this.disabledApplyButton = false;
    },
    allCountriesIncluded: function() {
      if(this.disabledApplyButton)
        this.disabledApplyButton = false;
    },
  },
  methods: {
    ...mapActions({
      applyBenchmarkSelection: 'assessments/applyBenchmarkSelection'
    }),
    toggleSelectAllIndustries() {
      this.selectedIndustries = this.allIndustriesSelected? this.typesByIndustry.map(typeOfIndustry => typeOfIndustry.text_en) : [];
    },
    toggleSelectAllSizes() {
      this.selectedSizes = this.allSizesSelected? this.typesByRevenueSize.map(companyTypeBySize => companyTypeBySize.text_en) : [];
    },
    applyBenchmarkFilters() {
      this.applyBenchmarkSelection({
        payload: {
          assessment_id: this.assessment_id,
          benchmark_data: {
            industries: this.selectedIndustries,
            sizes: this.selectedSizes,
            all_countries: this.allCountriesIncluded
          },
        },
        cb: (msg, isSuccessfull) => {
          this.responseMsg = msg;
          this.showSuccessMsg = isSuccessfull? 'success-message' : 'error-message';
        }
      });
    },
  },
};
</script>

<style lang="scss">
  .landing-page .md-menu-content.md-select-menu {
    max-width: 330px;
  }
  .counter-height {
    min-height: 48px;
  }
  .select-all-checkbox.md-checkbox .md-checkbox-label {
    padding-left: 36px;
  }
  .all-countries.md-checkbox {
    margin-left: 70px;
  }
  .error-message {
    color: red;
  }
  .success-message {
    color: green;
  }
</style>