import { http } from '@/utils';
import axios from 'axios';

export default {
  getUserDetails() {
    return http.get('api/v1/users/account_details/');
  },
  getOrganizations() {
    return http.get('api/v1/users/partner_organizations/');
  },
  updateUserDetails(fieldName, newValue) {
    return http.patch('api/v1/users/account_details/', { item_name: fieldName, item_value: newValue });
  },
  updateDetailsForOtherUser(userId, payload) {
    return http.patch(`api/v1/users/${userId}/details/`, payload);
  },
  async updateAdminPassword(email) {
    let payload = {
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      email,
      connection: 'Username-Password-Authentication'
    };
    let res = await axios.post('https://' + process.env.VUE_APP_AUTH0_DOMAIN + '/dbconnections/change_password', payload);

    let data = res.data;
    console.log('password reset response: ', data);
  },
  createUserAccount(payload, organizationId) {
    return http.post(`api/v1/users/organizations/${organizationId}/add_users/`, payload);
  },
  getUserById(id) {
    return http.get(`api/v1/users/${id}/details/`);
  },
  updateUserById(id) {
    return http.patch(`api/v1/users/${id}/details/`);
  },
  deleteUser(userId) {
    return http.delete(`api/v1/users/${userId}/details`);
  },
  updatePresentationForGSlides(shouldOptimizeForGSlides) {
    return http.post('api/v1/polls/preferred_power_point_optimizations/', { ppt_optimized_for_g_slides: shouldOptimizeForGSlides });
  },
  getPreferredOptimizationForPresentation(){
    return http.get('api/v1/polls/preferred_power_point_optimizations/');
  }
};
  