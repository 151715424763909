var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c("div", { staticClass: "main main-raised" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("v-responsive", { staticClass: "md-alignment-centered" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout md-gutter md-alignment-center text-center"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-60 md-small-size-100 mx-auto"
                    },
                    [
                      _c("h2", { staticClass: "top-header" }, [
                        _vm._v("Terms of Use for the Maturity Assessment Tool")
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _c("p", [_vm._v("Dear colleague,")]),
                      _c("p", [
                        _vm._v(
                          "Below are presented the Terms of Use for the Maturity Assessment Tool (MAT) developed and provided by the appliedAI Initiative. These terms have to be read, understood and accepted in order for you and your colleagues to take part in the assessment."
                        )
                      ]),
                      _c("br"),
                      _c("p", { staticClass: "boldText" }, [
                        _vm._v(
                          "Please Scroll down the Terms of Use document and click accept Terms of Use button at the end of this page."
                        )
                      ]),
                      _c(
                        "div",
                        { attrs: { id: "vue-pdf-embed" } },
                        [_c("vue-pdf-embed", { attrs: { source: _vm.src } })],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "by clicking the following link you accept the MAT Terms of Use:"
                        )
                      ]),
                      _c("br"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.disabledButton
                          },
                          on: { click: _vm.acceptTermsOfService }
                        },
                        [_vm._v(" Accept Terms of Use ")]
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "If you are interested in more information about the AI maturity assessment tool, please visit this website."
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "If you have any further questions do not hesitate to contact appliedAI initiative anytime."
                        )
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c("br")
                    ],
                    1
                  )
                ]
              )
            ])
          ],
          1
        )
      ]),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }