var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("popup-modal", { ref: "popup" }, [
    _c("h2", { staticStyle: { "margin-top": "0" } }, [
      _vm._v(_vm._s(_vm.title))
    ]),
    _c("p", [_vm._v(_vm._s(_vm.message))]),
    _vm.showEmailInputPart
      ? _c("div", [
          _c(
            "div",
            { staticClass: "md-small-size-100" },
            [
              _c(
                "md-field",
                [
                  _c("label", [
                    _vm._v("(separate multiple email addresses using ',')")
                  ]),
                  _c("md-input", {
                    ref: "nameInput",
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm._confirm.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.emailAddresses,
                      callback: function($$v) {
                        _vm.emailAddresses = $$v
                      },
                      expression: "emailAddresses"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.errorMsg
            ? _c("div", { staticClass: "red-text" }, [
                _vm._v(_vm._s(_vm.errorMsg))
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm.showUpdateDataPart
      ? _c("div", [
          _c(
            "div",
            { staticClass: "md-small-size-100" },
            [
              _c(
                "md-field",
                [
                  _c("label", [_vm._v("please insert new value")]),
                  _c("md-input", {
                    ref: "nameInput",
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm._confirm.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.newData,
                      callback: function($$v) {
                        _vm.newData = $$v
                      },
                      expression: "newData"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.errorMsg
            ? _c("div", { staticClass: "red-text" }, [
                _vm._v(_vm._s(_vm.errorMsg))
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "btns" },
      [
        _c(
          "v-btn",
          { attrs: { color: "warning" }, on: { click: _vm._cancel } },
          [_vm._v(" " + _vm._s(_vm.cancelButton) + " ")]
        ),
        typeof _vm.okButton == "string"
          ? _c(
              "v-btn",
              { attrs: { color: "success" }, on: { click: _vm._confirm } },
              [_vm._v(" " + _vm._s(_vm.okButton) + " ")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }