var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "parallax",
    { staticClass: "page-header header-filter", style: _vm.headerStyle },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c("div", { staticClass: "md-layout-item" }, [
          _c("div", { staticClass: "image-wrapper" }, [
            _c("div", { staticClass: "brand" }, [
              _c("h1", [_vm._v("Maturity Assessment Tool")]),
              _c("h3", [
                _vm._v("Assess your organization's usage of AI. By appliedAI.")
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }