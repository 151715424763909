export default {
  setAssessments (state, { assessmentswithPagination, payload }) {
    state.next = assessmentswithPagination.next;
    state.previous = assessmentswithPagination.previous;
    state.count = assessmentswithPagination.count;
    state.list = assessmentswithPagination.results;
    state.currentPage = payload.currentPage;
    state.perPage = payload.perPage;
  },
  setAssessment(state, assessment) {
    state.assessment = {...state.assessment, ...assessment };
  },
  addBenchmarkingDataToAssessment(state, benchmark_data) {
    state.assessment = {...state.assessment, ...benchmark_data };
  },
  deleteAssessment(state, id) {
    state.list = state.list.filter(item => item.assessment_id!==id);
  },
  setError(state, error) {
    state.error = error;
  },
  showSnackBar(state, text) {
    state.snackbarText = text;
    state.showSnackbar = true;
  },
  hideSnackBar(state) {
    state.snackbarText = null;
    state.showSnackbar = false;
  },
  setGroups(state, groups){
    state.groups = groups;
    state.loading = false;
  },
  deleteGroup(state, group_id){
    state.groups = [
      ...state.groups.filter((item) => item.group_id !== group_id)
    ];
    state.loading = false;
  },
  setMaturityData(state, maturityData) {
    state.maturityData = maturityData;
  },
  setParticipants(state, participants) {
    state.participants = participants;
  },
  setParticipantsLoading(state, value) {
    state.participantsLoading = value;
  },
  setMaturityDataLoading(state, value) {
    state.maturityDataLoading = value;
  },
  setMatGroups(state, matGroups) {
    state.matGroups = matGroups;
  },
  setAssessmentContainsGroups(state, assessmentContainsGroups) {
    state.assessmentContainsGroups = assessmentContainsGroups;
  },
  setSurveyParticipants(state, surveyParticipants) {
    state.surveyParticipants = surveyParticipants;
  },
  setQuestionsAndRatings(state, data) {
    let matData = state.maturityData.map(cluster => {
      let clusterDetailData = data.filter(d => d.cluster_id===cluster.id)[0];
      cluster.questionsAndRatings = clusterDetailData? clusterDetailData.data : [];
      return cluster;
    });
    state.maturityData = matData;
  },
  setAutoReportData(state, autoReportData) {
    state.autoReportData = autoReportData;
  },
  setParticipantsCountForAssessment(state, { participantsCount, assessmentId }) {
    let newAssessments = state.list.map(assessment => {
      if(assessment.assessment_id==assessmentId) {
        let final = [];
        let totalNumberOfParticipants = '';
        for (const key in participantsCount) {
          if(key =='total') {
            totalNumberOfParticipants = participantsCount[key];
          } else {
            final.push({ name: key, value: participantsCount[key] });
          }
        }
        assessment.totalParticipants = totalNumberOfParticipants;
        assessment.participantsPerGroup = final;
      }
      return assessment;
    });
    state.list = newAssessments;
  },
  updateIsDataReal(state, value) {
    state.assessment.is_data_real = value;
  },
  setPowerPointDownloadLoading(state, value) {
    state.powerPointDownloadLoading = value;
  },
  setApiLoading(state, value) {
    state.apiLoading = value;
  },
  setTypesOfCompaniesBySizeAndIndustry(state, value) {
    const sumOfAllTypesOfSizeAndIndustries = value;
    let typesByRevenueSize = [];
    let typesByIndustry = [];
    sumOfAllTypesOfSizeAndIndustries.map(type => {
      if (type.question_id === 2 && !type.is_question) {
        typesByRevenueSize.push(type);
      }
      if (type.question_id === 3 && !type.is_question) {
        typesByIndustry.push(type);
      }
    });
    state.typesByRevenueSize = typesByRevenueSize.sort((a, b) => a.position - b.position);
    state.typesByIndustry = typesByIndustry.sort((a, b) => a.position - b.position);
  },
  saveBenchmarkSelection(state, payload) {
    state.benchmarkFilters = payload;
  },
  resetStore(state) {
    const s = {
      list: [],
      assessment: {},
      groups: [],
      participants: {},
      matGroups: [],
      assessmentContainsGroups: [],
      surveyParticipants: [],
      questionsAndRatings: {},
      autoReportData: {},
      snackbarText: null,
      showSnackbar: false,
      loading: false,
      participantsLoading: false,
      maturityDataLoading: false,
      error: {},
      benchmarkFilters: {
        industries: [],
        sizes: [],
        all_countries: false
      },
    };
    for (let key in s) {
      state[key] = s[key];
    }
  }

};
