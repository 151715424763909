<template>
  <div class="table-legend">
    <div v-for="item in groupNames" v-bind:key="item" class="legend-row">
      <div v-if="item==='all_participants'">
        <span class="legend-icon square" style="background-color: #808080; opacity: 0.5;"></span>
        <span class="legend-icon square" style="background-color: #008000; opacity: 0.5;"></span>
        <label>Total</label>
      </div>
      <div v-else>
        <span class="legend-icon dot" :style="{'background-color': color(item)}"></span>
        <label>{{ item }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableLegend',
  props: {
    groupNames: {
      type: Array,
      required: true,
    },
    color: {
      type: Function,
      required: true,
    }
  }, 
};
</script>


<style lang="scss" scoped>
.table-legend {
  border: 2px solid #009688;
  border-radius: 5px;
  width: 20%;
  padding: 10px;
  font-size: 12px;
  box-shadow: 2px 2px 10px -3px grey;
}
.legend-row {
  margin-bottom: 2px;
}
.legend-icon {
  height:10px;
  width: 10px;
  display: inline-block;
  &.dot {
    border: 0.5px solid black;
    border-radius:90%;  
    margin-left: 10px;
    margin-right: 10px;
  }
  &.square {
    margin-right: 7px;
  }
}
</style>