var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c("div", { staticClass: "main main-raised" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("v-responsive", { staticClass: "md-alignment-centered" }, [
              _c(
                "div",
                { staticClass: "md-layout md-gutter md-alignment-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-60 md-small-size-100 mx-auto mt-12 mb-12"
                    },
                    [
                      _c("p", { staticClass: "success-msg" }, [
                        _vm._v(
                          "You just accepted ToU for maturity assessment by appliedAI. Thank you. "
                        )
                      ]),
                      _c("p", { staticClass: "success-msg" }, [
                        _vm._v(
                          "Please shortly inform your appliedAI main MAT contact via Mail in order to receive the links to perform the AI Maturity Assessment."
                        )
                      ])
                    ]
                  )
                ]
              )
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }