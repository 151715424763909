<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0">{{ title }}</h2>
    <p>{{ message }}</p>
    <div v-if="showEmailInputPart">
      <div class="md-small-size-100">
        <md-field>
          <label>(separate multiple email addresses using ',')</label>
          <md-input v-model="emailAddresses" @keyup.enter="_confirm" ref="nameInput"></md-input>
      </md-field> 
      </div>
      <div v-if="errorMsg" class="red-text">{{ errorMsg }}</div>
    </div>
    <div v-if="showUpdateDataPart">
      <div class="md-small-size-100">
        <md-field>
          <label>please insert new value</label>
          <md-input v-model="newData" @keyup.enter="_confirm" ref="nameInput"></md-input>
      </md-field> 
      </div>
      <div v-if="errorMsg" class="red-text">{{ errorMsg }}</div>
    </div>
    <div class="btns">
      <v-btn color="warning" @click="_cancel">
        {{ cancelButton }}
      </v-btn>
      <v-btn v-if="typeof okButton == 'string'" color="success" @click="_confirm">
        {{ okButton }}
      </v-btn>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue';

export default {
  name: 'ConfirmDialogue',
  components: { PopupModal },
  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: 'Go Back', // text for cancel button
    showEmailInputPart: undefined,
    showUpdateDataPart: undefined,
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
    emailAddresses: null,
    newData: null,
    errorMsg: null,
  }),
  watch: {
    emailAddresses(value) {
      this.emailAddresses = value;
    },
    newData(value) {
      this.newData = value;
    }
  },
  methods: {
    validateEmail(value){
      if(value){
        let emailsArray = value.split(',');
        let isValid = true;
        emailsArray.forEach(email => {
        //eslint-disable-next-line
         if (!email || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.replace(/\s/g,''))) {
            isValid = true;
          } else {
            this.errorMsg = 'The email address(es) that you provided is (are) invalid';
            isValid = false;
            throw new Error('The email address(es) that you provided is (are) invalid');
          } 
        });
        return isValid;
      }
      return true;
    },
    validateData(value) {
      if (!value) {
        this.errorMsg = 'You have to insert some value';
        return false;
      }
      return true;
    },
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      this.showEmailInputPart = opts.showEmailInputPart;
      this.emailAddresses = null;
      this.errorMsg = null;
      this.showUpdateDataPart = opts.showUpdateDataPart;
      this.newData = null;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      this.$nextTick(() => {
        if(this.showEmailInputPart) {
          this.$refs.email.$el.focus();
        } else if(this.showUpdateDataPart) {
          this.$refs.nameInput.$el.focus();
        }
      });
      
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      if(this.showEmailInputPart && this.validateEmail(this.emailAddresses)) {
        this.resolvePromise(this.emailAddresses? this.emailAddresses : true);
        this.$refs.popup.close();
      } else if(this.showUpdateDataPart && this.validateData(this.newData)){
        this.resolvePromise(this.newData? this.newData : true);
        this.$refs.popup.close();
      } else if(!this.showEmailInputPart && !this.showUpdateDataPart){
        this.resolvePromise(true);
        this.$refs.popup.close();
      }
    },
    _cancel() {
      this.resolvePromise(false);
      this.$refs.popup.close();
    },
  },
};
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.red-text {
  color: red;
  margin-bottom: 10px;
}
</style>
