<template>
  <div class="wrapper">
    <Header />
    <div class="main main-raised">
      <loading-indicator :is-loading="organization.loading" />
      <div class="container card-container">
        <v-responsive class="md-alignment-centered">
          <v-btn v-if="userRole!==Roles.Admin" color="primary" plain @click="$router.go(-1)">←Back</v-btn>
          <div v-if="userRole===Roles.Admin" class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <md-card class="md-layout">
                <md-card-content>
                  <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
                    <h3 class="text-primary">Account details</h3>
                  </div>
                  <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
                    <div class="md-layout-item">
                      <span class="boldText">Role: </span> {{ user.role }}
                    </div>
                    <div class="md-layout-item">
                      <span class="boldText">Name: </span>{{ user.name }}
                      <a class="stretched-link"  @click="updateUserDetails('name')">[change]</a>
                    </div>
                    <div class="md-layout-item">
                      <span class="boldText">E-mail address: </span> {{ user.email }}
                    </div>
                    <div class="md-layout-item">
                      <a class="custom-link" @click="updateAdminPassword()">[Reset admin password here]</a>
                    </div>
                  </div>
                </md-card-content>
              </md-card>
            </div>
          </div>
          <template v-if="userRole===Roles.AccountMaster || userRole===Roles.AccountUser">
          <div class="md-layout">
            <md-card class="md-layout-item md-size-60 md-small-size-100 mx-auto">
              <md-card-content>
                <div class="md-layout-item md-size-100 md-small-size-100 mx-auto mb-8">
                  <h3 class="text-primary">Account details</h3>
                </div>
                <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
                  <div class="md-layout-item">
                    <span class="boldText">Role: </span> {{ user.role }}
                  </div>
                  <div class="md-layout-item">
                    <span class="boldText">Name: </span>{{ user.name }}
                    <a v-if="userRole===Roles.AccountMaster" class="stretched-link" @click="updateUserDetails('name')">[change]</a>
                  </div>
                  <div class="md-layout-item">
                    <span class="boldText">E-mail address: </span> {{ user.email }}
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>

          <div class="md-layout">
            <md-card class="md-layout-item md-size-60 md-small-size-100 mx-auto">
              <md-card-content>
                <div class="md-layout-item md-size-100 md-small-size-100 mx-auto mb-8">
                  <h3 class="text-primary">Settings</h3>
                </div>
                <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
                  <div class="md-layout-item md-size-100">
                    <b>"Download Report slides"</b> mode. Optimise downloaded file format for:
                  </div>
                  <div class="md-layout mx-auto">
                    <div class="md-layout-item md-size-50">
                      <md-radio v-model="optimizePresentationForGSlides" :value="true" @change="savePrefferedOptimization">Google slides</md-radio>
                      </div>
                    <div class="md-layout-item md-size-50">
                      <md-radio v-model="optimizePresentationForGSlides" :value="false" @change="savePrefferedOptimization">PowerPoint</md-radio>
                    </div>
                    <div>
                      <label style="font-size: 12px;">* Information: Select the platform/software you will work on the downloaded slides, so the plotting of the slides will be optimised for the selected one.</label>

                    </div>

                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </template>

          <br />
          <template v-if="userRole===Roles.Admin">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-medium-size-50 mx-auto">
                <div class="md-layout">
                  <div class="md-layout-item md-size-40 md-large-size-100 mb-10 mx-auto">
                    <v-btn x-large color="success" @click="newAssessment">
                      <i class="material-icons">add</i>
                      &nbsp;Populate Assessment
                    </v-btn>
                  </div>
                  <div class="md-layout-item md-size-60 md-large-size-100 mx-auto">
                    <v-btn x-large color="success" @click="downloadBenchmarkData">
                      <template v-if="showDownloadingLoader"><v-progress-circular color="white" indeterminate />&nbsp;&nbsp;</template>
                      <v-icon>mdi-archive-arrow-down-outline</v-icon>
                      &nbsp; Global Benchmark Database
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-medium-size-50 mx-auto my-xl-0 my-sm-10">
                <v-btn x-large color="success" :disabled="isAutoFindingsLoading" @click="handleFileInputClick">
                  <template v-if="isAutoFindingsLoading"><v-progress-circular color="white" indeterminate />&nbsp;&nbsp;</template>
                  <i v-else class="material-icons">add</i>
                    &nbsp;Update Findings Library
                </v-btn>
                <input ref="fileInput" type="file" accept=".xlsx" class="d-none" @change="onFileSelected" @click="clearSelectedValue">
              </div>
            </div>
            <div class="md-layout" v-if="userRole!==Roles.AccountUser && !organizations.loading">
              <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
                <h3 class="text-primary">Account management</h3>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
                <div v-if="organizations.length===0" class="md-layout-item">
                  <h4>No organizations added yet</h4>
                </div>
                <div  v-else class="md-layout">
                  <div class="md-layout-item">
                    <div v-for="org in organizations" v-bind:key="org.country_name" class="borderBox">
                      <h4 class="headerBoldText">{{ org.country_name }}
                        <a v-if="org.nationalHost" class="stretched-link" @click="goToPage('organizationView', { id: org.nationalHost.id })">{{ org.nationalHost.name + ' (National host)' }}</a>
                        <span v-else >National host has not been added</span>
                      </h4>
                      <h6 v-if="org.certifiedPartners.length>0" v-for="certifiedPartner in org.certifiedPartners" @click="goToPage('organizationView', { id: certifiedPartner.id})" v-bind:key="certifiedPartner.name" class="sub-header">
                        <a class="stretched-link">{{ certifiedPartner.name + ' (certified partner)' }}</a>
                      </h6>
                      <h6 class="natHostName" v-if="org.certifiedPartners.length==0">Certified partners have not been added yet!</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <v-btn color="primary" class="fixed-width-btn" @click="goToPage('createOrganization', { organization_type: 'certified-partner' })">Add certified partner</v-btn>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100 fixed-width-btn">
                    <v-btn color="primary" 
                    class="fixed-width-btn" @click="goToPage('createOrganization', { organization_type: 'national-host' })">Add national host</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="userRole===Roles.AccountMaster">
            <div class="md-layout" v-if="collaboratorAccounts.length>0">
              <div class="md-layout-item md-size-60 md-small-size-100 mx-auto">
                <h4 class="boldText">Collaborators:</h4>
              </div>
            </div>
            <div class="md-layout" v-for="collaborator in collaboratorAccounts" v-bind:key="collaborator.email">
              <div class="md-layout-item md-size-60 md-small-size-100 mx-auto">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ collaborator.name }}</span>
                      </template>
                      <span>{{ collaborator.email }}</span>
                    </v-tooltip>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <div class="md-layout">
                      <div class="md-layout-item md-small-size-66 md-large-size-66" @click="updateDetailsForOtherUser(collaborator.id, 'name')">
                        <a class="stretched-link">[change name]</a>
                      </div>
                      <div class="md-layout-item md-small-size-33 md-large-size-33" @click="deleteUser(collaborator.id, `${collaborator.name} (${collaborator.email})`)">
                        <a class="stretched-link">[delete]</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <br />
            <div class="md-layout">
              <div class="md-layout-item md-size-60 md-small-size-100 mx-auto">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <v-btn color="primary" class="fixed-width-btn" @click="goToPage('userAccountCreate', { account_type: 'account-user', organization_id: organization.organization_id, userRole: userRole })">Add collaborator</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </v-responsive>
        </div>
      </div>
      <popup-modal ref="deletePopUp">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <h2>Are you sure you want to delete this account?</h2>
            <h3>{{ deletePopUpTitle }}</h3>
          </div>
          <div class="md-layout-item md-layout md-size-100 md-alignment-center-space-between">
            <v-btn class="md-layout-item md-size-30" color="warning" @click="_cancel()">Cancel</v-btn>
            <v-btn class="md-layout-item md-size-30" color="success" @click="_confirm()">Confirm</v-btn>
          </div>
        </div>
      </popup-modal>
      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
      <snackbar />
    </div>
  </template>
  
<script>
import backend_connector from '../mixins/backend_connector';
import { LoadingIndicator, Header, ConfirmDialogue, PopupModal, Snackbar } from '@/components';
import { Roles } from '@/auth/roles';
import { mapActions, mapState } from 'vuex';
import { http } from '@/utils';
  
export default {
  name: 'AccountDetails',
  mixins: [backend_connector],
  bodyClass: 'index-page',
  components: {
    Header,
    LoadingIndicator,
    ConfirmDialogue,
    PopupModal,
    Snackbar
  },
  props: {
    userRole: String,
  },
  data: () => ({
    deletePopUpTitle: null,
    showDownloadingLoader: false,
    isAutoFindingsLoading: false,
  }),
  mounted() {
    this.getUserDetailsAction();
    if(this.userRole===Roles.Admin) {
      this.getOrganizationsAction();
      this.getListOfCountries();
    } else if(this.userRole===Roles.AccountMaster) {
      this.getOrganizationCollaboratorsAction();
      this.getPreferredOptimizationForPresentationAction();
    } else if(this.userRole===Roles.AccountUser) {
      this.getPreferredOptimizationForPresentationAction();
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      organizations: state => state.organizations.list,
      organization: state => state.organizations.organization,
      isLoading: state => state.assessments.loading,
      shouldOptimizeForGSlides: state => state.user.preferredOptimizationForGSlide,
    }),
    collaboratorAccounts() {
      return this.organization.loading===false? this.$store.getters['organizations/getUserAccounts'] : [];
    },
    Roles() {
      return Roles;
    },
    optimizePresentationForGSlides: {
      get() {
        return this.shouldOptimizeForGSlides;
      },
      set(value) {
        this.updateShouldOptimizeForGSlides(value);
      }
    }
  },
  methods: {
    ...mapActions({
      getUserDetailsAction: 'user/getUserDetails',
      updateUserDetailsAction: 'user/updateUserDetails',
      updateAdminPasswordAction: 'user/updateAdminPassword',
      getOrganizationsAction: 'organizations/getOrganizations',
      getListOfCountries: 'organizations/getListOfCountries',
      getOrganizationCollaboratorsAction: 'organizations/getOrganizationCollaborators',
      deleteUserAction: 'organizations/deleteUser',
      hideSnackBarAction: 'assessments/hideSnackBar',
      showSnackBarAction: 'assessments/showSnackBar',
      createAssessment: 'assessments/createAssessment',
      createBenchmarkData: 'assessments/createBenchmarkData',
      updateAutoFindingsData: 'assessments/updateAutoFindingsData',
      updatePresentationForGSlidesAction: 'user/updatePresentationForGSlides',
      getPreferredOptimizationForPresentationAction: 'user/getPreferredOptimizationForPresentation',
      updateShouldOptimizeForGSlides: 'user/updateShouldOptimizeForGSlides',
      updateDetailsForOtherUserAction: 'organizations/updateDetailsForOtherUser',
    }),
    async newAssessment() {
      // TODO: call mat_assessments (POST view already exists) and create new assessment
      // Save assessemnt_id, date_started, date_closed and pass on to Config.
      // Config auto. handles empty mat_assessments (no groups, no b-questions answered).
  
      // Before mat_assessment post call release_id and company_id have to be known.
      // release_id simply get the most recent one (call to generics backend)
      // company_id needs to be newly created or existing one fetched.
  
      // create new assessment
      this.createAssessment(this.openConfig);
    },
    handleFileInputClick() {
      this.$refs.fileInput.click();
    },
    clearSelectedValue() {
      this.$refs.fileInput.value = '';
    },
    onFileSelected(e) {
      let uploadedFile = e.target.files[0];
      if (uploadedFile) {
        this.isAutoFindingsLoading = true;
        this.updateAutoFindingsData({
          formData: uploadedFile,
          cb: (msg) => {
            this.isAutoFindingsLoading = false;
            this.showSnackBarAction(msg);
          },
        });
      }
    },
    downloadBenchmarkData(){
      this.showDownloadingLoader = true;
      this.createBenchmarkData({ cb: (msg, file) => {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Global Database Report.xlsx');
        document.body.appendChild(link);
        link.click();
        this.showSnackBarAction(msg);
        http.defaults.responseType = '';
        this.showDownloadingLoader = false;
      }});
    },
    openConfig(assessment_id) {
      // open config panel for new assessment
      this.$router.push({ name: 'config', params: { assessment_id } });
    },
    goToPage(pageName, params) {
      this.$router.push({ name: pageName, params: params });
    },
    hideSnackbar(){
      this.hideSnackBarAction();
    },
    async deleteUser(id, email) {
      this.deletePopUpTitle = email;
      const ok = await this.showDialog();
      if(!ok) {
        return;
      }
      this.deleteUserAction({ id, cb: (msg) => this.showSnackBarAction(msg) });
    },
    showDialog(){
      return this.show().then();
    },
    show() {
      this.$refs.deletePopUp.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.resolvePromise(true);
      this.$refs.deletePopUp.close();
    },
    _cancel() {
      this.$refs.deletePopUp.close();
      this.rejectPromise(new Error('User cancelled the dialogue'));
    },
    async updateDetailsForOtherUser (userId, fieldName) {
      const newUserName = await this.$refs.confirmDialogue.show({
        title: 'Insert new name here:',
        okButton: 'Update',
        cancelButton: 'Cancel',
        showUpdateDataPart: true,
      });
        //TODO: If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (newUserName) {
        this.updateDetailsForOtherUserAction({ 
          userId, 
          payload: {
            item_name: fieldName, item_value: newUserName
          }, 
          cb: () => this.showSnackBarAction('Update successfull!')
        });
      }
    },
    async updateUserDetails(fieldName) {
      const newUserName = await this.$refs.confirmDialogue.show({
        title: 'Insert new name here:',
        okButton: 'Update',
        cancelButton: 'Cancel',
        showUpdateDataPart: true,
      });
        //TODO: If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (newUserName) {
        this.updateUserDetailsAction({ fieldName, value: newUserName});
      }
    },
    async updateAdminPassword() {
      const okClicked = await this.$refs.confirmDialogue.show({
        title: 'Are you sure you want to reset the password of the admin user?',
        okButton: 'Yes',
        cancelButton: 'No',
      });
      if (okClicked) {
        this.updateAdminPasswordAction(this.user.email);
      }
    },
    savePrefferedOptimization(){
      this.updatePresentationForGSlidesAction({ shouldOptimizeForGSlides: this.optimizePresentationForGSlides, cb: (msg) => this.showSnackBarAction(msg)});
    }
  }
};
</script>
  
<style lang="scss">
.wrapper p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #222;
  text-align: left;
}
.sub-header {
  padding: 5px;
  padding-left: 50px;
}
.boldText {
  font-weight: 600;
}
.md-theme-default a:not(.md-button).custom-link {
  color: #009688
}
.fixed-width-btn {
  width: 220px;
  margin-bottom: 10px;
}
.borderBox {
  margin-bottom: 35px;
  border-bottom: 1px solid #00000029;
  padding-bottom: 15px;
}
.natHostName {
  padding: 5px;
  padding-left: 35px;
}
.headerBoldText{
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  :before {
    content:" - ";
  }
}
.sub-header:before {
  content:"• ";
}
.card-container {
  font-size: 19px;
}
.card-container .md-card .md-card-content {
  padding: 28px 0px;
  font-size: 19px;
}
</style>
  