var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c("div", { staticClass: "main main-raised" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("v-responsive", { staticClass: "md-alignment-centered" }, [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("Heading", { attrs: { variant: "h2" } }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "md-layout text-justify" }, [
                _c(
                  "div",
                  { staticClass: "panel-container" },
                  [
                    _c(
                      "v-expansion-panels",
                      { attrs: { focusable: "", multiple: "" } },
                      [_vm._t("default")],
                      2
                    )
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }