export default {
  setUser (state, user) {
    if (user) {
      state.email = user.email;
      state.name = user.name;
      state.role = user.role;
      state.loading = false;
    } else {
      state.email = null;
      state.name = null;
      state.role = null;
    }
  },
  updateUserProperty(state, obj) {
    state[obj.fieldName] = obj.value;
  },
  setError(state, error) {
    state.error = error;
  },
  setUserPreferredOptimization(state, preferredOptimizationForPresentation) {
    state.preferredOptimizationForGSlide = preferredOptimizationForPresentation[0].ppt_optimized_for_g_slides;
  },
  updateShouldOptimizeForGSlides(state, value) {
    state.preferredOptimizationForGSlide = value;
  },
};
  