var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.wrapperClass }, [
    _c("img", {
      staticClass: "user-guide-image",
      style: { width: _vm.width },
      attrs: { src: _vm.imageSrc }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }