import user from '../../../api/user';
import { tackleErrorMessage } from '../../../api';

export const getAuthUser = async ({ commit }, user) => {
  commit('saveUser', user);
};

export const getUserDetails = async ({ commit, dispatch }) => {
  try {
    const response = await user.getUserDetails();
    commit('setUser', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const updateUserDetails = async ({ commit, dispatch }, { fieldName, value }) => {
  try {
    await user.updateUserDetails(fieldName, value);
    commit('updateUserProperty', { fieldName, value });
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const updateDetailsForOtherUser = async ({ commit, dispatch }, { userId, payload, cb }) => {
  try {
    await user.updateDetailsForOtherUser(userId, payload);
    commit('updateUserProperty', { fieldName: payload.item_name, value: payload.item_value });
    cb();
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const updateUserById = async ({ commit, dispatch }, { id, payload, cb }) => {
  try {
    await user.updateUserById(id);
    commit('updateUserProperty', { role: payload.role });
    cb();
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const updateAdminPassword = async ({ dispatch }, email) => {
  try {
    await user.updateAdminPassword(email);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const createUserAccount = async ({ dispatch }, { payload, organizationId, cb }) => {
  try {
    await user.createUserAccount(payload, organizationId);
    cb(true, 'User successfully created!');
  } catch (error) {
    cb(false);
    tackleErrorMessage(dispatch, error);
  }
};

export const getUserById = async ({ commit, dispatch }, id) => {
  commit('setUser', null);
  try {
    const response = await user.getUserById(id);
    commit('setUser', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const updatePresentationForGSlides = async ({ dispatch }, { shouldOptimizeForGSlides, cb }) => {
  try {
    await user.updatePresentationForGSlides(shouldOptimizeForGSlides);
    cb('Succesfully updated!');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getPreferredOptimizationForPresentation = async ({ commit, dispatch }) => {
  commit('setUser', null);
  try {
    const response = await user.getPreferredOptimizationForPresentation();
    commit('setUserPreferredOptimization', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const updateShouldOptimizeForGSlides = ({ commit }, value) => {
  commit('updateShouldOptimizeForGSlides', value);
};
