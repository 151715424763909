import { render, staticRenderFns } from "./ExpansionPanels.vue?vue&type=template&id=03c310e4&scoped=true&"
import script from "./ExpansionPanels.vue?vue&type=script&lang=js&"
export * from "./ExpansionPanels.vue?vue&type=script&lang=js&"
import style0 from "./ExpansionPanels.vue?vue&type=style&index=0&id=03c310e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03c310e4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VExpansionPanels,VResponsive})


/* hot reload */
if (module.hot) {
  var api = require("/builds/iaai/products/maturity-assessment-tool/mat-website/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03c310e4')) {
      api.createRecord('03c310e4', component.options)
    } else {
      api.reload('03c310e4', component.options)
    }
    module.hot.accept("./ExpansionPanels.vue?vue&type=template&id=03c310e4&scoped=true&", function () {
      api.rerender('03c310e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ExpansionPanels.vue"
export default component.exports