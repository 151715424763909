<template>
  <div class="wrapper">
    <Header />
    <div class="main main-raised">
      <div class="container">
        <v-responsive class="md-alignment-centered">
          <div class="md-layout md-gutter md-alignment-center text-center">
            <div class="md-layout-item md-size-60 md-small-size-100 mx-auto">
              <h2 class="top-header">Terms of Use for the Maturity Assessment Tool</h2>
              <br />  <br />  
              <p>Dear colleague,</p>
              <p>Below are presented the Terms of Use for the Maturity Assessment Tool (MAT) developed and provided by the appliedAI Initiative. These terms have to be read, understood and accepted in order for you and your colleagues to take part in the assessment.</p>
              <br />
              <p class="boldText">Please Scroll down the Terms of Use document and click accept Terms of Use button at the end of this page.</p>
              <div id="vue-pdf-embed">
                <vue-pdf-embed :source="src" />
              </div>
              <p>by clicking the following link you accept the MAT Terms of Use:</p>
              <br />  
              <v-btn color="primary" @click="acceptTermsOfService" :disabled="disabledButton">
                Accept Terms of Use
              </v-btn>
              
              <br />  <br />
              <p>If you are interested in more information about the AI maturity assessment tool, please visit this website.</p>
              <p>If you have any further questions do not hesitate to contact appliedAI initiative anytime.</p>
                <br />  <br />
            </div>
          </div>
        </v-responsive>
      </div>
    </div>
    <snackbar />
  </div>
</template>

<script>
import backend_connector from '../mixins/backend_connector';
import Header from '@/components/Header';
import Snackbar from '@/components/Snackbar.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { mapActions } from 'vuex';

export default {
  name: 'previewTermsOfUse',
  mixins: [backend_connector],
  components: {
    Header,
    VuePdfEmbed,
    Snackbar
  },
  bodyClass: 'index-page',
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      src: '/docs/termsOfService.pdf',
      disabledButton: true,
    };
  },
  mounted () {
    document.getElementById('vue-pdf-embed').addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    document.getElementById('vue-pdf-embed').removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions({
      termsOfUseAccepted: 'assessments/termsOfUseAccepted',
    }),
    acceptTermsOfService() {
      this.termsOfUseAccepted({payload: {
        assessment_id: this.$route.params.assessment_id,
        redirect_url: `${window.location.origin}/config/${this.$route.params.assessment_id}`,
        ...this.$route.query
      }, cb: () => {
        this.$router.push({
          name: 'termsOfUse',
        });
      }});
    },
    handleScroll() {
      const termsOfUseContainer = document.getElementById('vue-pdf-embed');
      if(this.disabledButton && termsOfUseContainer.scrollTop >= termsOfUseContainer.scrollHeight - termsOfUseContainer.clientHeight -100) {
        // near bottom
        this.disabledButton = false;
      }
    }
  }
};
</script>

<style scoped>
#vue-pdf-embed {
  overflow-y: scroll;
  height: 900px;
}
.top-header {
  font-family: Arial,sans-serif;
  font-size: 26px;
  line-height: 29px;
  color: #00747b;
  font-weight: bold;
}
p {
  font-family:Arial,sans-serif;
  font-size:16px;
  line-height:22px;
  color:#434343
}
.boldText {
  font-weight: 600;
}
</style>