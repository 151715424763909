<template>
  <div class="wrapper">
   <Header />
    <div class="main main-raised">
      <loading-indicator :is-loading="isLoading" />
      <div class="container">
        <v-responsive class="md-alignment-centered">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-left">
              <h2 class="text-center">My Assessments</h2>
              <p>
                Welcome to the AI maturity assessment tool! Below you find an
                overview of your assessments. By clicking on
                <span class="help__ui-label">Configure Assessment</span> you can
                edit existing assessments.
              </p>
              <p>
                Choose
                <span class="help__ui-label">view assessment results</span> to
                get an evaluation of your answers or select
                <span class="help__ui-label">new assessment</span> to create an
                additional assessment.
              </p>
              <p>
                If this is your first time performing this maturity assessment,
                please click
                <span class="help__ui-label">new assessment</span> to initiate
                your first assessment.
              </p>
            </div>
            <div class="new-assessment md-layout-item md-size-100 md-small-size-100 mx-auto">
              <v-btn x-large color="success" @click="newAssessment">
                <i class="material-icons">add</i>
                New assessment
              </v-btn>
            </div>
          </div>
          <!-- Available assessments -->
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <assessment-table @assessment-change="onAssessmentChange" :configsOn="true"/>
            </div>
          </div>
        </v-responsive>
      </div>
    </div>
  </div>
</template>

<script>
import AssessmentTable from '@/components/AssessmentTable';
import LoadingIndicator from '@/components/LoadingIndicator';
import Header from '@/components/Header';
import { mapActions } from 'vuex';

export default {
  name: 'Index',
  components: {
    Header,
    AssessmentTable,
    LoadingIndicator,
  },
  bodyClass: 'index-page',
  props: {
    isLoading: Boolean,
    userRole: String
  },
  methods: {
    ...mapActions({
      createAssessment: 'assessments/createAssessment'
    }),
    async newAssessment() {
      // TODO: call mat_assessments (POST view already exists) and create new assessment
      // Save assessemnt_id, date_started, date_closed and pass on to Config.
      // Config auto. handles empty mat_assessments (no groups, no b-questions answered).

      // Before mat_assessment post call release_id and company_id have to be known.
      // release_id simply get the most recent one (call to generics backend)
      // company_id needs to be newly created or existing one fetched.

      // create new assessment
      this.createAssessment(this.openConfig);
    },
    openConfig(assessment_id) {
      // open config panel for new assessment
      this.$router.push({ name: 'config', params: { assessment_id } });
    },
    openLanding(assessment_id) {
      this.$router.push({ name: 'landing', params: { assessment_id } });
    },
    onAssessmentChange ({ assessmentId, shouldConfigure }) {
      shouldConfigure === true? this.openConfig(assessmentId) : this.openLanding(assessmentId);
    },
  },
};
</script>

<style lang="scss">
@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
.new-assessment {
  margin: 32px 0;
}
.help {
  &__ui-label {
    font-weight: 400;
    text-transform: capitalize;
  }
}
</style>
