import { http } from '@/utils';

export default {
  getAssessments(payload) {
    let shouldIncludeSearch = payload.searchByValue ? '&search=' + encodeURIComponent(payload.searchByValue): '';
    return http.get('/api/v1/polls/assessments/?page=' + payload.currentPage + '&page_size=' + payload.perPage + shouldIncludeSearch);
  },
  getAssessment(assessment_id) {
    return http.get(`api/v1/polls/assessments/${assessment_id}/`);
  },
  getAssessmentBenchmarkingData(assessment_id) {
    return http.get(`api/v1/polls/assessments/${assessment_id}/benchmarking_data`);
  },
  deleteAssessment (assessment_id) {
    return http.delete(`api/v1/polls/assessments/${assessment_id}/`);
  },
  createAssessment(payload){
    return http.post('api/v1/polls/assessments/', payload);
  },
  updateAssessmentName(assessment_id, payload){
    return http.patch(`api/v1/polls/assessments/${assessment_id}/`, payload);
  },
  startAssessment(assessment_id, organization_id){
    return http.post(`api/v1/polls/assessments/${assessment_id}/start/`, { organization_id: organization_id });
  },
  closeAssessment(assessment_id){
    return http.post(`api/v1/polls/assessments/${assessment_id}/stop/`);
  },
  sendMailWithSurveyLinks(assessment_id, recipients, is_reminder){
    return http.post(`api/v1/polls/assessments/${assessment_id}/send_survey_links/`, {recipients, is_reminder });
  },
  getGroups(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/groups_and_versions/`);
  },
  createGroup(assessment_id, payload){
    return http.post(`api/v1/polls/assessments/${assessment_id}/groups_and_versions/`, payload);
  },
  deleteGroup(assessment_id, group_id){
    return http.delete(`api/v1/polls/assessments/${assessment_id}/groups/${group_id}/groups_and_versions/`);
  },
  getQuestions(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/b_questions_and_answers/`);
  },
  saveAnswers({assessment_id, ...payload} ){
    return http.post(`api/v1/polls/assessments/${assessment_id}/b_questions_and_answers/`, payload);
  },
  getMaturityData(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/dimension_maturities/`);
  },
  getParticipants(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/participants/`);
  },
  getSurveyParticipantsCount(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/survey_participation_counts/`);
  },
  getMatGroups(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/groups/`);
  },
  getAssessmentContainsGroups(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/assessment_contains_groups/`);
  },
  getSurveyParticipants(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/survey_participations/`);
  },
  getAllClustersDetailData(assessment_id){
    return http.get(`api/v1/polls/assessments/${assessment_id}/clusters_detail_data/`);
  },
  getClusterDetailData({assessment_id, cluster_id}){
    return http.get(`api/v1/polls/assessments/${assessment_id}/clusters_detail_data/?cluster_id=${cluster_id}`);
  },
  getAutoReportData({assessment_id, ...params}){
    return http.get(`api/v1/polls/assessments/${assessment_id}/report_data/`, { params });
  },
  saveIsDataReal({ assessment_id, isDataReal }){
    return http.patch(`api/v1/polls/assessments/${assessment_id}/`, { is_data_real: isDataReal });
  },
  mergeGroups(payload){
    return http.post('api/v1/polls/merge_two_groups/', payload);
  },
  sendEmailWithTermsOfUse(assessment_id, emailAddress, redirectUrl) {
    return http.post(`api/v1/users/assessments/${assessment_id}/send_terms_of_use/`, { recipients: emailAddress, redirect_url: redirectUrl });
  },
  termsOfUseAccepted({ assessment_id, expiration_date, redirect_url}) {
    return http.post(`api/v1/users/assessments/${assessment_id}/accept_terms_of_use/`, { expiration_date: expiration_date, redirect_url: redirect_url });
  },
  downloadPowerpointSlides({ assessment_id, benchmark_data, include_autofindings }) {
    http.defaults.responseType = 'blob';
    return http.post(`api/v1/polls/assessments/${assessment_id}/generate_pptx_report/`, { benchmark_data: benchmark_data, include_autofindings: include_autofindings });
  },
  getTypesOfCompanies(assessment_id) {
    return http.get(`api/v1/polls/assessments/${assessment_id}/benchmarking_questions_text/`);
  },
  createBenchmarkData() {
    http.defaults.responseType = 'blob';
    return http.post('api/v1/polls/generate_excel_global_database/');
  },
  applyBenchmarkSelection({assessment_id, benchmark_data}) {
    return http.post(`api/v1/polls/assessments/${assessment_id}/check_benchmarking_applied_filters/`, benchmark_data);
  },
  updateAutoFindingsData(fileData) {
    const formData = new FormData();
    formData.append('findings', fileData);
    return http.post('api/v1/polls/findings/', formData);
  },
};
