<template>
  <div class="wrapper">
    <Header />
    <confirm-dialogue ref="confirmDialogue" style="z-index: 100; position: fixed"></confirm-dialogue>
    <div class="main main-raised" v-if="this.assessment.assessment_id">
      <loading-indicator :is-loading="loading" />
      <div class="container md-layout text-justify">
        <v-responsive class="md-alignment-centered">
          <div class="text-center" :key="this.assessment.name">
            <h2>Configure Assessment {{ assessmentNewName || this.assessment.name }}</h2>
          </div>
          <div class="md-layout-item md-size-70 md-small-size-100 mx-auto">
            <p>
              This page allows you to configure your maturity assessment. Please read through this page carefully. You can always come back to this page later to make changes until you have started your assessment.
            </p>
            <p>There are three steps to go through:</p>
            <ol>
              <li>Define your assessment's name</li>
              <li>Provide information about your organization for benchmarking purposes</li>
              <li>Define groups inside your organization to distinguish in the assessment results</li>
            </ol>
            <h3>Assessment Name</h3>
            <div class="md-layout-item md-small-size-100">
              <form novalidate class="md-layout" @submit="setNewAssessmentName">
                <md-card>
                  <md-card-content>
                    <md-field>
                      <label>Assessment Name</label>
                      <md-input v-model="assessmentName"></md-input>
                    </md-field>
                  </md-card-content>
                  <md-card-actions>
                    <v-btn color="primary" type="submit">Save</v-btn>
                  </md-card-actions>
                </md-card>
              </form>
            </div>
          </div>
          <div class="md-layout-item md-size-70 md-small-size-100 mx-auto">
            <h3>About your organization</h3>
            <p>
              We collect this information to be able to allow for the
              selection of appropriate benchmarking data in the future - it is
              therefore important that you answer these questions correctly.
            </p>
            <p>
              You can come back to this page and change information stored
              here any time until you have started the assessment.
            </p>
          </div>
          <div class="md-layout-item md-size-70 md-small-size-100 mx-auto">
            <benchmark-question-table
              :assessment_id="this.assessment.assessment_id"
              :key="this.assessment.assessment_id"
              :readonly="false"
            />
          </div>
          <div class="md-layout-item md-size-70 md-small-size-100 mx-auto">
            <p>
              To observe where organizations with different characteristics (e.g.,site, country) are standing with regards to AI, we are building a benchmarking pool based on the above. Please note that the Benchmark feature is built into the tool in a way that protects report anonymity. It will only appear in the downloaded PowerPoint if there are enough assessments. If you want to opt out of including this assessment in the Benchmark Pool, please select one of the following options:
              </p>
              <div class="md-layout">
                <v-radio-group v-model="isDataReal">
                  <div class="md-layout-item md-size-70 md-small-size-100 mx-auto">
                    <v-radio label="Yes, include this assessment." :value="true" ></v-radio>
                  </div>
                  <div class="md-layout-item md-size-70 md-small-size-100 mx-auto">
                    <v-radio label="No, do not include this assessment." :value="false"></v-radio>
                  </div>
              </v-radio-group>
            </div>
            <p><b>Notice:</b> Please remember that every assessment you include in the Benchmark Pool is included in your region's Benchmark calculation and contributes to having more Benchmark data in your country.</p>
            <h3>Assessment Groups</h3>
            <p>
              The maturity assessment tool allows you to distinguish between
              multiple groups inside your organization. This helps to identify
              deviating perceptions and knowledge gaps inside your
              organization. Popular choices for groups are - for example - the
              core AI team, (higher/middle) management or business units. We
              suggest to make sure that there are at least five participants
              per group to ensure anonymity.
              <br /><br />
              For each group you can decide between two versions of the
              maturity assessment - either including or excluding technical
              questions. We suggest to use the version with technical
              questions for your IT department or core AI team, the non-tech
              version for management or business functions.
              <br /><br />
              Please add any groups you want to distinguish below:
            </p>
          </div>
          <send-terms-of-service v-if="this.assessment.status === 'running'" :assessment_id="assessment.assessment_id" :terms_of_use_accepted="assessment.terms_of_use_accepted"/>
          <div class="md-layout md-gutter md-alignment-center">
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <!-- add, change, del is handled in component -->
              <!-- groups shall only be created/del when assessment isn't active -->
              <group-table
                v-if="this.assessment.status"
                ref="configGroupTable"
                :sme_version="sme_version"
                :assessment_id="this.assessment.assessment_id"
                :status="this.assessment.status"
                :areTermsAccepted="this.assessment.terms_of_use_accepted"
                :userRole="userRole"
              />
            </div>
          </div>

          <div v-if="userRole===Roles.Admin" class="md-layout-item md-size-70 md-small-size-100 mx-auto">
            <form novalidate class="md-layout" @submit="setOrganizationForAssessment">
              <md-card>
                <md-card-content>
                  <md-field>
                    <label>Organization</label>
                    <md-select name="selectedOrganization" id="selectedOrganization" v-model="selectedOrganization" >
                        <template v-for="org in organizations" >
                          <md-option v-bind:key="org.country_name" :value="org.nationalHost.id"
                            >{{ org.nationalHost.name }}</md-option>
                            <template v-for="cps in org.certifiedPartners" >
                              <md-option v-bind:key="cps.name" :value="cps.id"
                            >{{ cps.name }}</md-option>
                            </template>
                        </template>
                      </md-select>
                  </md-field>
                </md-card-content>
                <md-card-actions>
                  <v-btn color="primary" type="submit">Save</v-btn>
                </md-card-actions>
              </md-card>
            </form>
          </div>
          <div class="main-button-bottom">
            <div class="md-layout-item md-size-70 md-small-size-100 mx-auto">
              <div v-if="this.assessment.status === 'created'" class="md-layout-item md-size-25 md-small-size-100 mx-auto">
                <v-btn v-if="userRole===Roles.Admin" large color="success" :loading="showActionLoader" :disabled="showActionLoader" @click="changeAssessmentState('populateDB')">Populate the database</v-btn>
                <v-btn v-else large color="success" :loading="showActionLoader" :disabled="showActionLoader" @click="changeAssessmentState('running')">Start Assessment</v-btn>
              </div>
              <div v-if="this.assessment.status === 'running'">
                <h3>Done filling out the surveys?</h3>
                <p>
                  Once you have completed the assessment - this means all you
                  colleagues have successully answered the survey you sent them
                  - use the button below to close the data collection phase.
                  This will close all survey access links and create your final
                  AI maturity report.
                </p>
                <v-btn large color="success" :loading="showActionLoader" :disabled="showActionLoader" @click="changeAssessmentState('completed')">Close Assessment</v-btn>
              </div>
            </div>
          </div>
          <div v-if="this.assessment.status === 'completed'" class="md-layout mx-auto text-justify">
            <h3>Assessment completed</h3>
            <p>
              This assessment has been completed. Please return to the main
              screen to access the assessment results.
            </p>
          </div>
        </v-responsive>
      </div>
    </div>
    <snackbar />
  </div>
</template>

<script>
import { GroupTable, BenchmarkQuestionTable, LoadingIndicator, Header, ConfirmDialogue, Snackbar, SendTermsOfService } from '@/components';
import backend_connector from '../mixins/backend_connector';
import { mapActions, mapState, mapGetters } from 'vuex';
import { Roles } from '@/auth/roles';
import { logger } from '@/utils';

export default {
  name: 'Config',
  mixins: [backend_connector],
  components: {
    Header,
    GroupTable,
    BenchmarkQuestionTable,
    ConfirmDialogue,
    LoadingIndicator,
    Snackbar,
    SendTermsOfService
  },
  bodyClass: 'index-page',
  props: {
    isLoading: Boolean,
    userRole: String
  },
  data: () => ({
    sme_version: null,
    // groups: null,
    assessmentNewName: '',
    showActionLoader: false,
    assessmentNewOrganization: '',
  }),
  mounted() {
    if(this.userRole===Roles.Admin) {
      this.getOrganizationsAction();
    } else {
      this.getGroupsAction({ 
        assessment_id: parseInt(this.$route.params.assessment_id),
        cb: () => {}});
    }
  },
  computed: {
    ...mapState({
      assessment: state => state.assessments.assessment,
      organizations: state => state.organizations.list,
      loading: state => state.assessments.loading,
      groups: state => state.assessments.groups,
    }),
    ...mapGetters({
      getIsDataReal: 'assessments/getIsDataReal',
      getAssessmentName: 'assessments/getAssessmentName',
      getOrganizationForAssessment: 'assessments/getOrganizationForAssessment',
    }),
    isDataReal: {
      get() {
        return this.getIsDataReal;
      },
      set(value) {
        this.saveIsDataRealAction({ assessment_id: this.assessment.assessment_id, isDataReal: value });
      }
    },
    Roles() {
      return Roles;
    },
    selectedOrganization: {
      get() {
        return this.getOrganizationForAssessment;
      },
      set(value) {
        this.assessmentNewOrganization = value;
        return value;
      }
    },
    assessmentName: {
      get() {
        return this.getAssessmentName;
      },
      set(value) {
        this.assessmentNewName = value;
        return value;
      }
    }
  },

  created() {
    // We get the assessment_id here to make it available for sub-components
    // https://medium.com/@brockreece/vue-parent-and-child-lifecycle-hooks-5d6236bd561f
    // https://v3.vuejs.org/guide/instance.html#lifecycle-hooks
    // https://v3.vuejs.org/api/options-lifecycle-hooks.html
    // Get assessment data passed in from Index.Vue
    this.getAssessment(this.$route.params.assessment_id);
  },
  beforeDestroy(){
    this.resetStore();
  },
  methods: {
    ...mapActions({
      getAssessment: 'assessments/getAssessment',
      updateAssessmentName: 'assessments/updateAssessmentName',
      startAssessmentAction: 'assessments/startAssessment',
      closeAssessmentAction: 'assessments/closeAssessment',
      sendMailWithSurveyLinksAction: 'assessments/sendMailWithSurveyLinks',
      resetStore: 'assessments/resetStore',
      saveIsDataRealAction: 'assessments/saveIsDataReal',
      getOrganizationsAction: 'organizations/getOrganizations',
      getGroupsAction: 'assessments/getGroups',
    }),

    async setOrganizationForAssessment(e) {
      e.preventDefault();
      const organization_id = this.assessmentNewOrganization;
      if (!organization_id) {
        this.showDialog('Please select some of the national hosts or certified partners in the organization field!', null);
      } else {
        this.updateAssessmentName({ assessment_id: this.assessment.assessment_id, msg: 'Assessment successfully connected with organization!', organization: organization_id });
      }
    },
    showDialog(title, message, okText, cancelText, showEmailInputPart) {
      return this.$refs.confirmDialogue
        .show({
          title,
          message,
          okButton: okText,
          cancelButton: cancelText,
          showEmailInputPart,
        })
        .then();
    },
    async setNewAssessmentName(e) {
      e.preventDefault();
      const new_name = this.assessmentNewName;
      if (new_name.length < 3) {
        this.assessmentName = new_name;
        this.showDialog('Invalid name for  assessment', 'The name you entered for your assessment is too short. Please use a name with at least three characters.', null,'OK');
      } else {
        this.updateAssessmentName({ assessment_id: this.assessment.assessment_id, msg: 'Assessment name changed successfully',name: new_name });
      }
    },
    async startAssessment() {
      logger.info('Starting assessment: ' + this.assessment.assessment_id);
      if (this.userRole===Roles.Admin && this.selectedOrganization=='') {
        this.showDialog('No organization selected', 'Please select some of the national hosts or certified partners in the organization field at the bottom of this page', null, 'OK');
        this.showActionLoader = false;
        return;
      }
      this.startAssessmentAction({
        assessment_id: this.assessment.assessment_id,
        organization_id: this.selectedOrganization,
        cb: ((isSuccess) => {
          if(isSuccess) {
            this.showActionLoader = false;
            this.userRole===Roles.Admin? this.$router.push({ name: 'index' }) : this.$router.push({ name: 'successfulCreation', params: { assessment_id: this.assessment.assessment_id }});
          } else this.showActionLoader = false;
        }),
      });
    },

    async closeAssessment() {
      logger.info('Closing assessment: ' + this.assessment.assessment_id);
      this.closeAssessmentAction({
        assessment_id: this.assessment.assessment_id,
        cb: ((isSuccess) => {
          if(isSuccess) {
            this.showActionLoader = false;
            this.$router.push({ name: 'index' });
          } else this.showActionLoader = false;
          
        }) ,
        // cbError: (() => {
        //   this.showActionLoader = false;
        // })
      });
    },
    async sendMailWithSurveyLinks() {
      const responseValue = await this.showDialog(
        null,
        'Please enter the email address of your contact person from the organization doing the assessment to send the links per email. A copy will be automatically sent to your address. To just send the reminder to your address leave the field blank.','Send the links via email', 'Cancel', true);
      if (responseValue) {
        let recipients = null;
        if (responseValue!==true) {
          recipients = responseValue;
        }
        this.sendMailWithSurveyLinksAction({ 
          assessment_id: this.assessment.assessment_id, 
          recipients: recipients, 
          is_reminder: false,
          cb: this.showDialog 
        });
      }
    },
    async changeAssessmentState(toState) {
      logger.info('Changing assessment state to: ' + toState);
      this.showActionLoader = true;
      if (toState === 'running') {
        // TODO: Check if all benchmarking questions have been answered and show popup if not.

        // show confirmation popup to user that asks whether he/she really wants to start assessment
        const ok = await this.showDialog('Start Assessment', 'Are you sure you want to start this assessment with the currently configured groups? ' +
            'This configuration cannot be changed once the assessment has been started. ' +
            'Do you want to start your assessment now?','Yes, start assessment now', 'No, back to editing');
        if (ok) {
          await this.startAssessment();
        } else this.showActionLoader = false;

      } else if (toState === 'completed') {
        // show confirmation popup to user that asks whether he/she really wants to stop the assessment
        const ok = await this.showDialog('Close Assessment', 'Are you sure want to close the assessment? This will close all access links to the survey and stop the data collection process. ' +
            'This step cannot be undone. Close the assessment now?','Yes, close assessment', 'No, keep assessment running');
        if (ok) {
          await this.closeAssessment();
        } else this.showActionLoader = false;
      } else if (toState === 'populateDB') {
        const ok = await this.showDialog('Start Assessment', 'Are you sure you want to create this assessment and populate it with the data from the collector IDs?' +
            'This configuration cannot be changed once the assessment has been started. ' +
            'Do you want to start your assessment now?','Yes, start assessment now', 'No, back to editing');
        if (ok) {
          await this.startAssessment();
        } else this.showActionLoader = false;
      }
    },
    saveIsDataReal() {
      this.saveIsDataRealAction({ assessment_id: this.assessment.assessment_id, isDataReal: this.isDataReal });
    }
  },
};
</script>
<style lang="scss">
.section-download {
  .v-btn + .v-btn {
    margin-left: 5px;
  }
}
.md-layout-item .md-radio.md-disabled .md-radio-label, .md-radio.md-disabled .md-radio-container {
  opacity: 1;
}
@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
.main-button-bottom {
  margin-top: 30px;
}
.md-field {
  max-width: 300px;
}
ul {
  margin: 0 0 10px;
  font-size: 14px;
}
.v-input--radio-group__input {
  flex-direction: row !important;
}
@media (max-width: 959px) {
  .v-input--radio-group__input {
  flex-direction: column !important;
}
}
</style>
