var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c("confirm-dialogue", {
        ref: "confirmDialogue",
        staticStyle: { "z-index": "100", position: "fixed" }
      }),
      this.assessment.assessment_id
        ? _c(
            "div",
            { staticClass: "main main-raised" },
            [
              _c("loading-indicator", { attrs: { "is-loading": _vm.loading } }),
              _c(
                "div",
                { staticClass: "container md-layout text-justify" },
                [
                  _c(
                    "v-responsive",
                    { staticClass: "md-alignment-centered" },
                    [
                      _c(
                        "div",
                        {
                          key: this.assessment.name,
                          staticClass: "text-center"
                        },
                        [
                          _c("h2", [
                            _vm._v(
                              "Configure Assessment " +
                                _vm._s(
                                  _vm.assessmentNewName || this.assessment.name
                                )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-70 md-small-size-100 mx-auto"
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " This page allows you to configure your maturity assessment. Please read through this page carefully. You can always come back to this page later to make changes until you have started your assessment. "
                            )
                          ]),
                          _c("p", [
                            _vm._v("There are three steps to go through:")
                          ]),
                          _c("ol", [
                            _c("li", [_vm._v("Define your assessment's name")]),
                            _c("li", [
                              _vm._v(
                                "Provide information about your organization for benchmarking purposes"
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                "Define groups inside your organization to distinguish in the assessment results"
                              )
                            ])
                          ]),
                          _c("h3", [_vm._v("Assessment Name")]),
                          _c(
                            "div",
                            { staticClass: "md-layout-item md-small-size-100" },
                            [
                              _c(
                                "form",
                                {
                                  staticClass: "md-layout",
                                  attrs: { novalidate: "" },
                                  on: { submit: _vm.setNewAssessmentName }
                                },
                                [
                                  _c(
                                    "md-card",
                                    [
                                      _c(
                                        "md-card-content",
                                        [
                                          _c(
                                            "md-field",
                                            [
                                              _c("label", [
                                                _vm._v("Assessment Name")
                                              ]),
                                              _c("md-input", {
                                                model: {
                                                  value: _vm.assessmentName,
                                                  callback: function($$v) {
                                                    _vm.assessmentName = $$v
                                                  },
                                                  expression: "assessmentName"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                type: "submit"
                                              }
                                            },
                                            [_vm._v("Save")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-70 md-small-size-100 mx-auto"
                        },
                        [
                          _c("h3", [_vm._v("About your organization")]),
                          _c("p", [
                            _vm._v(
                              " We collect this information to be able to allow for the selection of appropriate benchmarking data in the future - it is therefore important that you answer these questions correctly. "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " You can come back to this page and change information stored here any time until you have started the assessment. "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-70 md-small-size-100 mx-auto"
                        },
                        [
                          _c("benchmark-question-table", {
                            key: this.assessment.assessment_id,
                            attrs: {
                              assessment_id: this.assessment.assessment_id,
                              readonly: false
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-70 md-small-size-100 mx-auto"
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " To observe where organizations with different characteristics (e.g.,site, country) are standing with regards to AI, we are building a benchmarking pool based on the above. Please note that the Benchmark feature is built into the tool in a way that protects report anonymity. It will only appear in the downloaded PowerPoint if there are enough assessments. If you want to opt out of including this assessment in the Benchmark Pool, please select one of the following options: "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "md-layout" },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  model: {
                                    value: _vm.isDataReal,
                                    callback: function($$v) {
                                      _vm.isDataReal = $$v
                                    },
                                    expression: "isDataReal"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-70 md-small-size-100 mx-auto"
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label:
                                            "Yes, include this assessment.",
                                          value: true
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-size-70 md-small-size-100 mx-auto"
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label:
                                            "No, do not include this assessment.",
                                          value: false
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("p", [
                            _c("b", [_vm._v("Notice:")]),
                            _vm._v(
                              " Please remember that every assessment you include in the Benchmark Pool is included in your region's Benchmark calculation and contributes to having more Benchmark data in your country."
                            )
                          ]),
                          _c("h3", [_vm._v("Assessment Groups")]),
                          _c("p", [
                            _vm._v(
                              " The maturity assessment tool allows you to distinguish between multiple groups inside your organization. This helps to identify deviating perceptions and knowledge gaps inside your organization. Popular choices for groups are - for example - the core AI team, (higher/middle) management or business units. We suggest to make sure that there are at least five participants per group to ensure anonymity. "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              " For each group you can decide between two versions of the maturity assessment - either including or excluding technical questions. We suggest to use the version with technical questions for your IT department or core AI team, the non-tech version for management or business functions. "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              " Please add any groups you want to distinguish below: "
                            )
                          ])
                        ]
                      ),
                      this.assessment.status === "running"
                        ? _c("send-terms-of-service", {
                            attrs: {
                              assessment_id: _vm.assessment.assessment_id,
                              terms_of_use_accepted:
                                _vm.assessment.terms_of_use_accepted
                            }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout md-gutter md-alignment-center"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-50 md-small-size-100 mx-auto"
                            },
                            [
                              this.assessment.status
                                ? _c("group-table", {
                                    ref: "configGroupTable",
                                    attrs: {
                                      sme_version: _vm.sme_version,
                                      assessment_id: this.assessment
                                        .assessment_id,
                                      status: this.assessment.status,
                                      areTermsAccepted: this.assessment
                                        .terms_of_use_accepted,
                                      userRole: _vm.userRole
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.userRole === _vm.Roles.Admin
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-70 md-small-size-100 mx-auto"
                            },
                            [
                              _c(
                                "form",
                                {
                                  staticClass: "md-layout",
                                  attrs: { novalidate: "" },
                                  on: {
                                    submit: _vm.setOrganizationForAssessment
                                  }
                                },
                                [
                                  _c(
                                    "md-card",
                                    [
                                      _c(
                                        "md-card-content",
                                        [
                                          _c(
                                            "md-field",
                                            [
                                              _c("label", [
                                                _vm._v("Organization")
                                              ]),
                                              _c(
                                                "md-select",
                                                {
                                                  attrs: {
                                                    name:
                                                      "selectedOrganization",
                                                    id: "selectedOrganization"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedOrganization,
                                                    callback: function($$v) {
                                                      _vm.selectedOrganization = $$v
                                                    },
                                                    expression:
                                                      "selectedOrganization"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.organizations,
                                                    function(org) {
                                                      return [
                                                        _c(
                                                          "md-option",
                                                          {
                                                            key:
                                                              org.country_name,
                                                            attrs: {
                                                              value:
                                                                org.nationalHost
                                                                  .id
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                org.nationalHost
                                                                  .name
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          org.certifiedPartners,
                                                          function(cps) {
                                                            return [
                                                              _c(
                                                                "md-option",
                                                                {
                                                                  key: cps.name,
                                                                  attrs: {
                                                                    value:
                                                                      cps.id
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      cps.name
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                type: "submit"
                                              }
                                            },
                                            [_vm._v("Save")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "main-button-bottom" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-size-70 md-small-size-100 mx-auto"
                          },
                          [
                            this.assessment.status === "created"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-25 md-small-size-100 mx-auto"
                                  },
                                  [
                                    _vm.userRole === _vm.Roles.Admin
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              large: "",
                                              color: "success",
                                              loading: _vm.showActionLoader,
                                              disabled: _vm.showActionLoader
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.changeAssessmentState(
                                                  "populateDB"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Populate the database")]
                                        )
                                      : _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              large: "",
                                              color: "success",
                                              loading: _vm.showActionLoader,
                                              disabled: _vm.showActionLoader
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.changeAssessmentState(
                                                  "running"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Start Assessment")]
                                        )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            this.assessment.status === "running"
                              ? _c(
                                  "div",
                                  [
                                    _c("h3", [
                                      _vm._v("Done filling out the surveys?")
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " Once you have completed the assessment - this means all you colleagues have successully answered the survey you sent them - use the button below to close the data collection phase. This will close all survey access links and create your final AI maturity report. "
                                      )
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          large: "",
                                          color: "success",
                                          loading: _vm.showActionLoader,
                                          disabled: _vm.showActionLoader
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeAssessmentState(
                                              "completed"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Close Assessment")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ]),
                      this.assessment.status === "completed"
                        ? _c(
                            "div",
                            { staticClass: "md-layout mx-auto text-justify" },
                            [
                              _c("h3", [_vm._v("Assessment completed")]),
                              _c("p", [
                                _vm._v(
                                  " This assessment has been completed. Please return to the main screen to access the assessment results. "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }