var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "md-layout md-gutter md-alignment-center" },
        [
          _c(
            "v-btn",
            {
              staticClass:
                "md-layout-item md-size-30 md-small-size-100 mx-auto md-alignment-center",
              attrs: { color: "success", disabled: _vm.terms_of_use_accepted },
              on: { click: _vm.sendEmailWithTermsOfUse }
            },
            [_vm._v("Send Terms of Use")]
          )
        ],
        1
      ),
      _c("popup-modal", { ref: "popUp" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "form",
            {
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.sendMailToAddresses.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "md-layout-item md-size-100" }, [
                _c("h2", [
                  _vm._v(
                    "Insert email addresses to send them the terms of use:"
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-100" },
                [
                  _c(
                    "md-field",
                    {
                      class:
                        (!_vm.$v.newEmailAddress.required ||
                          !_vm.$v.newEmailAddress.email) &&
                        _vm.submitStatus === "ERROR"
                          ? "md-invalid"
                          : ""
                    },
                    [
                      _c("label", [_vm._v("Email address:")]),
                      _c("md-input", {
                        model: {
                          value: _vm.newEmailAddress,
                          callback: function($$v) {
                            _vm.newEmailAddress = $$v
                          },
                          expression: "newEmailAddress"
                        }
                      }),
                      !_vm.$v.newEmailAddress.required &&
                      _vm.submitStatus === "ERROR"
                        ? _c("span", { staticClass: "md-error" }, [
                            _vm._v("E-mail address is a required field")
                          ])
                        : _vm._e(),
                      !_vm.$v.newEmailAddress.email &&
                      _vm.submitStatus === "ERROR"
                        ? _c("span", { staticClass: "md-error" }, [
                            _vm._v("Please insert valid email address")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-layout md-size-100 md-alignment-center-space-between"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "md-layout-item md-size-30",
                      attrs: { color: "warning" },
                      on: { click: _vm._cancel }
                    },
                    [_vm._v("Dismiss")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "md-layout-item md-size-30",
                      attrs: { color: "success", type: "submit" }
                    },
                    [_vm._v("Send")]
                  )
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c("confirm-dialogue", {
        ref: "confirmDialogue",
        staticStyle: { "z-index": "100", position: "fixed" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }