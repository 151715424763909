<template>
  <div :class="wrapperClass">
    <img class="user-guide-image" :src="imageSrc" :style="{ 'width': width }" />
  </div>
</template>

<script>

export default {
  name: 'ImageContainer',
  props: {
    src: {
      type: String,
    },
    position: {
      type: String,
      default: 'center',
    },
    width: {
      type: String,
      default: 'auto'
    }
  },
  computed: {
    wrapperClass() {
      if (this.position === 'left') {
        return 'image-wrapper left';
      }
      if (this.position === 'right') {
        return 'image-wrapper right';
      }
      return 'image-wrapper';
    },
    imageSrc() {
      return require(`@/assets/img/${this.src}`);
    }
  },
};
</script>

<style lang="scss">
.user-guide-image {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  object-fit: contain;
   max-width: 100%;
   max-height: 100%;
   width: auto;
   height: fit-content;
}

.image-wrapper {
  max-width: 100%;
  height: fit-content;
  display: flex;
  padding: 15px;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
}
</style>
