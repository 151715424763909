var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "main main-raised" },
        [
          _c("loading-indicator", {
            attrs: { "is-loading": _vm.user.loading }
          }),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "v-responsive",
                { staticClass: "md-alignment-centered" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_vm._v("← Back")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-50 md-small-size-100 mx-auto"
                    },
                    [
                      _c("div", [
                        _c("h3", { staticClass: "text-primary" }, [
                          _vm._v(
                            _vm._s(_vm.user.role) +
                              " / " +
                              _vm._s(_vm.user.name)
                          )
                        ]),
                        _c(
                          "form",
                          {
                            staticClass: "md-layout",
                            attrs: { novalidate: "" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.updateDetailsForOtherUser.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "md-card",
                              [
                                _c(
                                  "md-card-content",
                                  [
                                    _c(
                                      "md-field",
                                      [
                                        _c("label", [_vm._v("Name:")]),
                                        _c("md-input", {
                                          model: {
                                            value: _vm.username,
                                            callback: function($$v) {
                                              _vm.username = $$v
                                            },
                                            expression: "username"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-field",
                                      [
                                        _c("label", [
                                          _vm._v("E-mail address:")
                                        ]),
                                        _c("md-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.user.email,
                                            callback: function($$v) {
                                              _vm.$set(_vm.user, "email", $$v)
                                            },
                                            expression: "user.email"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "md-field",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "selectedRole" } },
                                          [_vm._v("Role")]
                                        ),
                                        _c(
                                          "md-select",
                                          {
                                            attrs: {
                                              name: "selectedRole",
                                              id: "selectedRole"
                                            },
                                            model: {
                                              value: _vm.selectedRole,
                                              callback: function($$v) {
                                                _vm.selectedRole = $$v
                                              },
                                              expression: "selectedRole"
                                            }
                                          },
                                          [
                                            _c(
                                              "md-option",
                                              {
                                                attrs: {
                                                  value: "Account Master"
                                                }
                                              },
                                              [_vm._v("Account Master")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "md-card-actions",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          type: "submit"
                                        }
                                      },
                                      [_vm._v("Update")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }