var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { attrs: { novalidate: "" } },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-content",
            _vm._l(_vm.form.b_questions, function(item) {
              return _c("div", { key: item.question_id, staticClass: "row" }, [
                _c("div", { staticClass: "question" }, [
                  _vm._v(_vm._s(item.question_text))
                ]),
                _c(
                  "div",
                  { staticClass: "answer" },
                  [
                    _c(
                      "md-field",
                      [
                        _c(
                          "md-select",
                          {
                            attrs: {
                              "md-dense": "",
                              disabled:
                                _vm.sending ||
                                _vm.readonly ||
                                _vm.disabledIfGermanyNotSelected(
                                  item.question_id
                                )
                            },
                            model: {
                              value: item.answer_text,
                              callback: function($$v) {
                                _vm.$set(item, "answer_text", $$v)
                              },
                              expression: "item.answer_text"
                            }
                          },
                          [
                            _vm._l(item.options, function(option) {
                              return [
                                _c(
                                  "md-option",
                                  { key: option, attrs: { value: option } },
                                  [_vm._v(" " + _vm._s(option) + " ")]
                                )
                              ]
                            })
                          ],
                          2
                        ),
                        _vm.formHasErrors &&
                        item.has_error &&
                        item.question_id == 2
                          ? _c("span", { staticClass: "md-error" }, [
                              _vm._v(
                                "Organization’s yearly revenue is a required field."
                              )
                            ])
                          : _vm._e(),
                        _vm.formHasErrors &&
                        item.has_error &&
                        item.question_id == 3
                          ? _c("span", { staticClass: "md-error" }, [
                              _vm._v("Industry is a required field.")
                            ])
                          : _vm._e(),
                        _vm.formHasErrors &&
                        item.has_error &&
                        item.question_id == 4
                          ? _c("span", { staticClass: "md-error" }, [
                              _vm._v("Country headquarter is a required field.")
                            ])
                          : _vm._e(),
                        _vm.formHasErrors &&
                        item.has_error &&
                        item.question_id == 5
                          ? _c("span", { staticClass: "md-error" }, [
                              _vm._v(
                                "Answer doesn't match with the answer on the previous question"
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }),
            0
          ),
          _c(
            "md-card-actions",
            [
              !_vm.readonly
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: _vm.sending },
                      on: { click: _vm.saveAnswers }
                    },
                    [_vm._v(" Save Answers ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.sending
        ? _c("md-progress-bar", { attrs: { "md-mode": "indeterminate" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }