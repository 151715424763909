import axios from 'axios';
import { createLogger, format, transports } from 'winston';

const backend = process.env.VUE_APP_BACKEND_HOST;
export const http = axios.create({
  baseURL: backend,
});

function newLogger() {
  const loggingTargets = new transports.Console({
    format: format.combine(format.colorize(), format.simple()),
  });

  return createLogger({
    level: process.env.LOG_LEVEL || 'info',
    transports: loggingTargets,
    exitOnError: false,
  });
}

export const logger = newLogger();
