import { render, staticRenderFns } from "./BarChartParticipation.vue?vue&type=template&id=57c40876&"
import script from "./BarChartParticipation.vue?vue&type=script&lang=js&"
export * from "./BarChartParticipation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/iaai/products/maturity-assessment-tool/mat-website/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57c40876')) {
      api.createRecord('57c40876', component.options)
    } else {
      api.reload('57c40876', component.options)
    }
    module.hot.accept("./BarChartParticipation.vue?vue&type=template&id=57c40876&", function () {
      api.rerender('57c40876', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BarChartParticipation.vue"
export default component.exports