<template>
<div class="wrapper">
  <Header />
  <div class="main main-raised">
    <loading-indicator :is-loading="organization.loading" />
    <div class="container">
      <v-responsive class="md-alignment-centered">
        <v-btn color="primary" plain @click="$router.go(-1)">← Back</v-btn>
        <div class="md-layout-item md-size-50 md-small-size-100 mx-auto" v-if="!organization.loading">
          <h3 class="text-primary">{{ organizationName }}</h3>
          <br />
          <form novalidate class="md-layout" @submit="saveNewOrganization">
            <md-card>
              <md-card-content>
                <md-field>
                  <label>Organization name:</label>
                  <md-input v-model="organizationName" disabled></md-input>
                </md-field>
                <md-field class="custom-no-border">
                  <v-select
                    :items="countries"
                    v-model="organizationCountry"
                    name="country"
                    item-text="name"
                    item-value="code"
                    label="Country"
                    persistent-hint
                    persistent-placeholder
                    disabled
                  ></v-select>
                </md-field>
                <md-field>
                  <label>Contact person:</label>
                  <md-input v-model="organizationContactPerson"></md-input>
                </md-field>
                <md-field>
                  <label>Affiliation:</label>
                  <md-input v-model="organizationType" disabled></md-input>
                </md-field>
                <br />
              </md-card-content>
              <md-card-actions>
                <v-btn color="primary" type="submit" :disabled="disabledSaveBtn">Save</v-btn>
              </md-card-actions>
            </md-card>
          </form>
          <div class="md-layout" v-if="masterAccounts.length>0">
            <div class="md-layout-item md-size-100">
              <h4 class="boldText">Master accounts:</h4>
            </div>
          </div>
          <div class="md-layout" v-for="masterAccount in masterAccounts" v-bind:key="masterAccount.email">
            <div class="md-layout-item md-small-size-45 md-large-size-60 md-xlarge-size-25">
              <strong> {{ masterAccount.name }} </strong>
              {{ masterAccount.email }}
            </div>
            <div class="md-layout-item md-small-size-15 md-large-size-20 md-xlarge-size-10" @click="updateDetailsForUserFromOrganization(masterAccount.id, 'name')">
              <a class="stretched-link">[change]</a>
            </div>
            <div class="md-layout-item md-small-size-15 md-large-size-20 md-xlarge-size-10" @click="deleteUser(masterAccount.id, masterAccount.email)">
              <a class="stretched-link">[delete]</a>
            </div>
          </div>
          <br />
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <v-btn color="primary" @click="goToPage('userAccountCreate', { account_type: 'account-master', organization_id: organizationId, userRole: user.role  })">Add master account</v-btn>
            </div>
          </div>
          <br />
          <div class="md-layout" v-if="userAccounts.length>0">
            <div class="md-layout-item md-size-100">
              <h4 class="boldText">User accounts:</h4>
            </div>
          </div>
          <div class="md-layout" v-for="userAccount in userAccounts" v-bind:key="userAccount.email">
            <div class="md-layout-item md-small-size-45 md-large-size-60 md-xlarge-size-25">
              <strong> {{ userAccount.name }} </strong>
              {{ userAccount.email }}
            </div>
            <div class="md-layout-item md-small-size-15 md-large-size-20 md-xlarge-size-10" @click="goToPage('UserChangeAccountType', { user_id: userAccount.id })">
              <a class="stretched-link">[change]</a>
            </div>
            <div class="md-layout-item md-small-size-15 md-large-size-20 md-xlarge-size-10" @click="deleteUser(userAccount.id, userAccount.email)">
              <a class="stretched-link">[delete]</a>
            </div>
          </div>
          
          <br />
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <v-btn color="primary" @click="goToPage('userAccountCreate', { account_type: 'account-user', organization_id: organizationId, userRole: user.role })">Add user account</v-btn>
            </div>
          </div>
        </div>
      </v-responsive>
    </div>
    <popup-modal ref="popUp">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <h2>Change email address?</h2>
        </div>
        <div class="md-layout-item md-size-100">
          <md-field>
            <label>Insert new email address here:</label>
            <md-input v-model="newEmailAddress"></md-input>
          </md-field>
        </div>
        <div v-if="errorMsg" class="md-layout-item md-size-100 red-text">
          {{ errorMsg }}
        </div>
        <div class="md-layout-item md-layout md-size-100 md-alignment-center-space-between">
          <v-btn class="md-layout-item md-size-30" color="warning" @click="_cancel('popUp')">Cancel</v-btn>
          <v-btn class="md-layout-item md-size-30" color="success" @click="_confirm(false)">Confirm</v-btn>
        </div>
      </div>
    </popup-modal>
    <popup-modal ref="deletePopUp">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <h2>Are you sure you want to delete this account? ({{ deletePopUpTitle }})</h2>
        </div>
        <div class="md-layout-item md-layout md-size-100 md-alignment-center-space-between">
          <v-btn class="md-layout-item md-size-30" color="warning" @click="_cancel('deletePopUp')">Cancel</v-btn>
          <v-btn class="md-layout-item md-size-30" color="success" @click="_confirm(true)">Confirm</v-btn>
        </div>
      </div>
    </popup-modal>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
  <snackbar />
</div>
</template>

<script>
import backend_connector from '../../mixins/backend_connector';
import { LoadingIndicator, PopupModal, Header, Snackbar, ConfirmDialogue } from '@/components';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'organizationView',
  mixins: [backend_connector],
  components: {
    Header,
    LoadingIndicator,
    PopupModal,
    Snackbar,
    ConfirmDialogue
  },
  bodyClass: 'index-page',
  data: () => ({
    organizationId: null,
    organizationNewName: null,
    organizationNewCountry: null,
    organizationNewContactPerson: null,
    organizationNewType: null,
    newEmailAddress: null,
    errorMsg: null,
    disabledSaveBtn: true,
    deletePopUpTitle: null
  }),
  computed: {
    ...mapState({
      countries: state => state.organizations.countries,
      organization: state => state.organizations.organization,
      user: state => state.user,
    }),
    masterAccounts() {
      return this.organization.loading===false? this.$store.getters['organizations/getMasterAccounts'] : [];
    },
    userAccounts() {
      return this.organization.loading===false? this.$store.getters['organizations/getUserAccounts'] : [];
    },
    organizationName: {
      get() {
        return this.organization.loading===false? this.$store.getters['organizations/getOrganizationName'] : null;
      },
      set(value) {
        this.disabledSaveBtn = false;
        this.organizationNewName = value;
        return value;
      }
    },
    organizationCountry: {
      get() {
        return this.organization.loading===false? this.$store.getters['organizations/getOrganizationCountry'] : null;
      },
      set(value) {
        this.disabledSaveBtn = false;
        this.organizationNewCountry = value;
        return value;
      }
    },
    organizationContactPerson: {
      get() {
        return this.organization.loading===false? this.$store.getters['organizations/getOrganizationContactPerson'] : null;
      },
      set(value) {
        this.disabledSaveBtn = false;
        this.organizationNewContactPerson = value;
        return value;
      }
    },
    organizationType: {
      get() {
        return this.organization.loading===false? this.$store.getters['organizations/getOrganizationType'] : null;
      },
      set(value) {
        this.disabledSaveBtn = false;
        this.organizationNewType = value;
        return value;
      }
    },
  },
  mounted() {
    this.organizationId = this.$route.params.id;
    this.getAllCountries();
    this.getOrganizationDetails(this.organizationId);
  },
  watch: {
    newEmailAddress(value) {
      this.newEmailAddress = value;
    },
  },
  methods: {
    ...mapActions({
      getOrganizationDetails: 'organizations/getOrganizationDetails',
      getAllCountries: 'organizations/getListOfCountries',
      updateOrganization: 'organizations/updateOrganization',
      changeUserMailAction: 'organizations/changeUserMail',
      deleteUserAction: 'organizations/deleteUser',
      showSnackBarAction: 'assessments/showSnackBar',
      updateUserDetailsAction: 'user/updateUserDetails',
      updateDetailsForUserFromOrganizationAction: 'organizations/updateDetailsForOtherUser',
    }),
    async updateDetailsForUserFromOrganization (userId, fieldName) {
      const newUserName = await this.$refs.confirmDialogue.show({
        title: 'Insert new name here:',
        okButton: 'Update',
        cancelButton: 'Cancel',
        showUpdateDataPart: true,
      });
        //TODO: If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (newUserName) {
        this.updateDetailsForUserFromOrganizationAction({ 
          userId, 
          payload: { item_name: fieldName, item_value: newUserName }, 
          cb: () => this.showSnackBarAction('Update successfull!')
        });
      }
    },
    async updateUserDetails(fieldName) {
      const newUserName = await this.$refs.confirmDialogue.show({
        title: 'Insert new name here:',
        okButton: 'Update',
        cancelButton: 'Cancel',
        showUpdateDataPart: true,
      });
      //TODO: If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (newUserName) {
        this.updateUserDetailsAction({ fieldName, value: newUserName});
      }
    },
    goToPage(pageName, params) {
      this.$router.push({ name: pageName, params: params });
    },
    async deleteUser(id, email) {
      this.deletePopUpTitle = email;
      const ok = await this.showDialog('deletePopUp');
      if(!ok) {
        return;
      }
      this.deleteUserAction({ id, cb: (msg) => this.showSnackBarAction(msg) });
    },
    async changeMail(oldMail) {
      const newMail = await this.showDialog('popUp');
      if(!newMail) {
        return;
      }
      this.changeUserMailAction({ oldMail, newMail });
    },
    showDialog(popUpName){
      return this.show(popUpName).then();
    },
    async saveNewOrganization(e){
      e.preventDefault();
      let payload = {
        item_name: 'email',
        item_value: this.organizationNewContactPerson
      };
      this.updateOrganization({ 
        organization_id: this.$route.params.id, 
        payload: payload, 
        cb: () => this.showSnackBarAction('Update successfull!') 
      });
    },
    show(popUpName) {
      this.$refs[popUpName].open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    validateEmail(value){
      let isValid = true;
      //eslint-disable-next-line
      if (!value || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.replace(/\s/g,''))) {
        this.errorMsg = 'The email address(es) that you provided is (are) invalid';
        isValid = false;
        throw new Error('The email address(es) that you provided is (are) invalid');
      }
      return isValid;
    },
    _confirm(shouldDeleteAccount) {
      if(!shouldDeleteAccount && this.validateEmail(this.newEmailAddress)) {
        this.resolvePromise(this.newEmailAddress);
        this.$refs.popUp.close();
        this.newEmailAddress = null;
        this.errorMsg = null;
      } 
      if (shouldDeleteAccount) {
        this.resolvePromise(true);
        this.$refs.deletePopUp.close();
      }
    },
    _cancel(popUpName) {
      this.$refs[popUpName].close();
      this.newEmailAddress = null;
      this.errorMsg = null;
      this.rejectPromise(new Error('User cancelled the dialogue'));
    },
  },
};
</script>

<style lang="scss">
.md-field.md-theme-default.custom-no-border:after{
  content: none;
}
.wrapper p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #222;
  text-align: left;
}
.md-theme-default a:not(.md-button).stretched-link {
  color: #009688;
  font-weight: 500;
  padding: 5px;
  &:hover {
    font-weight: 600;
  }
}
.boldText {
  font-weight: 600;
}
</style>
