var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading-indicator", {
        attrs: { "is-loading": _vm.participantsLoading }
      }),
      _c(
        "div",
        { staticClass: "assessment-table" },
        [
          _c("div", { staticClass: "md-layout md-alignment-bottom-right" }, [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-25" },
              [
                _c(
                  "v-card-text",
                  [
                    _c("v-text-field", {
                      attrs: {
                        color: "success",
                        clearable: "",
                        loading: _vm.participantsLoading,
                        density: "compact",
                        variant: "solo",
                        label: "Search...",
                        "append-icon": "mdi-magnify",
                        "single-line": "",
                        "hide-details": ""
                      },
                      on: {
                        "click:append": _vm.getSearchResults,
                        "click:clear": _vm.clearSearchByValue,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getSearchResults.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.options.searchByValue,
                        callback: function($$v) {
                          _vm.$set(_vm.options, "searchByValue", $$v)
                        },
                        expression: "options.searchByValue"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "md-table",
            {
              staticClass: "inline",
              staticStyle: { "background-color": "rgba(255, 255, 255, 0.4)" },
              attrs: { "md-card": "" },
              scopedSlots: _vm._u([
                {
                  key: "md-table-row",
                  fn: function(ref) {
                    var item = ref.item
                    return _c(
                      "md-table-row",
                      {
                        class: _vm.getClass(item),
                        attrs: { "md-fixed-header": "" }
                      },
                      [
                        _c(
                          "md-table-cell",
                          {
                            attrs: {
                              "md-label": "Created on/by",
                              "md-sort-by": "time_created"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.time_created.substring(
                                    0,
                                    item.time_created.indexOf("T")
                                  )
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(" " + _vm._s(item.username) + " ")
                          ]
                        ),
                        _c(
                          "md-table-cell",
                          {
                            attrs: { "md-label": "Name", "md-sort-by": "name" }
                          },
                          [_c("b", [_vm._v(_vm._s(item.name))])]
                        ),
                        _c(
                          "md-table-cell",
                          {
                            staticClass: "status-col",
                            attrs: {
                              "md-label": "Status",
                              "md-sort-by": "status"
                            }
                          },
                          [
                            _c("assessment-status", {
                              attrs: { status: item.status }
                            })
                          ],
                          1
                        ),
                        _c(
                          "md-table-cell",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { "md-label": "Details" }
                          },
                          [
                            item.status === "created"
                              ? _c("div", [
                                  _c("b", [_vm._v("Not started yet.")]),
                                  _vm._v(
                                    " Go to configuration to start assessment. "
                                  )
                                ])
                              : _vm._e(),
                            item.status === "running"
                              ? _c("div", [
                                  _c("b", [_vm._v("Running for: ")]),
                                  _vm._v(
                                    _vm._s(
                                      _vm.calculateDuration(item.date_started)
                                    ) + " days "
                                  )
                                ])
                              : _vm._e(),
                            item.status === "completed"
                              ? _c("div", [
                                  _c("b", [_vm._v("Closed on ")]),
                                  _vm._v(_vm._s(item.date_closed) + " ")
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "md-table-cell",
                          {
                            staticClass: "participation-col",
                            staticStyle: { width: "50px" },
                            attrs: { "md-label": "# Participants" }
                          },
                          [
                            item.status === "created"
                              ? _c("div", [_vm._v("N/A")])
                              : _c("div", [
                                  _vm._v(_vm._s(item.totalParticipants))
                                ])
                          ]
                        ),
                        _c(
                          "md-table-cell",
                          {
                            staticClass: "participants-per-group-col",
                            attrs: { "md-label": "# Completed/NonCompleted" }
                          },
                          [
                            item.status === "created"
                              ? _c("div", [_vm._v("N/A")])
                              : _vm._l(item.participantsPerGroup, function(
                                  perGroup
                                ) {
                                  return _c(
                                    "div",
                                    { key: perGroup.name },
                                    [
                                      perGroup.name !== "completed"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "bold-group-names"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(perGroup.name) +
                                                    ": "
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  perGroup.value.completed
                                                ) +
                                                "/ " +
                                                _vm._s(
                                                  perGroup.value.total -
                                                    perGroup.value.completed
                                                ) +
                                                " "
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                })
                          ],
                          2
                        ),
                        item.status === "completed"
                          ? _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Actions" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "action-button",
                                    attrs: {
                                      small: "",
                                      color: "success",
                                      target: "_blank"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewElem(item.assessment_id)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-magnify")]),
                                    _vm._v(" Results ")
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        item.status === "created" || item.status === "running"
                          ? _c(
                              "md-table-cell",
                              {
                                staticClass: "action-btns-container",
                                attrs: { "md-label": "Actions" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "action-button",
                                    attrs: {
                                      small: "",
                                      color: "warning",
                                      target: "_blank"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.configElem(
                                          item.assessment_id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-cog")
                                    ]),
                                    _vm._v(" Configure ")
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "action-button",
                                    staticStyle: { "margin-left": "7px" },
                                    attrs: {
                                      small: "",
                                      color: "success",
                                      target: "_blank"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.sendMailWithSurveyLinks(
                                          item.assessment_id,
                                          item.participantsPerGroup
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-bell-ring")
                                    ]),
                                    _vm._v("  Send reminder ")
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("md-table-cell", [
                          _c(
                            "div",
                            { staticClass: "md-lg delete_cell" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: { icon: "", color: "red", dark: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirmDeletion(
                                        item.assessment_id,
                                        item.name,
                                        item.status
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("mdi-delete")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  }
                }
              ]),
              model: {
                value: _vm.assessments.list,
                callback: function($$v) {
                  _vm.$set(_vm.assessments, "list", $$v)
                },
                expression: "assessments.list"
              }
            },
            [
              _vm.assessments.list.length === 0
                ? _c(
                    "md-table-row",
                    {
                      staticClass: "md-primary",
                      attrs: { slot: "md-table-row", "md-fixed-header": "" },
                      slot: "md-table-row"
                    },
                    [
                      _c("label", { staticClass: "no-items-found" }, [
                        _vm._v("No assessments found")
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              { staticClass: "status-legend md-layout-item md-size-100" },
              [
                _vm._v(" Status legend: "),
                _vm._l(["created", "running", "completed"], function(status) {
                  return _c(
                    "span",
                    { key: status },
                    [
                      _c("assessment-status", { attrs: { status: status } }),
                      _vm._v(" " + _vm._s(status) + " ")
                    ],
                    1
                  )
                })
              ],
              2
            )
          ]),
          _c("div", { staticClass: "md-layout" }, [
            _c("div", {
              staticClass: "md-layout-item md-xsmall-hide md-size-35"
            }),
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-xsmall-size-100 md-size-30 mt-10"
              },
              [
                _c("v-pagination", {
                  attrs: { length: _vm.numOfPages, color: "#5f625f" },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            ),
            _c("div", {
              staticClass: "md-layout-item md-xsmall-hide md-size-20"
            }),
            _c(
              "div",
              { staticClass: "md-layout-item md-xsmall-size-100 md-size-15" },
              [
                _c("div", { staticClass: "md-layout md-alignment-top-right" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-xsmall-size-25 md-large-size-100"
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: [
                            { text: 5, value: 5 },
                            { text: 10, value: 10 },
                            { text: 20, value: 20 },
                            { text: 30, value: 30 },
                            { text: "All", value: this.totalItems }
                          ],
                          name: "assessmentsPerPage",
                          label: "Rows per page"
                        },
                        model: {
                          value: _vm.perPage,
                          callback: function($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c("confirm-dialogue", { ref: "confirmDialogue" }),
          _c("snackbar")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }