<template>
  <div class="wrapper">
    <Header />
    <div class="main main-raised">
      <div class="container">
        <v-responsive class="md-alignment-centered">
          <div class="text-center">
            <Heading variant="h2">{{ title }}</Heading>
          </div>
          <div class="md-layout text-justify">
            <div class="panel-container">
              <v-expansion-panels focusable multiple>
                <slot></slot>
              </v-expansion-panels>
            </div>
          </div>
        </v-responsive>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header';
import Heading from '@/components/Heading';

export default {
  name: 'ExpansionPanels',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  components: {
    Header,
    Heading,
  },
};
</script>
<style scoped>
.panel-container {
  margin: 0 auto;
  width: 800px;
  padding: 20px;
}
</style>
