var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: 3000 },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { color: "pink", text: "" },
                    on: { click: _vm.hideSnackBarAction }
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Close ")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.snackbar,
        callback: function($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar"
      }
    },
    [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }