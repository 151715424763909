var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ClusterDetails" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("Header"),
        _c(
          "div",
          { staticClass: "main main-raised" },
          [
            _c("loading-indicator", { attrs: { "is-loading": _vm.isLoading } }),
            _c("div", { staticClass: "section challenge-content" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "md-layout" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-80 mx-auto align-center"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            plain: "",
                            to: "/landing/" + _vm.assessment_id
                          }
                        },
                        [_vm._v("← Results")]
                      ),
                      _vm.report_data["cluster_name"] !== "Ambition"
                        ? _c("br")
                        : _vm._e(),
                      _vm.report_data["cluster_name"] !== "Ambition"
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", plain: "" },
                              on: {
                                click: function($event) {
                                  return _vm.goToDimension(true)
                                }
                              }
                            },
                            [_vm._v("← Previous dimension")]
                          )
                        : _vm._e(),
                      _vm.report_data["cluster_name"] !== "Steering"
                        ? _c(
                            "v-btn",
                            {
                              staticStyle: { float: "right" },
                              attrs: { color: "primary", plain: "" },
                              on: {
                                click: function($event) {
                                  return _vm.goToDimension(false)
                                }
                              }
                            },
                            [_vm._v("Next dimension →")]
                          )
                        : _vm._e(),
                      _vm.report_data && "cluster_name" in _vm.report_data
                        ? _c("div", { staticClass: "cluster-name" }, [
                            _c("h1", [
                              _vm._v(_vm._s(_vm.report_data["cluster_name"]))
                            ])
                          ])
                        : _vm._e(),
                      _vm.report_data &&
                      "cluster_description" in _vm.report_data
                        ? _c("div", { staticClass: "cluster-heading" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.report_data["cluster_description"]) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "cluster-table" },
                        [
                          _c(
                            "md-table",
                            {
                              attrs: {
                                "md-sort": "position",
                                "md-sort-order": "asc",
                                "md-card": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "md-table-row",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return _c(
                                      "md-table-row",
                                      { staticClass: "row-level" },
                                      [
                                        _c(
                                          "md-table-cell",
                                          {
                                            staticClass: "col-level",
                                            attrs: {
                                              "md-label": "Required for level"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.level) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "md-table-cell",
                                          {
                                            staticClass: "col-description",
                                            attrs: { "md-label": "Description" }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "md-table-cell",
                                          {
                                            staticClass: "col-status",
                                            attrs: { "md-label": "Status" }
                                          },
                                          [
                                            _c("statusChart", {
                                              attrs: {
                                                item: item,
                                                color: _vm.color,
                                                groupNames: _vm.groupNames
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.searched,
                                callback: function($$v) {
                                  _vm.searched = $$v
                                },
                                expression: "searched"
                              }
                            },
                            [
                              _c(
                                "md-table-empty-state",
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      color: "primary lighten-2",
                                      indeterminate: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("table-legend", {
                        attrs: { groupNames: _vm.groupNames, color: _vm.color }
                      }),
                      _vm.report_data &&
                      "cluster_description_for_level" in _vm.report_data
                        ? _c(
                            "div",
                            { staticClass: "cluster-level-description" },
                            [
                              _c("h2", [
                                _vm._v(
                                  " Your current Level of Maturity is Level " +
                                    _vm._s(_vm.report_data["maturity_level"]) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.report_data[
                                      "cluster_description_for_level"
                                    ]
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.report_data &&
                      "question_level_recommendations" in _vm.report_data
                        ? _c(
                            "div",
                            { staticClass: "question-level-recommendations" },
                            [
                              _c("h2", [
                                _vm._v(
                                  "Based on your responses this is what we recommend:"
                                )
                              ]),
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.report_data[
                                    "question_level_recommendations"
                                  ],
                                  function(question) {
                                    return _c(
                                      "li",
                                      {
                                        key: question.recommendation,
                                        staticClass: "recommendation-listitem"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(question.recommendation) +
                                            " "
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.report_data && "resources" in _vm.report_data
                        ? _c("div", { staticClass: "resources" }, [
                            _c("h2", [
                              _vm._v(
                                "Further readings and resources that might help you:"
                              )
                            ]),
                            _c(
                              "ul",
                              _vm._l(_vm.report_data["resources"], function(
                                resource
                              ) {
                                return _c("li", { key: resource.resource_id }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "resource-link",
                                      attrs: {
                                        href: resource.resource_link,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(resource.resource_text) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm.report_data && "services" in _vm.report_data
                        ? _c(
                            "div",
                            { staticClass: "services" },
                            [
                              _c("h2", [
                                _vm._v(
                                  " Services that can help you to accelerate to the next maturity level in " +
                                    _vm._s(_vm.report_data["cluster_name"]) +
                                    ": "
                                )
                              ]),
                              _vm._l(_vm.report_data["services"], function(
                                service
                              ) {
                                return _c("div", { key: service.service_id }, [
                                  _c("h3", [
                                    _vm._v(_vm._s(service.service_name))
                                  ]),
                                  _c("h4", [
                                    _vm._v(" by "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: service.organization_website,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(service.organization_name) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        service.service_description
                                      )
                                    }
                                  }),
                                  service.service_mail
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "mailto:" +
                                              service.service_mail +
                                              "?subject=Please%20get%20in%20touch%20with%20me%20regarding " +
                                              service.service_name +
                                              "&body=Add%20a%20message%20here%20or%20simply%20hit%20send.%20We%20will%20get%20back%20to%20you!",
                                            target: "_blank"
                                          }
                                        },
                                        [_c("v-btn", [_vm._v("Get in touch")])],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }