import getters from './getters';
import * as actions from './actions';
import mutations from './mutations';

const state = () => ({
  id: '',
  name: '',
  email: '',
  role: '',
  preferredOptimizationForGSlide: false,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};