// =========================================================
// * Vue Material Kit - v1.2.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-kit/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import MaterialKit from './plugins/material-kit';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import vuetify from './plugins/vuetify';
import { Auth0Plugin } from '@/auth';
import 'setimmediate';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import * as SentryBrowser from '@sentry/browser';

import store from './store';
import VueExcelXlsx from 'vue-excel-xlsx';
Vue.config.productionTip = false;

Vue.use(MaterialKit);
Vue.use(VueMaterial);
Vue.use(VueExcelXlsx);
const NavbarStore = {
  showNavbar: false,
};

Vue.mixin({
  data() {
    return {
      NavbarStore,
    };
  },
});

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  cacheLocation: 'localstorage',
});

if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: 'mat-frontend@' + process.env.PACKAGE_VERSION,
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://mat.aai.sh/', /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new SentryBrowser.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
/*
if (process.env.VUE_APP_SENTRY_ENABLED === 'true' && !navigator.doNotTrack) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    logErrors: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
*/

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
