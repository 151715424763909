<template>
  <div class="wrapper">
    <Header />
    <div class="main main-raised">
      <div class="container">
        <v-responsive class="md-alignment-centered">
            <div class="md-layout md-gutter md-alignment-center">
              <div class="md-layout-item md-size-60 md-small-size-100 mx-auto mt-12 mb-12">
                <p class="success-msg">You just accepted ToU for maturity assessment by appliedAI. Thank you. </p>
                <p class="success-msg">Please shortly inform your appliedAI main MAT contact via Mail in order to receive the links to perform the AI Maturity Assessment.</p>
              </div>
            </div>
        </v-responsive>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';

export default {
  name: 'termsOfUse',
  components: {
    Header,
  },
  bodyClass: 'index-page',
};
</script>
<style scoped>
.success-msg {
  color: #00747b;
  font-size: 20px;
  text-align: center;
  padding-top: 10px;
}
</style>
