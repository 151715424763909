<template>
  <div>
    <div class="md-layout md-gutter md-alignment-center">
      <v-btn color="success" :disabled="terms_of_use_accepted" @click="sendEmailWithTermsOfUse" class="md-layout-item md-size-30 md-small-size-100 mx-auto md-alignment-center">Send Terms of Use</v-btn>
    </div>
    <popup-modal ref="popUp">
      <div class="md-layout">
        <form novalidate @submit.prevent="sendMailToAddresses">
          <div class="md-layout-item md-size-100">
            <h2>Insert email addresses to send them the terms of use:</h2>
          </div>
          <div class="md-layout-item md-size-100">
            <md-field :class="(!$v.newEmailAddress.required || !$v.newEmailAddress.email) && submitStatus==='ERROR'? 'md-invalid' : ''">
              <label>Email address:</label>
              <md-input v-model="newEmailAddress"></md-input>
              <span class="md-error" v-if="!$v.newEmailAddress.required && submitStatus==='ERROR'">E-mail address is a required field</span>
              <span class="md-error" v-if="!$v.newEmailAddress.email && submitStatus==='ERROR'">Please insert valid email address</span>
            </md-field>
          </div>
          <div class="md-layout-item md-layout md-size-100 md-alignment-center-space-between">
            <v-btn class="md-layout-item md-size-30" color="warning" @click="_cancel">Dismiss</v-btn>
            <v-btn class="md-layout-item md-size-30" color="success" type="submit">Send</v-btn>
          </div>
        </form>
      </div>
    </popup-modal>
    <confirm-dialogue ref="confirmDialogue" style="z-index: 100; position: fixed"></confirm-dialogue>
  </div>
</template>

<script>
import { PopupModal, ConfirmDialogue } from '@/components';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  name: 'SendTermsOfService',
  mixins: [validationMixin],
  props: {
    assessment_id: {
      required: true,
      type: [String, Number],
    },
    terms_of_use_accepted: { 
      type: Boolean, 
      default: false,
    },
  },
  components: {
    PopupModal,
    ConfirmDialogue
  },
  data: () => ({
    newEmailAddress: null,
    submitStatus: null,
  }),
  validations: {
    newEmailAddress: {
      required,
      email
    },
  },
  methods: {
    ...mapActions({
      sendEmailWithTermsOfUseAction: 'assessments/sendEmailWithTermsOfUse',
    }),
    isFormValid(){
      this.$v.$touch();
      if(this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        return false;
      } else {
        this.submitStatus = 'PENDING';
        return true;
      }
    },
    sendMailToAddresses(){
      if (this.isFormValid()) {
        this.resolvePromise(this.newEmailAddress);
        this.$refs.popUp.close();
        this.newEmailAddress = null;
      }
    },
    _cancel() {
      this.$refs.popUp.close();
      this.newEmailAddress = null;
      this.rejectPromise(new Error('User cancelled the dialogue'));
    },
    showPopUp(popUpName){
      return this.show(popUpName).then();
    },
    show(popUpName) {
      this.$refs[popUpName].open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    showDialog(title, message, okText, cancelText, showEmailInputPart){
      return this.$refs.confirmDialogue
        .show({
          title,
          message,
          okButton: okText,
          cancelButton: cancelText,
          showEmailInputPart,
        })
        .then();
    },
    async sendEmailWithTermsOfUse() {
      const mailAddress = await this.showPopUp('popUp');
      if(!mailAddress) {
        return;
      }
      this.sendEmailWithTermsOfUseAction({
        assessment_id: this.assessment_id,
        emailAddress: mailAddress,
        redirectUrl: `${window.location.origin}/terms-of-use/${this.assessment_id}`
      });
    }
  },
};
</script>
