<template>
  <div class="wrapper">
    <Header />
    <div class="main main-raised" >
      <div class="container">
        <v-responsive class="md-alignment-centered">
          <div class="text-center" >
            <h2>Ooops</h2>
          </div>
          <div class="md-layout text-justify">
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <p>
                It seems that you don't have the right permissions to access this page
              </p>
               <v-btn color="primary" plain @click="$router.go(-1)">← Back </v-btn>
            </div>
          </div>
        </v-responsive>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';

export default {
  name: 'Invalid',
  components: {
    Header,
  },
};
</script>