import * as getters from './getters'; 
import * as actions from './actions';
import mutations from './mutations';

const state = () => ({
  currentPage: 1,
  perPage: 10,
  next: '',
  previous: '',
  count: 0,
  list: [],
  assessment: {
    areTermsOfUseAccepted: false,
  },
  groups: [],
  maturityData: [],
  participants: {},
  matGroups: [],
  assessmentContainsGroups: [],
  surveyParticipants: [],
  questionsAndRatings: {},
  autoReportData: {},
  snackbarText: null,
  showSnackbar: false,
  participantsLoading: false,
  maturityDataLoading: false,
  powerPointDownloadLoading: false,
  typesByRevenueSize: [],
  typesByIndustry: [],
  error: {},
  apiLoading: false,
  benchmarkFilters: {
    industries: [],
    sizes: [],
    all_countries: false
  },
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};