<template>
  <div v-bind:id="name"></div>
</template>

<script>
import * as d3 from 'd3/dist/d3.min';

export default {
  name: 'MaturityPieChart',
  props: {
    name: String,
    score: Number,
  },
  mounted() {
    const domItem = `#${this.name}`;
    const width = 160;
    const height = 60;

    // removes previously drawn data
    const svgRemove = d3.selectAll(domItem);
    svgRemove.selectAll('*').remove();

    // create svg
    const svg = d3
      .select(domItem)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewport', [0, 0, width, height])
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    // calculate donut chart arc
    const arcPadding = 4;
    const innerRadius = 14;
    const outerRadius = Math.min(width, height) / 2 - arcPadding;
    let arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    // calculate pie chart share out of 4
    let pie = d3.pie().sort(null);
    const arcs = pie([this.score, 4 - this.score]);
    const color = ['hsl(183,100%,24%)', 'hsl(183,25%,75%)'];

    // add pie chart to svg on DOM
    svg
      .append('g')
      .selectAll('path')
      .data(arcs)
      .enter()
      .append('path')
      .attr('fill', (d, i) => color[i])
      .attr('d', arc);

    // Equal-spaced scale ticks
    // Rotation angles for tick marks in radians
    const ticks = [0, 1, 2, 3].map((i) => (i * Math.PI) / 2 - Math.PI / 2);

    // Draw ticks by converting polar angles to cartesian coords
    svg
      .append('g')
      .selectAll('line')
      .data(ticks)
      .enter()
      .append('line')
      .attr('stroke', 'hsl(183, 100%, 24%)')
      .attr('x1', (d) => Math.cos(d) * outerRadius)
      .attr('y1', (d) => Math.sin(d) * outerRadius)
      .attr('x2', (d) => Math.cos(d) * (outerRadius + arcPadding))
      .attr('y2', (d) => Math.sin(d) * (outerRadius + arcPadding));

    svg
      .append('text')
      .attr('fill', (d, i) => color[i])

      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .style('font-weight', 500)
      .text(Math.floor(this.score));
  },
};
</script>

<style scoped></style>
