var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-progress-linear", {
    style: { visibility: _vm.isLoading ? "visible" : "hidden" },
    attrs: { indeterminate: "", color: "primary" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }