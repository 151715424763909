<template>
  <div id="participantsChart"></div>
</template>
<script>
import * as d3 from 'd3/dist/d3.min';

export default {
  name: 'BarChartParticipation',
  props: {
    chartData: {
      type: Object,
      required: true
    },
  },
  mounted() {
    if(this.chartData && this.chartData.labels && this.chartData.labels.length>0)
      this.participantsChart(this.chartData);
  },
  methods: {
    participantsChart: function (data) {
      const domItem = '#participantsChart';
      const width = 500;
      const height = 150;
      const totalHeight = 230;

      // removes previously drawn data
      const svgRemove = d3.selectAll(domItem);
      svgRemove.selectAll('*').remove();
      // refactor data
      let _data = [];
      for (let i = 0; i < data.labels.length; i++) {
        _data.push({
          participationGroupNameForBottomTooltip: data.labels[i],
          participationGroup: data.labels[i].length>10? '(' + (i+1) +')' + data.labels[i].slice(0, 5) + '...' : '(' + (i+1) +') ' + data.labels[i],
          surveyStarted:
            data.total_participants[i] - data.completed_participants[i],
          surveyCompleted: data.completed_participants[i],
        });
      }

      // prepare data
      const groups = [];
      _data.forEach((group) => {
        groups.push(group.participationGroup);
      });
      let participantsPerGroup = [];
      _data.forEach((group) => {
        let participants = group.surveyStarted + group.surveyCompleted;
        participantsPerGroup.push(participants);
      });
      const maxParticipantsPerGroup = Math.max(...participantsPerGroup);
      const legendIds = ['surveyStarted', 'surveyCompleted'];
      const legend = ['Started Survey', 'Completed Survey'];

      // create svg
      const svg = d3
        .select(domItem)
        .append('svg')
        .attr('width', width)
        .attr('height', totalHeight)
        .append('g');

      // calculate xAxis scale
      let x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2]);

      // add xAxis to svg on DOM
      if (groups.length>6) {
        svg
          .append('g')
          .attr('transform', `translate(0, ${height - 15})`)
          .call(d3.axisBottom(x).tickSizeOuter(0)).selectAll('text')
          .attr('x', -50)
          .attr('transform', 'rotate(-70)')
          .style('text-anchor', 'start');
      } else {
        svg
          .append('g')
          .attr('transform', `translate(0, ${height - 15})`)
          .call(d3.axisBottom(x).tickSizeOuter(0));
      }
    
      // calculate yAxis scale
      let y = d3
        .scaleLinear()
        .domain([0, maxParticipantsPerGroup])
        .range([height - 15, 0]);
      // add yAxis to svg on DOM
      svg.append('g').call(d3.axisLeft(y));

      // define color scale
      const color = {
        'surveyStarted': '#FA8F0D',
        'surveyCompleted': '#00747b'
      };
      // stack bars
      let stackGen = d3.stack().keys(legendIds);
      let stackedData = stackGen(_data);
      // add bars to svg on DOM
      svg
        .append('g')
        .selectAll('g')
        .data(stackedData)
        .enter()
        .append('g')
        .attr('fill', (d) => color[d.key])
        .selectAll('rect')
        .data((d) => d)
        .enter()
        .append('rect')
        .attr('x', (d) => x(d.data.participationGroup))
        .attr('y', function (d) {
          return y(d[1]);
        })
        .attr('height', (d) => y(d[0]) - y(d[1]))
        .attr('width', x.bandwidth())
        .on('mouseover', function (ev, d) {
          d3.select(this).transition().duration(200).attr('opacity', 0.6);
          // show values in tooltip on mouseover
          d3.select('#tooltip').transition().duration(200).style('opacity', 1);
          d3.select('#tooltip').html(function () {
            console.log(ev, d);
            if (d.data === undefined) {
              return '<p></p>';
            }
            if (d.data.surveyStarted > 0) {
              return `<p>Group ${d.data.participationGroupNameForBottomTooltip}: ${d.data.surveyStarted} surveys started, ${d.data.surveyCompleted} surveys completed</p>`;
            } else {
              return `<p>Group ${d.data.participationGroupNameForBottomTooltip}: ${d.data.surveyCompleted} surveys completed</p>`;
            }
          });
        })
        .on('mouseout', function () {
          d3.select(this).transition().duration(200).attr('opacity', 1);
          d3.select('#tooltip').style('opacity', 0);
        });

      d3.selectAll('.domain').remove();
      d3.selectAll('.tick line').remove();

      svg
        .selectAll('mydots')
        .data(legendIds)
        .enter()
        .append('circle')
        .attr('cx', (d, i) => width * 0.33 + i * width * 0.22)
        .attr('cy', 200)

        .attr('r', 7)
        .style('fill', (d) => color[d]);

      svg
        .selectAll('mylabels')
        .data(legend)
        .enter()
        .append('text')
        .attr('x', (d, i) => width * 0.35 + i * width * 0.22)
        .attr('y', 200)

        .style('fill', 'black')
        .text((d) => d)
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle')
        .attr('font-size', '12');
    }}
};
</script>
