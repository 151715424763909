var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "main main-raised" },
        [
          _c("loading-indicator", { attrs: { "is-loading": _vm.isLoading } }),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("v-responsive", { staticClass: "md-alignment-centered" }, [
                _c("div", { staticClass: "md-layout text-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-66 md-small-size-100 mx-auto text-left"
                    },
                    [
                      _c("h2", { staticClass: "text-center" }, [
                        _vm._v("My Assessments")
                      ]),
                      _c("p", [
                        _vm._v(
                          " Welcome to the AI maturity assessment tool! Below you find an overview of your assessments. By clicking on "
                        ),
                        _c("span", { staticClass: "help__ui-label" }, [
                          _vm._v("Configure Assessment")
                        ]),
                        _vm._v(" you can edit existing assessments. ")
                      ]),
                      _c("p", [
                        _vm._v(" Choose "),
                        _c("span", { staticClass: "help__ui-label" }, [
                          _vm._v("view assessment results")
                        ]),
                        _vm._v(
                          " to get an evaluation of your answers or select "
                        ),
                        _c("span", { staticClass: "help__ui-label" }, [
                          _vm._v("new assessment")
                        ]),
                        _vm._v(" to create an additional assessment. ")
                      ]),
                      _c("p", [
                        _vm._v(
                          " If this is your first time performing this maturity assessment, please click "
                        ),
                        _c("span", { staticClass: "help__ui-label" }, [
                          _vm._v("new assessment")
                        ]),
                        _vm._v(" to initiate your first assessment. ")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "new-assessment md-layout-item md-size-100 md-small-size-100 mx-auto"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { "x-large": "", color: "success" },
                          on: { click: _vm.newAssessment }
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("add")
                          ]),
                          _vm._v(" New assessment ")
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "md-layout" }, [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100" },
                    [
                      _c("assessment-table", {
                        attrs: { configsOn: true },
                        on: { "assessment-change": _vm.onAssessmentChange }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }