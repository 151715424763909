<template>
  <div :id="'svg' + selectorId"></div>
</template>

<script>
import * as d3 from 'd3/dist/d3.min';

export default {
  name: 'HorizontalArrowBarChart',
  props: {
    matScoreValue: {
      required: true,
      type: Number,
    },
    selectorId: {
      required: true,
      type: Number
    }
  },
  mounted() {
    setTimeout(() => {
      this.drawXAxis(this.selectorId, this.matScoreValue);
    }, 500);
  },
  methods: {
    drawXAxis(selectorId, value) {
      var divContainer = d3.select('#svg' + selectorId);
      divContainer.append('svg').attr('class', 'svgContainer');
      var svg = divContainer.select('svg');
      // RIGHT ARROW
      svg.append('defs').append('marker')
        .attr('id', 'arrowhead')
        .attr('refX', 3)
        .attr('refY', 12)
        .attr('markerWidth', 17)
        .attr('markerHeight', 16)
        .attr('orient', 'right')
        .append('path')
        .attr('d', 'M2,2 L2,13 L8,7 L2,2');

      // X-AXIS WITH VALUES
      var formatxAxis = d3.format('.0f');
      var scale = d3.scaleLinear().domain([1, 4.6]).range([0, 180]);
      var x_axis = d3.axisBottom(scale);
      x_axis.tickValues([1, 2, 3, 4]).tickFormat(formatxAxis);
      svg.append('g').attr('transform', 'translate(3,18)').call(x_axis).style('font-size', '15');
   
      // GREEN THICK LINE
      svg.append('line')
        .style('stroke', '#00747a')
        .style('stroke-width', 10)
        .attr('x1', 3) // x1 is starting x point of the green line
        .attr('y1', 18)
        .attr('x2', value===1? 6 : 3 + 50.5*value - 50.5) // x2 is the ending poing of the green line-we add 3 because the staring point is 3 and we extract 50.5 because the range between the dots is 50.5 (taken from the screen)
        .attr('y2', 18);

      svg.select('g').select('path').attr('marker-end', 'url(#arrowhead)');
    }
  }
};
</script>

<style>
.svgContainer {
  height: 40px;
  width: 210px;
}
</style>