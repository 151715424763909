<template>
  <div class="wrapper">
   <Header />
    <div class="container">
      <div class="main main-raised">
        <h2 class="text-center">Licenses</h2>
        <p class="md-layout-item">
          This tool has been made using a variety of third-party open-source components. We thank the authors and acknowledge their contributions on this page.
        </p>
        <div class="card-container">
          <v-card
            v-for="dependency in dependencies"
            :key="dependency.name + dependency.version"
            class="dependency-card"
          >
            <v-card-title
              >{{ dependency.name }}
              <a
                v-if="dependency.repository"
                :href="dependency.repository"
                target="_blank"
                class="repository-link"
              >
                <v-icon
                  aria-label="Repository link"
                  aria-hidden="false"
                  role="img"
                >
                  mdi-open-in-new
                </v-icon>
              </a>
            </v-card-title>
            <v-card-subtitle v-if="dependency.author"
              >{{ dependency.author }}
            </v-card-subtitle>
            <v-card-text>
              Licensed under <strong>{{ dependency.license }}</strong>

              <v-dialog width="600">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    v-if="dependency.license && dependency.licenseText"
                  >
                    Show license text
                  </div>
                </template>
                <v-card>
                  <v-card-title>{{ dependency.license }}</v-card-title>
                  <v-card-text>
                    <p
                      class="license-text"
                      v-html="reflowHtml(dependency.licenseText)"
                    ></p>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/utils';
import Header from '@/components/Header';

export default {
  name: 'Licenses',
  components: {
    Header,
  },
  data() {
    return {
      dependencies: [],
    };
  },
  methods: {
    reflowHtml(text) {
      if (text === null) {
        return '';
      }
      return text
        .replaceAll(/\n(?!\n)/g, ' ') // squash lines
        .replaceAll(/^ /gm, '') // trim leading spaces
        .replaceAll(/\n+/g, '<br><br>'); // HTML line breaks
    },
  },
  mounted() {
    http
      .get(`${window.origin}/oss-licenses.json`)
      .then(({ data }) => (this.dependencies = data));
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 16px 0;
}

.dependency-card {
  flex-basis: 23%;
  margin: 10px 0;
  min-width: 16em;
  min-height: 170px;

  & .v-card__title {
    font-size: 110%;
    color: dimgrey;
  }
}

.repository-link {
  margin-left: 1ex;

  & .v-icon {
    font-size: inherit;
    vertical-align: baseline;
  }
}

.license-text {
  font-size: 14px;
  line-height: 1.1;
  white-space: pre-line;
}
</style>
