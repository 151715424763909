var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "main main-raised" },
        [
          _c("loading-indicator", {
            attrs: { "is-loading": _vm.organization.loading }
          }),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "v-responsive",
                { staticClass: "md-alignment-centered" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_vm._v("← Back")]
                  ),
                  !_vm.organization.loading
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-50 md-small-size-100 mx-auto"
                        },
                        [
                          _c("h3", { staticClass: "text-primary" }, [
                            _vm._v(_vm._s(_vm.organizationName))
                          ]),
                          _c("br"),
                          _c(
                            "form",
                            {
                              staticClass: "md-layout",
                              attrs: { novalidate: "" },
                              on: { submit: _vm.saveNewOrganization }
                            },
                            [
                              _c(
                                "md-card",
                                [
                                  _c(
                                    "md-card-content",
                                    [
                                      _c(
                                        "md-field",
                                        [
                                          _c("label", [
                                            _vm._v("Organization name:")
                                          ]),
                                          _c("md-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.organizationName,
                                              callback: function($$v) {
                                                _vm.organizationName = $$v
                                              },
                                              expression: "organizationName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-field",
                                        { staticClass: "custom-no-border" },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.countries,
                                              name: "country",
                                              "item-text": "name",
                                              "item-value": "code",
                                              label: "Country",
                                              "persistent-hint": "",
                                              "persistent-placeholder": "",
                                              disabled: ""
                                            },
                                            model: {
                                              value: _vm.organizationCountry,
                                              callback: function($$v) {
                                                _vm.organizationCountry = $$v
                                              },
                                              expression: "organizationCountry"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-field",
                                        [
                                          _c("label", [
                                            _vm._v("Contact person:")
                                          ]),
                                          _c("md-input", {
                                            model: {
                                              value:
                                                _vm.organizationContactPerson,
                                              callback: function($$v) {
                                                _vm.organizationContactPerson = $$v
                                              },
                                              expression:
                                                "organizationContactPerson"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-field",
                                        [
                                          _c("label", [_vm._v("Affiliation:")]),
                                          _c("md-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.organizationType,
                                              callback: function($$v) {
                                                _vm.organizationType = $$v
                                              },
                                              expression: "organizationType"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("br")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            type: "submit",
                                            disabled: _vm.disabledSaveBtn
                                          }
                                        },
                                        [_vm._v("Save")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.masterAccounts.length > 0
                            ? _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item md-size-100" },
                                  [
                                    _c("h4", { staticClass: "boldText" }, [
                                      _vm._v("Master accounts:")
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._l(_vm.masterAccounts, function(masterAccount) {
                            return _c(
                              "div",
                              {
                                key: masterAccount.email,
                                staticClass: "md-layout"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-45 md-large-size-60 md-xlarge-size-25"
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        " " + _vm._s(masterAccount.name) + " "
                                      )
                                    ]),
                                    _vm._v(
                                      " " + _vm._s(masterAccount.email) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-15 md-large-size-20 md-xlarge-size-10",
                                    on: {
                                      click: function($event) {
                                        return _vm.updateDetailsForUserFromOrganization(
                                          masterAccount.id,
                                          "name"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("a", { staticClass: "stretched-link" }, [
                                      _vm._v("[change]")
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-15 md-large-size-20 md-xlarge-size-10",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteUser(
                                          masterAccount.id,
                                          masterAccount.email
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("a", { staticClass: "stretched-link" }, [
                                      _vm._v("[delete]")
                                    ])
                                  ]
                                )
                              ]
                            )
                          }),
                          _c("br"),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item md-size-100" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.goToPage(
                                          "userAccountCreate",
                                          {
                                            account_type: "account-master",
                                            organization_id: _vm.organizationId,
                                            userRole: _vm.user.role
                                          }
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Add master account")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("br"),
                          _vm.userAccounts.length > 0
                            ? _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item md-size-100" },
                                  [
                                    _c("h4", { staticClass: "boldText" }, [
                                      _vm._v("User accounts:")
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._l(_vm.userAccounts, function(userAccount) {
                            return _c(
                              "div",
                              {
                                key: userAccount.email,
                                staticClass: "md-layout"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-45 md-large-size-60 md-xlarge-size-25"
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        " " + _vm._s(userAccount.name) + " "
                                      )
                                    ]),
                                    _vm._v(
                                      " " + _vm._s(userAccount.email) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-15 md-large-size-20 md-xlarge-size-10",
                                    on: {
                                      click: function($event) {
                                        return _vm.goToPage(
                                          "UserChangeAccountType",
                                          { user_id: userAccount.id }
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("a", { staticClass: "stretched-link" }, [
                                      _vm._v("[change]")
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-small-size-15 md-large-size-20 md-xlarge-size-10",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteUser(
                                          userAccount.id,
                                          userAccount.email
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("a", { staticClass: "stretched-link" }, [
                                      _vm._v("[delete]")
                                    ])
                                  ]
                                )
                              ]
                            )
                          }),
                          _c("br"),
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              { staticClass: "md-layout-item md-size-100" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.goToPage(
                                          "userAccountCreate",
                                          {
                                            account_type: "account-user",
                                            organization_id: _vm.organizationId,
                                            userRole: _vm.user.role
                                          }
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Add user account")]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("popup-modal", { ref: "popUp" }, [
            _c("div", { staticClass: "md-layout" }, [
              _c("div", { staticClass: "md-layout-item md-size-100" }, [
                _c("h2", [_vm._v("Change email address?")])
              ]),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-100" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Insert new email address here:")]),
                      _c("md-input", {
                        model: {
                          value: _vm.newEmailAddress,
                          callback: function($$v) {
                            _vm.newEmailAddress = $$v
                          },
                          expression: "newEmailAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.errorMsg
                ? _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100 red-text" },
                    [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-layout md-size-100 md-alignment-center-space-between"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "md-layout-item md-size-30",
                      attrs: { color: "warning" },
                      on: {
                        click: function($event) {
                          return _vm._cancel("popUp")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "md-layout-item md-size-30",
                      attrs: { color: "success" },
                      on: {
                        click: function($event) {
                          return _vm._confirm(false)
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ])
          ]),
          _c("popup-modal", { ref: "deletePopUp" }, [
            _c("div", { staticClass: "md-layout" }, [
              _c("div", { staticClass: "md-layout-item md-size-100" }, [
                _c("h2", [
                  _vm._v(
                    "Are you sure you want to delete this account? (" +
                      _vm._s(_vm.deletePopUpTitle) +
                      ")"
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-layout md-size-100 md-alignment-center-space-between"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "md-layout-item md-size-30",
                      attrs: { color: "warning" },
                      on: {
                        click: function($event) {
                          return _vm._cancel("deletePopUp")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "md-layout-item md-size-30",
                      attrs: { color: "success" },
                      on: {
                        click: function($event) {
                          return _vm._confirm(true)
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            ])
          ]),
          _c("confirm-dialogue", { ref: "confirmDialogue" })
        ],
        1
      ),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }