import { render, staticRenderFns } from "./SuccessfullCreation.vue?vue&type=template&id=0c48f347&"
import script from "./SuccessfullCreation.vue?vue&type=script&lang=js&"
export * from "./SuccessfullCreation.vue?vue&type=script&lang=js&"
import style0 from "./SuccessfullCreation.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VBtn,VResponsive})


/* hot reload */
if (module.hot) {
  var api = require("/builds/iaai/products/maturity-assessment-tool/mat-website/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c48f347')) {
      api.createRecord('0c48f347', component.options)
    } else {
      api.reload('0c48f347', component.options)
    }
    module.hot.accept("./SuccessfullCreation.vue?vue&type=template&id=0c48f347&", function () {
      api.rerender('0c48f347', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/SuccessfullCreation.vue"
export default component.exports