export default {
  setAllOrganizations (state, organizations) {
    state.list = organizations;
  },    
  setOrganizations(state, organizations) {
    let foundCountries = [];
    let finalArr = [];
    organizations.forEach(org => {
      if (foundCountries.indexOf(org.country_code)===-1){
        foundCountries.push(org.country_code);
        finalArr.push({ country_name: org.country_name, nationalHost: org.type==='NH'? org : null, certifiedPartners: org.type==='NH'? [] : [org] });
      } else {
        let objInFinalArray = finalArr.find(fin => fin.country_name==org.country_name);
        if (org.type==='NH'){
          objInFinalArray.nationalHost = org;
        } else if (org.type==='CP') {
          objInFinalArray.certifiedPartners.push(org);
        }
      }
    });
    state.list = finalArr;
    state.loading = false;
  },
  setUsersForOrganization(state, users) {
    state.organization.users = users;
  },
  setOrganization(state, organization) {
    state.organization = organization;
  },
  setOrganizationLoading(state, value){
    state.organization.loading = value;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
  setError(state, error) {
    state.error = error;
  },
  setNationalHosts(state, nationalHosts) {
    state.nationalHosts = nationalHosts;
  },
  removeUserFromList(state, id) {
    let currentOrganization = state.organization;
    let reducedUsers = currentOrganization.users.filter(user => user.id != id);
    currentOrganization.users = reducedUsers;
    state.organization = currentOrganization;
  },
  updateCollaboratorUserProperty(state, { userId, fieldName, value }){
    let currentOrganization = state.organization;
    let newUsers =currentOrganization.users.map(user => {
      if(user.id === userId) {
        user[fieldName] = value;
      }
      return user;
    });
    currentOrganization.users = newUsers;
    state.organization = currentOrganization;
  },
};
    
