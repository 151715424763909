<template>
    <div>
        <v-btn color="primary" @click="toggleGroupsTable">
            <v-icon>{{ showGroupTable? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
            &nbsp;{{ showGroupTable? 'Hide groups table' : 'Show groups table' }}
        </v-btn>
        <group-table 
          v-if="showGroupTable" 
          ref="configGroupTable" 
          :sme_version="sme_version" 
          :assessment_id="this.assessment.assessment_id" 
          :status="this.assessment.status" 
          :areTermsAccepted="this.assessment.terms_of_use_accepted"
          :userRole="userRole"
        />
    </div>
</template>

<script>
import { GroupTable } from '@/components';

export default {
  name: 'ToggleGroupsTable',
  components: {
    GroupTable,
  },
  props: {
    sme_version: {
      type: Number,
    },
    assessment: {
      type: Object,
      required: true,
    },
    userRole: {
      type: String,
      required: true
    }
  }, 
  data() {
    return {
      showGroupTable: false,
    };
  },
  methods: {
    toggleGroupsTable() {
      this.showGroupTable = !this.showGroupTable;
    },
  }
};
</script>
