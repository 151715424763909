
<template>
  <div>
    <loading-indicator :is-loading="participantsLoading" />
      <div class="assessment-table">
        <div class="md-layout md-alignment-bottom-right">
          <div class="md-layout-item md-size-25">
            <v-card-text>
              <v-text-field
                color="success"
                clearable
                :loading="participantsLoading"
                density="compact"
                variant="solo"
                label="Search..."
                append-icon="mdi-magnify"
                single-line
                hide-details
                v-model="options.searchByValue"
                @click:append="getSearchResults"
                @click:clear="clearSearchByValue"
                v-on:keyup.enter="getSearchResults">
              </v-text-field>
            </v-card-text>
          </div>
        </div>

      <md-table v-model="assessments.list" md-card style="background-color: rgba(255, 255, 255, 0.4)" class="inline">
        <md-table-row v-if="assessments.list.length === 0" class="md-primary" slot="md-table-row" md-fixed-header>
          <label class="no-items-found">No assessments found</label>
        </md-table-row>
        <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-fixed-header>
          <md-table-cell md-label="Created on/by" md-sort-by="time_created">
            {{ item.time_created.substring(0, item.time_created.indexOf("T")) }}
            <br/>
            {{item.username}}
          </md-table-cell>
          <md-table-cell md-label="Name" md-sort-by="name">
            <b>{{ item.name }}</b>
          </md-table-cell>
          <md-table-cell md-label="Status" md-sort-by="status" class="status-col">
            <assessment-status :status="item.status" />
          </md-table-cell>
          <md-table-cell md-label="Details" style="width: 150px">
            <div v-if="item.status === 'created'">
              <b>Not started yet.</b> Go to configuration to start assessment.
            </div>
            <div v-if="item.status === 'running'">
              <b>Running for: </b>{{ calculateDuration(item.date_started) }} days
            </div>
            <div v-if="item.status === 'completed'">
              <b>Closed on </b>{{ item.date_closed }}
            </div>
          </md-table-cell>
          <md-table-cell md-label="# Participants" class="participation-col" style="width: 50px">
            <div v-if="item.status === 'created'">N/A</div>
            <div v-else>{{ item.totalParticipants }}</div>
          </md-table-cell>
          <md-table-cell md-label="# Completed/NonCompleted" class="participants-per-group-col">
            <div v-if="item.status === 'created'">N/A</div>
            <div v-else v-for="perGroup in item.participantsPerGroup" :key="perGroup.name">
              <template v-if="perGroup.name!=='completed'">
                <span class="bold-group-names">
                  {{ perGroup.name }}:
                </span>
                {{ perGroup.value.completed }}/
                {{perGroup.value.total-perGroup.value.completed}}
              </template>
            </div>
          </md-table-cell>
          <md-table-cell md-label="Actions" v-if="item.status === 'completed'">
            <v-btn small color="success" target="_blank" class="action-button" @click="viewElem(item.assessment_id)">
              <v-icon>mdi-magnify</v-icon>&nbsp;Results
            </v-btn>
          </md-table-cell>
          <md-table-cell md-label="Actions" class="action-btns-container" v-if="item.status === 'created' || item.status === 'running'">
            <v-btn small color="warning" target="_blank" class="action-button" @click="configElem(item.assessment_id)">
              <v-icon small>mdi-cog</v-icon>&nbsp;Configure
            </v-btn>
            <v-btn small color="success" target="_blank" class="action-button" style="margin-left: 7px" @click="sendMailWithSurveyLinks(item.assessment_id, item.participantsPerGroup)">
              <v-icon small>mdi-bell-ring</v-icon>&nbsp; Send reminder
            </v-btn>
          </md-table-cell>
          <md-table-cell>
            <div class="md-lg delete_cell">
              <v-btn class="ma-2" icon color="red" dark @click="confirmDeletion(item.assessment_id, item.name, item.status)">
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="md-layout">
        <div class="status-legend md-layout-item md-size-100">
          Status legend:
          <span v-for="status in ['created', 'running', 'completed']" :key="status">
            <assessment-status :status="status" /> {{ status }}
          </span>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-xsmall-hide md-size-35"></div>
        <div class="md-layout-item md-xsmall-size-100 md-size-30 mt-10">
          <v-pagination
            v-model="currentPage"
            :length="numOfPages"
            color="#5f625f"
          ></v-pagination>
        </div>
        <div class="md-layout-item md-xsmall-hide md-size-20"></div>
        <div class="md-layout-item md-xsmall-size-100 md-size-15">
          <div class="md-layout md-alignment-top-right">
            <div class="md-layout-item md-xsmall-size-25 md-large-size-100">
              <v-select
                :items="[
                  {text: 5, value: 5},
                  {text: 10, value: 10},
                  {text: 20, value: 20},
                  {text: 30, value: 30},
                  {text: 'All', value: this.totalItems}]"
                v-model="perPage"
                name="assessmentsPerPage"
                label="Rows per page"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
      <snackbar />
    </div>
  </div>
</template>

<script>
import { AssessmentStatus, ConfirmDialogue, Snackbar, LoadingIndicator } from '@/components';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AssessmentTable',
  components: { AssessmentStatus, ConfirmDialogue, Snackbar, LoadingIndicator },
  props: {
    configsOn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    page:1,
    options: {
      currentPage: 1,
      perPage: 10,
      searchByValue: '',
    }
  }),
  computed: {
    ...mapState({
      assessments: state => state.assessments,
      items: state => state.assessments.list,
      totalItems: state => state.assessments.count,
      participantsLoading: state => state.assessments.participantsLoading,
    }),
    numOfPages() {
      return this.totalItems < this.options.perPage ? 1 : Math.ceil(this.totalItems / this.options.perPage);
    },
    currentPage: {
      get() {
        return this.assessments.currentPage;
      },
      set(newPageNumber) {
        this.options.currentPage = newPageNumber;
        this.getDataForTable(this.options);
      }
    },
    perPage: {
      get() {
        return this.assessments.perPage;
      },
      set(perPageNewValue) {
        this.options.perPage = perPageNewValue;
        this.options.currentPage = 1;
        this.options.searchByValue = '';
        this.getDataForTable(this.options);
      }
    },
  },
  mounted() {
    if (sessionStorage.getItem('currentPage')) {
      this.options.currentPage = parseInt(sessionStorage.getItem('currentPage'));
    }
    if (sessionStorage.getItem('perPage')) {
      this.options.perPage = parseInt(sessionStorage.getItem('perPage'));
    }
    if (sessionStorage.getItem('searchByValue') && sessionStorage.getItem('searchByValue') !== 'null') {
      this.options.searchByValue = sessionStorage.getItem('searchByValue');
    }
    this.getDataForTable(this.options);
  },
  methods: {
    ...mapActions({
      getAssessments: 'assessments/getAssessments',
      deleteAssessmentAction: 'assessments/deleteAssessment',
      sendMailWithSurveyLinksAction: 'assessments/sendMailWithSurveyLinks',
      hideSnackBarAction: 'assessments/hideSnackBar',
      getParticipants: 'assessments/getParticipantsCount',
    }),
    getSearchResults(){
      this.options.currentPage = 1;
      this.getDataForTable(this.options);
    },
    clearSearchByValue(){
      this.options.searchByValue = null;
      this.getDataForTable(this.options);
    },
    calculateDuration(assessmentStartedOnDate){
      let startedDate = new Date(assessmentStartedOnDate);
      let currentDate = new Date();
      var duration = currentDate.valueOf() - startedDate.valueOf(); // The unit is millisecond
      var daysDiff = parseInt(duration / (1000 * 3600 * 24));
      return daysDiff;
    },
    getDataForTable (options) {
      this.getAssessments({ payload: options, cb: ((all) => {
        sessionStorage.setItem('perPage', options.perPage);
        sessionStorage.setItem('currentPage', options.currentPage);
        sessionStorage.setItem('searchByValue', options.searchByValue);

        all.forEach(assessment => {
          if(assessment.status === 'running' || assessment.status === 'completed') {
            this.getParticipants(assessment.assessment_id);
          }
        });
      })});
    },
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3,
    }),
    hideSnackbar(){
      this.hideSnackBarAction();
    },
    viewElem(assessmentId) {
      this.$emit('assessment-change', { assessmentId, shouldConfigure: false });
    },
    configElem(assessmentId) {
      this.$emit('assessment-change', { assessmentId, shouldConfigure: true });
    },
    async confirmDeletion(assessment_id, name, status) {
      if (status === 'running') {
        await this.showDialog('Delete Assessment', 'The assessment ' + name + ' is still running. Please stop the assessment via the configuration before deleting it.', null, 'Ok, go back');
      } else {
        //assessment is not running, so it can be deleted, show dialog to confirm/reject deletion
        const ok = await this.showDialog( 'Delete Assessment', 'Are you sure you want to delete the assessment -' + name + '-? This step cannot be undone.', 'Yes, delete assessment', 'No, go back');
        if (!ok) {
          return;
        }
        await this.deleteAssessment(assessment_id);
      }
    },
    async deleteAssessment(assessment_id) {
      this.deleteAssessmentAction({ assessment_id });
    },
    showDialog(title, message, okText, cancelText, showEmailInputPart){
      return this.$refs.confirmDialogue
        .show({
          title,
          message,
          okButton: okText,
          cancelButton: cancelText,
          showEmailInputPart
        })
        .then();
    },
    async sendMailWithSurveyLinks(assessment_id) {
      this.hideSnackBarAction();
      const responseValue = await this.showDialog(
        null,
        'Please enter the email address of your contact person from the organization doing the assessment to send the links per email. A copy will be automatically sent to your address. To just send the reminder to your address leave the field blank.','Send the links via email', 'Cancel', true, 'Insert e-mail addresses');
      if (responseValue) {
        let recipients = null;
        if (responseValue!==true) {
          recipients = responseValue;
        }
        this.sendMailWithSurveyLinksAction({
          assessment_id,
          recipients: recipients,
          is_reminder: true,
          cb: this.showDialog
        });
      }
    },
  },
};
</script>

<style lang="scss">
#longTextHeader {
  width: 125px;
  text-align: center;
}
#longTextHeader * {
  overflow: initial;
  text-overflow: initial;
  white-space: normal;
}
.md-table-cell-container, .md-table-head-container{
  text-align: center;
}
.assessment-table {
  margin-bottom: 16px;
  padding: 0px 5px;
  .md-table-cell {
    padding: 12px 0px;
  }
  .md-card{
    margin-bottom: 10px;
  }
}
.status-legend {
  text-align: left;
  font-size: 80%;
  color: grey;
}
.status-legend > span {
  margin: 0px 8px 0px 8px;
}
.status-legend > span > .v-icon{
  font-size: 16px;
}
.action-btns-container > .md-table-cell-container {
  display: flex;
  justify-content: center;
}
.md-table-cell.participants-per-group-col {
  padding-left: 0;
  padding-right: 0;
}
.bold-group-names {
  font-weight: 500;
  font-size: 15px;
}
.no-items-found {
  padding-left: 20px;
}
</style>
