import assessments from '../../../api/assessments';
import { tackleErrorMessage } from '../../../api';

export const getAssessments = async ({ commit, dispatch }, { payload, cb }) => {
  try {
    const response = await assessments.getAssessments(payload);
    const removedDeletedAssessments = response.data.results.filter((a) => a.status !== 'deleted');
    commit('setAssessments', { assessmentswithPagination: response.data, payload: payload });
    cb(removedDeletedAssessments);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getAssessment = async ({ commit, dispatch }, id) => {
  try {
    const response = await assessments.getAssessment(id);
    commit('setAssessment', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getAssessmentBenchmarkingData = async ({ commit, dispatch }, id) => {
  try {
    const response = await assessments.getAssessmentBenchmarkingData(id);
    commit('addBenchmarkingDataToAssessment',
      {
        industry: response.data.industry,
        size: response.data.size,
      });
    commit('saveBenchmarkSelection', 
      {
        industries: [response.data.industry],
        sizes: [response.data.size],
        all_countries: false,
      },);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const deleteAssessment = async ({ commit, dispatch }, { assessment_id }) => {
  try {
    await assessments.deleteAssessment(assessment_id, commit);
    commit('deleteAssessment', assessment_id);
    commit('showSnackBar', 'Assessment successfully deleted!');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const createAssessment = async ({ dispatch }, cb) => {
  let name = 'unnamed';
  let currentdate = new Date();
  let date_created =
    currentdate.getFullYear() +
    '-' +
    ('0' + (currentdate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + currentdate.getDate()).slice(-2) +
    ' ' +
    ('0' + currentdate.getHours()).slice(-2) +
    ':' +
    ('0' + currentdate.getMinutes()).slice(-2);

  const payload = {
    name: name,
    time_created: date_created,
    release: 3, //always use latest release (3 refers to MAT 1.2)})
  };
  try {
    let response = await assessments.createAssessment(payload);
    cb(response.data.assessment_id);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const updateAssessmentName = async ({ commit, dispatch }, { assessment_id, msg, ...payload }) => {
  try {
    await assessments.updateAssessmentName(assessment_id, payload);
    commit('showSnackBar', msg);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const startAssessment = async ({ dispatch }, { assessment_id, organization_id, cb }) => {
  try {
    await assessments.startAssessment(assessment_id, organization_id);
    cb(true);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
    cb(false);
  }
};

export const closeAssessment = async ({ dispatch }, { assessment_id, cb }) => {
  try {
    await assessments.closeAssessment(assessment_id);
    cb(true);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
    cb(false);
  }
};

export const sendMailWithSurveyLinks = async ({ commit, dispatch }, { assessment_id, recipients, is_reminder }) => {
  try {
    await assessments.sendMailWithSurveyLinks(assessment_id, recipients, is_reminder);
    commit('showSnackBar', 'Survey links sent successfully via email - check your inbox.');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getGroups = async ({ commit, dispatch }, { assessment_id, cb }) => {
  try {
    const response = await assessments.getGroups(assessment_id);
    commit('setGroups', response.data);
    cb(response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const createGroup = async ({ commit, dispatch }, { assessment_id, ...payload }) => {
  try {
    const response = await assessments.createGroup(assessment_id, payload);
    commit('setGroups', response.data);
    commit('showSnackBar', 'The new group was saved successfully!');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const deleteGroup = async ({ commit, dispatch }, { assessment_id, group_id }) => {
  try {
    await assessments.deleteGroup(assessment_id, group_id);
    commit('deleteGroup', group_id);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getQuestions = async ({ dispatch }, { assessment_id, cb }) => {
  try {
    const response = await assessments.getQuestions(assessment_id);
    cb(response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const saveAnswers = async ({ commit, dispatch }, payload) => {
  try {
    await assessments.saveAnswers(payload);
    commit('showSnackBar', 'Changes saved successfully!');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getMaturityData = async ({ commit, dispatch }, { assessment_id, cb }) => {
  commit('setMaturityDataLoading', true);
  try {
    const response = await assessments.getMaturityData(assessment_id);
    commit('setMaturityData', response.data);
    commit('setMaturityDataLoading', false);
    cb(response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
    commit('setMaturityDataLoading', false);
  }
};

export const getParticipants = async ({ commit, dispatch }, assessment_id) => {
  commit('setParticipantsLoading', true);
  try {
    const response = await assessments.getParticipants(assessment_id);
    commit('setParticipants', response.data);
    commit('setParticipantsLoading', false);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
    commit('setParticipantsLoading', false);
  }
};

export const getMatGroups = async ({ commit, dispatch }, assessment_id) => {
  try {
    const response = await assessments.getMatGroups(assessment_id);
    commit('setMatGroups', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getAssessmentContainsGroups = async ({ commit, dispatch }, assessment_id) => {
  try {
    const response = await assessments.getAssessmentContainsGroups(assessment_id);
    commit('setAssessmentContainsGroups', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getSurveyParticipants = async ({ commit, dispatch }, assessment_id) => {
  try {
    const response = await assessments.getSurveyParticipants(assessment_id);
    commit('setSurveyParticipants', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getAllClustersDetailData = async ({ commit, dispatch }, assessment_id) => {
  try {
    const response = await assessments.getAllClustersDetailData(assessment_id);
    commit('setQuestionsAndRatings', response.data.questions_and_ratings);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getClusterDetailData = async ({ commit, dispatch }, {params, cb}) => {
  try {
    const response = await assessments.getClusterDetailData(params);
    commit('setQuestionsAndRatings', response.data.questions_and_ratings);
    cb(response.data.questions_and_ratings);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const getAutoReportData = async ({ commit, dispatch }, params) => {
  try {
    const response = await assessments.getAutoReportData(params);
    commit('setAutoReportData', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const showSnackBar = ({ commit }, text) => {
  commit('showSnackBar', text);
};

export const hideSnackBar = ({ commit }) => {
  commit('hideSnackBar');
};

export const getParticipantsCount = async ({ commit, dispatch }, id) => {
  commit('setParticipantsLoading', true);
  try {
    const response = await assessments.getSurveyParticipantsCount(id);
    commit('setParticipantsCountForAssessment', { participantsCount: response.data, assessmentId: id });
    commit('setParticipantsLoading', false);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
    commit('setParticipantsLoading', false);
  }
};

export const resetStore = ({ commit }) => {
  commit('resetStore');
};

export const saveIsDataReal = async ({ commit, dispatch }, payload) => {
  try {
    await assessments.saveIsDataReal(payload);
    commit('updateIsDataReal', payload.isDataReal);
    commit('showSnackBar', 'Changes saved successfully!');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const sendEmailWithTermsOfUse = async ({ commit, dispatch }, { assessment_id, emailAddress, redirectUrl }) => {
  try {
    await assessments.sendEmailWithTermsOfUse(assessment_id, emailAddress, redirectUrl);
    commit('showSnackBar', 'Email with terms of use was sent');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const mergeGroups = async ({ commit, dispatch }, { payload, cb }) => {
  try {
    await assessments.mergeGroups(payload);
    cb();
    commit('showSnackBar', 'Changes saved successfully!');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const termsOfUseAccepted = async ({ commit, dispatch }, { payload, cb }) => {
  try {
    await assessments.termsOfUseAccepted(payload);
    cb();
    commit('showSnackBar', 'Thank you for accepting our terms of service!');
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const downloadPowerpointSlides = async ({ commit, dispatch }, { payload, cb }) => {
  commit('setPowerPointDownloadLoading', true);
  try {
    const response = await assessments.downloadPowerpointSlides(payload);
    cb('Successfull download!', response.data);
    commit('setPowerPointDownloadLoading', false);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
    commit('setPowerPointDownloadLoading', false);
  }
};

export const getTypesOfCompanies = async ({ commit, dispatch }, assessment_id) => {
  try {
    const response = await assessments.getTypesOfCompanies(assessment_id);
    commit('setTypesOfCompaniesBySizeAndIndustry', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const createBenchmarkData = async ({ dispatch }, { cb }) => {
  try {
    const response = await assessments.createBenchmarkData();
    cb('Successfull download!', response.data);
  } catch (error) {
    tackleErrorMessage(dispatch, error);
  }
};

export const applyBenchmarkSelection = async ({ commit }, { payload, cb }) => {
  commit('setApiLoading', true);
  commit('saveBenchmarkSelection', payload.benchmark_data);
  try {
    const response = await assessments.applyBenchmarkSelection(payload);
    cb(response.data.message, true);
    commit('setApiLoading', false);
  } catch (error) {
    cb(error.response?.data.message || error.response?.data.detail || 'Sorry, an error occurred!', false);
    commit('setApiLoading', false);
  }
};

export const updateAutoFindingsData = async (_, { formData, cb }) => {
  try {
    const response = await assessments.updateAutoFindingsData(formData);
    cb(response.data.message);
  } catch (error) {
    cb(error.response?.data.message || error.response?.data.detail || 'Sorry, an error occurred!');
  }
};

