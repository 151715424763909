import MainPage from './MainPage.vue';
import Landing from './Landing.vue';
import Config from './Config.vue';
import SuccessfullCreation from './SuccessfullCreation.vue';
import UserCreateAccount from './user/UserCreateAccount.vue';
import UserChangeAccountType from './user/UserChangeAccountType.vue';

import OrganizationCreate from './organization/OrganizationCreate.vue';
import OrganizationView from './organization/OrganizationView.vue';

import AccountDetails from './AccountDetails.vue';
import Licenses from './Licenses.vue';
import InvalidPage from './Invalid.vue';
import PageNotFound from './PageNotFound.vue';

import TermsOfUse from './TermsOfUse.vue';
import PreviewTermsOfUse from './PreviewTermsOfUse.vue';
import ClusterDetails from './ClusterDetails.vue';
import UserGuide from './UserGuide.vue';

import FAQ from './FAQ.vue';


export { 
  MainPage, 
  Landing, 
  Config, 
  SuccessfullCreation, 
  UserCreateAccount, 
  OrganizationCreate, 
  AccountDetails, 
  Licenses, 
  InvalidPage, 
  PageNotFound, 
  OrganizationView,
  TermsOfUse,
  UserChangeAccountType,
  PreviewTermsOfUse,
  ClusterDetails,
  UserGuide,
  FAQ,
};
