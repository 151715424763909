<template>
  <form novalidate>
    <md-card>
      <md-card-content>
        <div class="row" v-for="item in form.b_questions" :key="item.question_id">
          <div class="question">{{ item.question_text }}</div>
          <div class="answer">
            <md-field>
              <md-select v-model="item.answer_text" md-dense :disabled="sending || readonly || disabledIfGermanyNotSelected(item.question_id)">
                <template v-for="option in item.options">
                  <md-option :key="option" :value="option">
                    {{ option }}
                  </md-option>
                </template>
              </md-select>
              <span v-if="formHasErrors && item.has_error && item.question_id==2" class="md-error">Organization’s yearly revenue is a required field.</span>
              <span v-if="formHasErrors && item.has_error && item.question_id==3" class="md-error">Industry is a required field.</span>
              <span v-if="formHasErrors && item.has_error && item.question_id==4" class="md-error">Country headquarter is a required field.</span>
              <span v-if="formHasErrors && item.has_error && item.question_id==5" class="md-error">Answer doesn't match with the answer on the previous question</span>

            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-card-actions>
        <v-btn color="primary" v-if="!readonly" @click="saveAnswers" :disabled="sending">
          Save Answers
        </v-btn>
      </md-card-actions>
    </md-card>
    <md-progress-bar md-mode="indeterminate" v-if="sending" />
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { logger } from '@/utils';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BenchmarkQuestionTable',
  mixins: [validationMixin],
  props: {
    assessment_id: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    lastUser: null,
    userSaved: false,
    unsavedChanges: false,
    snackbarduration: 3000,
    selected: {},
    form: {
      b_questions: [],
    },
    loaded: true,
    formHasErrors: false,
  }),
  created() {
    window.addEventListener('beforeunload', this.saveAnswers);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.saveAnswers);
  },
  mounted() {
    // fetch list and populate table
    this.getQuestions();
  },
  // TODO: more in-depth validations can be done here.
  // see docs of vuelidate for more options.
  validations: {
    form: {
      b_questions: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      showSnackbar: state => state.assessments.showSnackbar,
      snackbarText: state => state.assessments.snackbarText,
      sending: state => state.assessments.loading,
    }),
  },
  watch: {
    form: {
      handler: function() {
        const questionIsItGermany = this.form.b_questions.find(question => question.question_id == 4);
        const questionGermanyState = this.form.b_questions.find(question => question.question_id == 5);
        if (questionIsItGermany.answer_text !== 'Germany') {
          questionGermanyState.answer_text = null;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getQuestionsAction: 'assessments/getQuestions',
      saveAnswersAction: 'assessments/saveAnswers'
    }),
    getQuestions() {
      logger.info('Getting question and answers for assessment: ' + this.assessment_id);
      this.getQuestionsAction({ assessment_id: this.assessment_id, cb:((data)=>{
        this.$set(this.form, 'b_questions', data);
      })});
    },
    disabledIfGermanyNotSelected(question_id){
      // question number 5(In case your organization is headquartered in Germany, which federal state are your headquarters located in?) should be disabled if in the previous question the answer is not Germany
      if(question_id === 5) {
        const countryQuestion = this.form.b_questions.find(question => question.question_id === 4);
        if(countryQuestion.answer_text !== 'Germany') {
          return true;
        }
      }
      return false;
    },
    validateForm() {
      this.formHasErrors= false;
      let questionIsItGermany;
      this.form.b_questions.map(question => {
        // 2,3,4 and 5 are question_id for these question:
        // - What is your organization's yearly revenue?
        // - In which industry does your organization have its main business?
        // - Which country is your organization's headquarter located in? 
        // - In case your organization is headquartered in Germany, which federal state are your headquarters located in?
        if(question.question_id===4) {
          questionIsItGermany = question;
        }
        if([2,3,4].includes(question.question_id)) {
          if(question.answer_text==null){
            this.formHasErrors= true;
            question.has_error= true;
          } else {
            question.has_error= false;
          }
        } else if(question.question_id===5){
          if(questionIsItGermany.answer_text=='Germany' &&
            question.answer_text==null){
            this.formHasErrors= true;
            question.has_error= true;
          } else {
            question.has_error= false;
          }
        }
      });
    },
    saveAnswers() {
      this.validateForm();
      if(!this.formHasErrors){
      // Filter out all unnecessary keys from form data before API call
        logger.info('Saving user for assessment: ' + this.assessment_id);
        const allowedKeys = ['question_id', 'answer_text'];
        const questionData = this.form.b_questions.map((q) =>
          Object.keys(q)
            .filter((key) => allowedKeys.includes(key))
            .reduce((obj, key) => {
              return {
                ...obj,
                [key]: q[key],
              };
            }, {})
        );
        const payload = {
          assessment_id: this.assessment_id,
          new_form: questionData,
        };
        this.saveAnswersAction(payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  margin: 0 auto;
  width: 100%;
  align-items: center;
}
.question {
  text-align: left;
  padding-right: 8px;
  flex: 3;
}
.answer {
  flex: 2;
}
.md-field .md-error {
  opacity: 1;
  color: red;
  top: 50px;
}
</style>
