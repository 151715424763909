<template>
 <div class="wrapper">
   <Header />
    <div class="main main-raised">
      <loading-indicator :is-loading="loading" />
      <div class="container">
        <v-responsive class="md-alignment-centered">
          <v-btn color="primary" plain @click="$router.go(-1)">← Back</v-btn>
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <div>
                  <h3 class="text-primary">
                    {{ urlFormType===formTypes.certifiedPartner? "Addition of certified partner" : "Addition of national host" }}
                  </h3>
                  <form novalidate class="md-layout" @submit.prevent="createOrganization" >
                    <md-card>
                      <md-card-content>
                        <md-field :class="!$v.organizationName.required && submitStatus==='ERROR'? 'md-invalid' : ''">
                          <label>Organization name:</label>
                          <md-input v-model="organizationName"></md-input>
                          <span class="md-error" v-if="!$v.organizationName.required && submitStatus==='ERROR'">Organization name is a required field</span>
                        </md-field>
                        <md-field class='custom-no-border' v-if="urlFormType===formTypes.certifiedPartner">
                            <v-select
                            :items="nationalHostsWithNA"
                            name="affiliatedNationalHost"
                            v-on:change="setSelectedCountry"
                            item-value="host_name"
                            :item-text="item => item.host_name"
                            label="Affiliated national host:"
                            persistent-hint
                            persistent-placeholder
                          ></v-select>
                        </md-field>
                        <md-field :class="!$v.country.required && submitStatus==='ERROR'? 'md-invalid custom-no-border' : 'custom-no-border'">
                          <v-select
                            :items="countries"
                            v-model="country"
                            name="country"
                            item-text="name"
                            item-value="code"
                            label="Select county"
                            persistent-hint
                            persistent-placeholder
                            :disabled="countryDropdownDisabled"
                          ></v-select>
                          <span class="md-error" v-if="!$v.country.required && submitStatus==='ERROR'">Please select country</span>
                        </md-field>
                        <md-field :class="(!$v.contactPersonsEmail.required || !$v.contactPersonsEmail.mail) && submitStatus==='ERROR'? 'md-invalid' : ''">
                          <label>Contact person's email:</label>
                          <md-input v-model="contactPersonsEmail"></md-input>
                          <span class="md-error" v-if="!$v.contactPersonsEmail.required && submitStatus==='ERROR'">Please insert email address</span>
                          <span class="md-error" v-if="!$v.contactPersonsEmail.email && submitStatus==='ERROR'">Please insert valid email address</span>
                        </md-field>
                      </md-card-content>
                      <md-card-actions>
                        <v-btn color="primary" type="submit">Save</v-btn>
                      </md-card-actions>
                    </md-card>
                  </form>
                <br />
              </div>
            </div>
        </v-responsive>
      </div>
    </div>
    <snackbar />
  </div>
</template>

<script>
import backend_connector from '../../mixins/backend_connector';
import LoadingIndicator from '@/components/LoadingIndicator';
import Snackbar from '@/components/Snackbar.vue';
import Header from '@/components/Header';
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'OrganizationCreate',
  mixins: [backend_connector,validationMixin],
  components: {
    LoadingIndicator,
    Snackbar,
    Header,
  },
  bodyClass: 'index-page',
  created() {
    this.urlFormType = this.$route.params.organization_type;
  },
  data: () => ({
    formTypes: {
      certifiedPartner: 'certified-partner',
      nationalHost: 'national-host',
    },
    urlFormType: '',
    organizationName: null,
    country: null,
    contactPersonsEmail: null,
    submitStatus: null,
    countryDropdownDisabled: false
  }),
  validations() {
    return {
      organizationName: {
        required,
      },
      country: {
        required,
      },
      contactPersonsEmail: {
        required,
        email,
      }
    };
  },
  computed: {
    ...mapState({
      countries: state => state.organizations.countries,
      nationalHosts: state => state.organizations.nationalHosts,
      loading: state => state.organizations.loading,
      user: state => state.user,
    }),
    nationalHostsWithNA() {
      return [{code: 'N/A', country_name: '', host_name: 'None'}].concat(this.nationalHosts);

    },
  },
  mounted(){
    this.getListOfCountriesAction();
    this.getListOfNationalHostsAction();
  },
  methods: {
    ...mapActions({
      getListOfCountriesAction: 'organizations/getListOfCountries',
      createOrganizationAction: 'organizations/createOrganization',
      hideSnackBarAction: 'assessments/hideSnackBar',
      showSnackBarAction: 'assessments/showSnackBar',
      getListOfNationalHostsAction: 'organizations/getAllNationalHosts'
    }),
    setSelectedCountry(natHostName){
      if(natHostName === 'None') {
        this.countryDropdownDisabled = false;
        return;
      }
      let natHostCountry = this.nationalHosts.find(natHost => natHost.host_name===natHostName);
      this.countryDropdownDisabled = true;
      this.country = natHostCountry? natHostCountry.code : null;
    },
    hideSnackBar(){
      this.hideSnackBarAction();
    },
    async createOrganization(e) {
      e.preventDefault();
      const orgType = this.urlFormType === this.formTypes.certifiedPartner? 'CP' : 'NH';
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.submitStatus = 'OK';
        this.createOrganizationAction({ 
          type: orgType,
          name: this.organizationName, 
          country: this.country, 
          email:  this.contactPersonsEmail,
          cb: (()=> this.$router.push({ name: 'index' }))
        });
      }
    },
  }
};
</script>

<style lang="scss">
.md-field.md-theme-default.custom-no-border:after{
  content: none;
}
.wrapper p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #222;
  text-align: left;
}
.md-theme-default a:not(.md-button).stretched-link {
  color: #009688;
  font-weight: 500;
  padding: 5px;
  &:hover {
    font-weight: 600;
  }
}
.boldText {
  font-weight: 600;
}
</style>
