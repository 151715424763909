<template>
 <div class="wrapper">
   <Header />
    <div class="main main-raised">
      <loading-indicator :is-loading="loading" />
      <div class="container">
        <v-responsive class="md-alignment-centered">
          <v-btn color="primary" plain @click="$router.go(-1)">← Back</v-btn>
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
                <h3 class="text-primary">{{ title }}</h3>
                <h4 class="text-primary">{{ organization && organization.details? organization.details.name : '' }}</h4>
                <form novalidate class="md-layout" @submit.prevent="createAccount">
                  <md-card>
                    <md-card-content>
                      <md-field :class="!$v.name.required && submitStatus==='ERROR'? 'md-invalid' : ''">
                        <label>Name:</label>
                        <md-input v-model="name"></md-input>
                        <span class="md-error" v-if="!$v.name.required && submitStatus==='ERROR'">Name is a required field</span>
                      </md-field>
                      <md-field :class="(!$v.email.required || !$v.email.email) && submitStatus==='ERROR'? 'md-invalid' : ''">
                        <label>E-mail address:</label>
                        <md-input v-model="email"></md-input>
                        <span class="md-error" v-if="!$v.email.required && submitStatus==='ERROR'">E-mail address is a required field</span>
                        <span class="md-error" v-if="!$v.email.email && submitStatus==='ERROR'">Please insert valid email address</span>
                      </md-field>
                      <md-field :class="!$v.comment.required && submitStatus==='ERROR'? 'md-invalid' : ''">
                        <label>Comments:</label>
                        <md-input v-model="comment"></md-input>
                        <span class="md-error" v-if="!$v.comment.required && submitStatus==='ERROR'">Comment is a required field</span>
                      </md-field>
                    </md-card-content>
                    <md-card-actions>
                      <v-btn color="primary" type="submit" :loading="savingData" :disabled="savingData">Save</v-btn>
                    </md-card-actions>
                  </md-card>
                </form>
                <br />
            </div>
        </v-responsive>
      </div>
    </div>
  <snackbar />

  </div>
</template>

<script>
import backend_connector from '../../mixins/backend_connector';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { LoadingIndicator, Header, Snackbar } from '@/components/';
import { mapActions, mapState, mapGetters } from 'vuex';
import { Roles } from '@/auth/roles';

export default {
  name: 'UserCreateAccount',
  mixins: [backend_connector, validationMixin],
  components: {
    LoadingIndicator,
    Header,
    Snackbar
  },
  props: {
    isLoading: Boolean,
    userRole: String
  },
  bodyClass: 'index-page',
  data: () => ({
    title: null,
    name: null,
    email: null,
    comment: null,
    submitStatus: null,
    userType: null,
    organizationId: null,
    savingData: false,
  }),
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    comment: {}
  },
  mounted() {
    const accountType = this.$route.params.account_type;
    this.organizationId = this.$route.params.organization_id;
    
    if(this.userRole===Roles.Admin) {
      this.getOrganizationDetails(this.organizationId);
      if (accountType==='account-master') {
        this.title = 'Addition of master account';
        this.userType = Roles.AccountMaster;
      } else if (accountType==='account-user') {
        this.title = 'Addition of user account';
        this.userType = Roles.AccountUser;
      }
    } else if(this.userRole===Roles.AccountMaster) {
      this.title = 'Add collaborator';
      this.userType = Roles.AccountUser;
    }
  },
  computed: {
    ...mapGetters({
      allOrganizations: 'organizations/allOrganizations',
    }),
    ...mapState({
      loading: state => state.assessments.loading,
      user: state => state.user,
      organization: state => state.organizations.organization
    }),
  },
  methods: {
    ...mapActions({
      createAccountAction: 'user/createUserAccount',
      getOrganizationDetails: 'organizations/getOrganizationDetails',
      showSnackBarAction: 'assessments/showSnackBar'
    }),
    isFormValid(){
      this.$v.$touch();
      if(this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        return false;
      } else {
        this.submitStatus = 'PENDING';
        return true;
      }
    },
    createAccount(){
      if (this.isFormValid()) {
        this.savingData = true;
        this.createAccountAction({
          payload: {
            name: this.name,
            role: this.userType,
            email: this.email,
            comment: this.comment === null ? '' : this.comment,
          },
          organizationId: this.organizationId,
          cb: (status, msg) => {
            this.savingData = false;
            if (status) {setTimeout(() => {
              this.showSnackBarAction(msg);
              this.$router.go(-1);
            }, 1000);
            }
          }
        });
      }
    },
  }
};
</script>

<style lang="scss">
.wrapper p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #222;
  text-align: left;
}
.md-theme-default a:not(.md-button).stretched-link {
  color: #009688;
  font-weight: 500;
  padding: 5px;
  &:hover {
    font-weight: 600;
  }
}
.boldText {
  font-weight: 600;
}
</style>
