var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c("confirm-dialogue", {
        ref: "confirmDialogue",
        staticStyle: { "z-index": "100", position: "fixed" }
      }),
      this.assessment.assessment_id
        ? _c(
            "div",
            { staticClass: "main main-raised" },
            [
              _c("loading-indicator", { attrs: { "is-loading": _vm.loading } }),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "v-responsive",
                    { staticClass: "md-alignment-centered" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "md-layout md-gutter md-alignment-center"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-60 md-small-size-100 mx-auto"
                            },
                            [
                              _c("h3", [
                                _vm._v(
                                  " Great! You have successfully created your maturity assessment. "
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout md-gutter md-alignment-center"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-60 md-small-size-100 mx-auto"
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  " To send out the links to the organization doing the maturity assessment you need to acquire their approval to the Terms of Use of the tool. To do so, please enter the customer's contact information below to send an e-mail and request approval to the Terms of Use. Once the customer agrees you can send out the links by copying the URL and/or sending them via e-mail. "
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c("send-terms-of-service", {
                        attrs: {
                          assessment_id: _vm.assessment.assessment_id,
                          terms_of_use_accepted:
                            _vm.assessment.terms_of_use_accepted
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout md-gutter md-alignment-center"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-80 md-small-size-100 mx-auto"
                            },
                            [
                              _c("group-table", {
                                ref: "configGroupTable",
                                attrs: {
                                  sme_version: _vm.sme_version,
                                  assessment_id: this.assessment.assessment_id,
                                  status: this.assessment.status,
                                  areTermsAccepted: this.assessment
                                    .terms_of_use_accepted,
                                  userRole: _vm.userRole
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout md-gutter md-alignment-center"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-size-60 md-small-size-100 mx-auto"
                            },
                            [
                              _c("h3", [_vm._v("Send links via email")]),
                              _c("p", [
                                _vm._v(
                                  "Please enter the email address of your contact person from the organization doing the assessment to send the links per email. A copy will be automatically sent to your address. To just send the reminder to your address leave the field blank."
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "md-small-size-100" },
                                [
                                  _c(
                                    "md-field",
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "(separate multiple email addresses using ',')"
                                        )
                                      ]),
                                      _c("md-input", {
                                        model: {
                                          value: _vm.emailAddresses,
                                          callback: function($$v) {
                                            _vm.emailAddresses = $$v
                                          },
                                          expression: "emailAddresses"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.errorMsg
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v(_vm._s(_vm.errorMsg))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("br"),
                              _c("br"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    color: "success",
                                    disabled: !this.assessment
                                      .terms_of_use_accepted
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendMailWithSurveyLinks()
                                    }
                                  }
                                },
                                [_vm._v("Send access links to me via E-Mail")]
                              ),
                              _c("br"),
                              _c("br")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }