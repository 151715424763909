var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ExpansionPanels",
    { attrs: { title: "Online MAT User Guide (v.2.0)" } },
    [
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v(" 1.0 Maturity Assessment Tool ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("1.1 Introduction")
                    ]),
                    _c(
                      "div",
                      { staticClass: "image-text-frame" },
                      [
                        _c("p", [
                          _vm._v(
                            " This guide introduces you to the new Online appliedAI Maturity Assessment Tool (MAT) developed by appliedAI to assess the level of AI Maturity for companies. We will guide you through the main features, tool details, FAQ, and any recommendations you might need to successfully determine your customer's AI Maturity. "
                          )
                        ]),
                        _c("ImageContainer", {
                          attrs: { src: "guide/ug111.png" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("1.2 Product definition")
                    ]),
                    _c("p", [
                      _vm._v(
                        " To help your customers on their transformational journey to increase their AI Maturity level, appliedAI has developed the Maturity Assessment Tool (MAT). This interactive tool allows your customer to assess their state of AI adoption throughout their AI Journey. The Maturity Assessment Tool helps to determine areas along the AI Journey in which your customer excel and those where they might still need to improve competencies or be unaware. The Maturity Assessment Tool helps your customer identify areas for improvement, build their AI roadmap and introduce AI at scale in the customer's company. "
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v(" 2.0 Main Features/Specifications v 2.0 ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("p", [
                      _c("b", [
                        _vm._v("New since V2.0 (released on 05.02.2024)")
                      ])
                    ]),
                    _c("ul", [
                      _c("li", [_vm._v("Merge groups bug fixed")]),
                      _c("li", [
                        _vm._v(
                          " Updated AF 2.5 productive, which is based on a more elaborate approach to find similar assessments. Also, key findings and updated formatting are now available. "
                        )
                      ]),
                      _c("li", [
                        _vm._v(
                          " Significant speed increase (e.g. reduce download time ca. 500-1000%) optimization "
                        )
                      ]),
                      _c("li", [
                        _vm._v(
                          " New Menu help menu for User Guide 2.0, FAQs and new contact us Mail (MAT_support@appliedai.de) "
                        )
                      ])
                    ]),
                    _c("ImageContainer", {
                      attrs: { src: "guide/ug201.png", width: "200px" }
                    }),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          " New grey-out download button when customers click to avoid overload queries "
                        )
                      ]),
                      _c("li", [
                        _vm._v(
                          " Increased robustness and stability in the tool (download process, front-end improvements, Database and Infrastructure optimization) "
                        )
                      ]),
                      _c("li", [
                        _vm._v(
                          "Other bug fixes related to N+1 queries and report download."
                        )
                      ])
                    ]),
                    _c("br"),
                    _c("p", [
                      _c("b", [
                        _vm._v(
                          " The v 1.7 released on 01.07.23 includes the following minimum features:"
                        )
                      ])
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          " Auth0 infrastructure implementation for security and anonymity of data (no e-mails, customer or company names are stored in the system) "
                        )
                      ]),
                      _c("li", [
                        _vm._v(
                          " (Infrastructure) Implemented role management levels, including "
                        )
                      ]),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "Admin account (appliedAI only) able to create/delete:"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            " Master and User Account (Collaborator). Creation of NH* and CP* roles. Within both levels: "
                          )
                        ]),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              " Master can create/delete User roles per org. with different rights. "
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              " Master can change User names (new function by 1.7 onward) "
                            )
                          ])
                        ])
                      ]),
                      _c("li", [
                        _vm._v(
                          "(NH*, CP* role including Master and user accounts)"
                        )
                      ]),
                      _c("ul", [
                        _c("li", [_vm._v("Register in the online MAT Tool")]),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              " Receive Reset Password Mail (and perform password creation) "
                            )
                          ])
                        ]),
                        _c("li", [_vm._v("Login online to MAT Tool")]),
                        _c("li", [
                          _vm._v(
                            " Generate MATs ( NH, CP role including Master and user accounts) "
                          )
                        ]),
                        _c("ul", [
                          _c("li", [
                            _vm._v("Create MAT new Assessment including:")
                          ]),
                          _c("ul", [
                            _c("li", [
                              _vm._v("Add assessment name (and save)")
                            ]),
                            _c("li", [
                              _vm._v(
                                " Add organization details (for Benchmark purposes) (and save) "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " Confirm if organization details data is accurate (for quality data maintenance in aAI Benchmark) "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " Create a (not limited) group to perform assessments, including: "
                              )
                            ]),
                            _c("ul", [
                              _c("li", [_vm._v("Group name")]),
                              _c("li", [
                                _vm._v(
                                  " Group assessment Survey version (Technical or Non-Technical, refer to section) "
                                )
                              ])
                            ])
                          ]),
                          _c("li", [
                            _vm._v(
                              "Create survey links for partners and allow them to copy"
                            )
                          ]),
                          _c("li", [
                            _vm._v("Manage Assessments management including:")
                          ]),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                " Send a reminder for the survey directly to the partner´s Mail (It sends all group's links) "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " Check the number of assessments per group while running (New UX design from v1.7 onward, including created on/by and details bar together with complete/non-complete) "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " (1.1) Merge assessment groups (Limitation: Not available until v.1.8) "
                              )
                            ]),
                            _c("li", [_vm._v("Closing assessment")]),
                            _c("li", [
                              _vm._v("Terms of Use acceptance feature")
                            ])
                          ])
                        ])
                      ]),
                      _c("li", [_vm._v("Results visualization & download")]),
                      _c("ul", [
                        _c("li", [_vm._v("Online visualization of results")]),
                        _c("li", [
                          _vm._v(
                            " Download raw assessment results as XLS ( v1.7 onward includes Maturity average per dimension and total N/A and per group) "
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            " Download Assessment results (in Google Slides and PowerPoint mode since v1.7) "
                          )
                        ])
                      ]),
                      _c("li", [
                        _vm._v("Benchmark(BM) selection ( from v.1.4 onwards)")
                      ]),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "New UX Accordion Menu selection (v1.7 onward)"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            " Selection of Industry and Size with Anonymity Function (Tool will not plot BM if not enough assessments are selected) "
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            " Information warning in the menu if the Benchmark selection does not complete a minimum of assessment to be benchmarked. "
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            " Download the embedded benchmark slide in slide 26 (or a warning message if not enough assessments were selected during the Benchmark menu. Number of selected assessment are not shown due to privacy function) "
                          )
                        ])
                      ])
                    ]),
                    _c("p", [
                      _vm._v(
                        " If you want to see the full list of specifications, please check with your appliedAI contact. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " *NH=National Host as one type of user CP= Certified Partner as second type of user "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("2.1 Definition of every feature/specs")
                    ]),
                    _c("p", [
                      _vm._v(
                        " The definition of the above specifications is defined through this guide. If you want to see details, click on every feature above to link you to the section. "
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v(" 3.0 Creating MAT Assessments ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("div", [
                  _c("p", [
                    _vm._v(
                      " In the following steps, you will learn to register, log in and create/modify the assessment. "
                    )
                  ])
                ]),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("3.1 Register to MAT")
                    ]),
                    _c("p", [
                      _vm._v(
                        " You must contact your appliedAI representative to receive the invitation to use the tool. Please provide the organization's full name, the country/Area where you will be performing the MAT and the primary contact person mail. Be aware that this Person's Name will be the primary user for the tool. Therefore please consider selecting one of the leading responsible/managers in your company to be the main MAT responsible. You will receive the following mail to confirm your account: "
                      )
                    ]),
                    _c("ImageContainer", {
                      attrs: { src: "guide/ug311.png", width: "300px" }
                    }),
                    _c("p", [_vm._v("Please click on the button")]),
                    _c("ImageContainer", { attrs: { src: "guide/ug312.png" } }),
                    _c("p", [
                      _vm._v(
                        " You will be redirected to the following page to increase for reset your password: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug313.png" } }),
                    _c("p", [
                      _vm._v(
                        " (We use Auth0 to ensure the highest standards of security on our platform. For more detailed information on implemented security measures, contact your appliedAI contact.) "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "Remember your new password should follow these requirements:"
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug314.png" } }),
                    _c("p", [
                      _vm._v(
                        "Enter your new password and re-enter and click on:"
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug315.png" } }),
                    _c("p", [
                      _vm._v(
                        "You will get a confirmation as follows when successful:"
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug316.png" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("3.2 Login to MAT")
                    ]),
                    _c("p", [
                      _vm._v(
                        " The MAT can be accessed through a web-based interface. Please go to the following site in your browser: "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "a",
                        { attrs: { href: "https://mattool.appliedai.de" } },
                        [_vm._v("mattool.appliedai.de")]
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " The login is the mail where you received the password reset mail. The password you just selected in the register action: "
                      )
                    ]),
                    _c("ImageContainer", {
                      attrs: { src: "guide/ug321.png", width: "300px" }
                    }),
                    _c("p", [
                      _vm._v("That will redirect you to the main logged page:")
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug322.png" } }),
                    _c("p", [
                      _vm._v(
                        "Congratulations, you have successfully registered for the MAT!"
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("3.3 Creating a New Assessment")
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Assessment definition:")]),
                      _c("br"),
                      _vm._v(
                        "To perform an assessment, you first need to understand the assessment structure. The assessment is based on a survey, containing 109 questions structured in 10 dimensions. Each assessment can be performed in different groups. Every group can select a full assessment with technical questions (109 items) or a non-technical version (89 items). "
                      )
                    ]),
                    _c("p"),
                    _c("p", [
                      _c("b", [_vm._v("Assessment procedure:")]),
                      _c("br"),
                      _vm._v(
                        "Let's start creating a new assessment by clicking on the button as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug331.png" } }),
                    _c("p", [
                      _vm._v(
                        "You will see that this page has three main sections:"
                      )
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          " Assessment name (to name your evaluation). You can set your own format, including the date and number of assessments already finished for the company (in case the customer will do recurrent assessments through the years, for example Company X, 2024). In any case the data of the assessment name is encrypted at GCP and Database level. "
                        )
                      ])
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug332.png" } }),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          " Assessment details (to include the org information for benchmark purposes) "
                        )
                      ])
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug333.png" } }),
                    _c("p", [
                      _vm._v(
                        " IMPORTANT: If you are unsure of the responses or do not want to share details, you can opt-out by selecting (The data are incorrect). This is important to contribute correctly to the anonymized Benchmark that appliedAI creates with the Database. Please remember to click SAVE ANSWERS. Otherwise, the data will not be saved. Here is the area to select anonymity: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug334.png" } }),
                    _c("ul", [
                      _c("li", [
                        _c("b", [_vm._v("Groups definition")]),
                        _vm._v(
                          " (to define the groups that you want to send the links for the survey) If the company has different departments or business units where you wish to assess maturity separately to understand differentiated perspectives, you can create several groups. Examples for groups: Management, Admin, IT, AI core team, Sales, etc. "
                        )
                      ])
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug335.png" } }),
                    _c("p", [
                      _vm._v(
                        " Please consider there are two kinds of assessment lengths/types: Technical, including 109 questions and non-technical, including only 89 questions: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug336.png" } }),
                    _c("p", [
                      _c("b", [_vm._v("Including technical questions")]),
                      _c("br"),
                      _vm._v(
                        "Select this set of questions for a group that has a more comprehensive knowledge of AI technology, infrastructure, and implementation. "
                      )
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Not including technical questions.")]),
                      _c("br"),
                      _vm._v(
                        " To reduce the number of questions and time, you can send the general MAT question, not including the ones considered more AI technical. In that case, they are only 89. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " The pre-selected kind of question will be shown in blue when the tab is open: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug337.png" } }),
                    _c("p", [
                      _vm._v(
                        " Enter the group name, group description and Survey type and click: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug338.png" } }),
                    _c("p", [
                      _vm._v(
                        " Congratulations, you have created your first group for your assessment: You can always create and add additional groups to your assessment. "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug339.png" } }),
                    _c("p", [_vm._v("By clicking")]),
                    _c("ImageContainer", {
                      attrs: { src: "guide/ug3310.png" }
                    }),
                    _c("p", [_vm._v("You can delete groups created")]),
                    _c("p", [
                      _vm._v(
                        " Continue creating as many groups as you find convenient (no limitations, but if more than 4 are selected the names will be shortened in the presentation´s glossary). "
                      )
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Recommendation:")]),
                      _vm._v(
                        " more than four groups might overlap in the later visualization. Therefore we do not recommend the above four groups in one single Assessment. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Once you are finished with group creation, then click on Start Assessment: "
                      )
                    ]),
                    _c("ImageContainer", {
                      attrs: { src: "guide/ug3311.png" }
                    }),
                    _c("p", [
                      _vm._v(
                        " A warning will appear, as once the assessment and groups assessment after this confirmation, the data cannot be changed as follows: "
                      )
                    ]),
                    _c("ImageContainer", {
                      attrs: { src: "guide/ug3312.png" }
                    }),
                    _c("p", [
                      _vm._v(
                        " Therefore, please consider if any changes are needed (Information, groups, etc.). If nothing needs to be modified, then please click Yes. "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("3.4 Accepting the Terms of Use (ToU)")
                    ]),
                    _c("p", [
                      _vm._v(
                        " On the next screen, to Confirm the Terms of Use (ToU) from the customer side, you need to mail them so they can confirm their acceptance. Please click on the ToU button as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug341.png" } }),
                    _c("p", [
                      _c("b", [_vm._v("Reminder:")]),
                      _vm._v(
                        " The MAT survey COPY buttons and sending via mail are greyed out until the customer receives the mail and clicks accept the Terms of Use. (Refreshing the page after ToU has been accepted will show green buttons). See the following steps for more details. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Please introduce the e-mail address of your contact person from the company (the company to be assessed) that will perform the MAT as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug342.png" } }),
                    _c("p", [
                      _vm._v(
                        " After entering and clicking send, your customer should receive the following mail: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug343.png" } }),
                    _c("p", [_vm._v("The link above leads to this page")]),
                    _c("ImageContainer", { attrs: { src: "guide/ug344.png" } }),
                    _c("p", [
                      _vm._v(
                        " Please ensure that the customer is aware of this mail and that they confirm the ToU. Once this is established from the customer side, they should receive a link confirmation. "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug345.png" } }),
                    _c("p", [
                      _vm._v(
                        " If your customer confirms the Terms of Use during the process and you refresh the site and go to Configure assessment, you will be able to see the Collector URL activated as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug346.png" } }),
                    _c("p", [
                      _vm._v(
                        " By clicking on Copy, you will see a notification that confirms the link was Copy to your clipboard as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug347.png" } }),
                    _c("p", [
                      _vm._v(
                        " Once you have the link, you can use it for your mail notification to customers. Remember, every group is different. "
                      )
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("NOTE:")]),
                      _vm._v(
                        " Copying the link (Collector URL) and inputting it into your browser allows you to start on the MAT immediately "
                      )
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Recommendation:")]),
                      _vm._v(
                        " If you want to send all assessment URL at once you can use always use the function “send reminder” (see section 4.1) in the main assessment page. "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("3.5 Account Settings")
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug351.png" } }),
                    _c("p", [
                      _vm._v(
                        " By clicking the “HELP” button, your email will be automatically opened to a draft email to our MAT support address. You can write directly there if you have any issues or questions with the MAT tool. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " By clicking the “MY ACCOUNT” button, you can see the different settings for your account and change of the downloaded ppt format as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug352.png" } }),
                    _c("p", [
                      _vm._v(
                        " The “Download Report slides” allows you to optimize the format of the downloaded PowerPoint. If you target to open the slides with Microsoft PowerPoint, please select PowerPoint. If you plan to upload the downloaded slides to Google Drive, then select the Google slides option. In case the visualizations in the generated report are plotted wrongly, you might need to adjust the file format as stated above. "
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v(" 4.0 Managing Assessment ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("div", [
                  _c("p", [
                    _vm._v(
                      " Features such as sending reminders, configuring or closing the Maturity Assessment. "
                    )
                  ])
                ]),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("4.1 Sending reminder")
                    ]),
                    _c("p", [
                      _vm._v(
                        " New online MAT allows you to send reminders, including the link per group. Please remember the mail will include all groups, so the user must be aware of which group she/she/she/it belongs to. You can find the reminder option in the preliminary assessment list as follow: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug411.png" } }),
                    _c("p", [
                      _vm._v(
                        " To start sending reminders per Assessment, please click on the following: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug412.png" } }),
                    _c("p", [
                      _vm._v(
                        " It will ask you to include the mail you want to send the reminder to as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug413.png" } }),
                    _c("p", [
                      _vm._v(
                        " Please enter the mail you want to send the reminder mail to and click on SEND THE LINKS VIA E-MAIL. The system will send the mail as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug414.png" } }),
                    _c("p", [
                      _c("b", [_vm._v("Limitation 1:")]),
                      _vm._v(" Available in English and German")
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Limitation 2:")]),
                      _vm._v(
                        " Send reminder include all groups, so secure to highlight to the surveyed person to which group they belong. "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("4.2 Configure Assessment")
                    ]),
                    _c("p", [
                      _vm._v(
                        " You can configure specific assessments on the pipeline in the primary assessment list window. "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug421.png" } }),
                    _c("p", [
                      _vm._v(
                        " By clicking on Configure, you can see the selected groups to copy separate links as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug422.png" } }),
                    _c("p", [
                      _c("b", [_vm._v("Warning:")]),
                      _vm._v(
                        " You cannot add new groups once the Assessment is created. "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("4.3 Closing assessment")
                    ]),
                    _c("p", [
                      _vm._v(
                        "To be able to close the assessments, the following can be done:"
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug431.png" } }),
                    _c("p", [
                      _vm._v(
                        " Please remember that once you complete the evaluation, you cannot include groups/evaluation surveys/etc. Therefore seal the review ONLY when you are sure all surveys have been filled for the organization members. A warning will appear: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug432.png" } }),
                    _c("p", [
                      _vm._v(
                        " Recalling the database and showing the MAT has been calculated might take some seconds (10-15 sec) "
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v("5.0 Assessment Results")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("5.1 Opening results:")
                    ]),
                    _c("p", [
                      _vm._v(
                        " Once the assessment is closed, you might see a change in the Assessment Results as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug511.png" } }),
                    _c("p", [
                      _vm._v(
                        "By clicking on RESULTS, you will see the tracking data:"
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug512.png" } }),
                    _c("p", [
                      _vm._v(
                        " By every dimension (and clicking in Detailed analysis), you will be able to visualize the results as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug513.png" } }),
                    _c("p", [
                      _vm._v(
                        " (Limitation: This information can be copied/pasted for your final customer report) "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Not only will you be able to view the results by every dimension, but you will also get a Maturity level explanation and some resources recommendations by scrolling down: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug514.png" } }),
                    _c("p", [
                      _vm._v(
                        " The recommendations are just a general guide, and we always recommend creating tailored Key findings/recommendations for your custom reports. National Hosts and Certified Partners should be able to develop these recommendations based on Report Results from the different groups surveyed. "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug515.png" } }),
                    _c("p", [
                      _vm._v(
                        "And also appliedAI further support to keep growing:"
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug516.png" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("5.2 Downloading report slides:")
                    ]),
                    _c("p", [
                      _vm._v(
                        "You can download the report slides with the below button:"
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug521.png" } }),
                    _c("p", [
                      _vm._v(
                        " You can also go into your account settings and change how the presentation should be optimized (either for Google Slides or Microsoft PowerPoint). Also, consider selecting the Benchmark you prefer to have in your report (See Section 5.3) "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " After choosing your preferred method of delivery, you can download the summary results. Here is an example: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug522.png" } }),
                    _c("ImageContainer", { attrs: { src: "guide/ug523.png" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("5.3 Selecting benchmark:")
                    ]),
                    _c("p", [
                      _vm._v(
                        " In order to select the Benchmark, you can filter out by industry and size in the Benchmark Menu: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug531.png" } }),
                    _c("p", [
                      _vm._v(
                        " By default, the size and Industry of the initial assessment information are pre-filtered (shown in blue) when you open the size/industry drop-down menu as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug532.png" } }),
                    _c("p", [
                      _vm._v(
                        " You can select the one you want to include in your Benchmark comparison. The apply button will not be selectable until you change the settings: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug533.png" } }),
                    _c("p", [
                      _vm._v(
                        " If your selection includes too few assessments a warning will be shown as follows: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug534.png" } }),
                    _c("p", [
                      _vm._v(
                        " If you download the slides after getting this message, no Benchmark will be plotted for anonymity reasons. To correct this you can change your selection in the Benchmark menu in order to get this message: "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug535.png" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("5.4 Merging Groups:")
                    ]),
                    _c("p", [
                      _vm._v(
                        " If some of the assessed groups did not collect sufficient answers to guarantee anonymity (n<5), groups with the same set of questions can be merged. This will collect answers from several groups and display them as the answers of one group in the report, to assure anonymity. To achieve this, one simply has to go to the group to be merged (can be seen in the menu), and select the group(s) to merge into in the drop-down menu next to the group. "
                      )
                    ]),
                    _c("p", [
                      _c("b", [
                        _vm._v(
                          "Note: Merging is IRREVERSIBLE, therefore proceed with caution!"
                        )
                      ])
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug541.png" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("5.5 Autofindings:")
                    ]),
                    _c("p", [
                      _vm._v(
                        " The feature “Autofindings” which has been newly implemented in the Maturity Assessment Tool, uses maturity assessments from past projects, to automatically generate findings and recommendations for incoming assessments. This is based on the highest similarity between the new assessment and all the past assessments, selecting the observations and recommendations from the most similar past assessment for the incoming assessment. This significantly reduces the time spent analyzing the maturity of companies internally and helps onboarding of new colleagues into the assessment process, as a first set of observations and recommendations is already provided. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " To use the automatic generation of observations and recommendations, select the “Autofindings included” button. This will populate the result slides with fitting observations and recommendations. Make sure to thoroughly double-check and adjust these observations and recommendations before using them for clients. "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug551.png" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("Heading", { attrs: { variant: "h4" } }, [
                      _vm._v("5.6 Downloading summary data:")
                    ]),
                    _c("p", [
                      _vm._v(
                        " Following up on the industry benchmark, in case you’d like to do your own manual searching/operations on the data, you are able to download the summary file as an Excel document. "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug561.png" } }),
                    _c("p", [
                      _vm._v(
                        " This produces an Excel document that documents the scores for each section, the total average for each question and then the score for each question by the user. "
                      )
                    ]),
                    _c("ImageContainer", { attrs: { src: "guide/ug562.png" } }),
                    _c("p", [
                      _vm._v(
                        " In some cases, customers request raw data to see detailed insight of the report (Number of un-answered items, when a participant stopped answering, dedicated maturity levels. In case you have any other questions, do not hesitate to contact your partner at appliedAI or reach us at "
                      )
                    ]),
                    _c("p", [
                      _c(
                        "a",
                        { attrs: { href: "mailto:MAT_support@appliedai.de" } },
                        [_vm._v("MAT_support@appliedai.de")]
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " V2.0 User guide for mattool.appliedai.de, February 2024. appliedAI all rights reserved. "
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }