var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ExpansionPanels",
    { attrs: { title: "Frequently Asked Questions" } },
    [
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _vm._v(" 1. Presentation elements are not displayed correctly ")
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    " Is that the problem are you experiencing in the following snapshots? "
                  )
                ]),
                _c("ImageContainer", { attrs: { src: "faq/faq11.png" } }),
                _c("p", [_vm._v("(page 13-25 of the downloaded report)")]),
                _c("ImageContainer", { attrs: { src: "faq/faq12.png" } }),
                _c("p", [_vm._v("(page 26 of the downloaded report)")]),
                _c("p", [
                  _vm._v(
                    " In that case is not a software problem, but a settings item. MAT tool has a feature to select the output compatibility of the report (the options are compatible with Google Slides or PowerPoint), please go to my account, and select the right output format you will need (depending on which Software you will intend to use to open the .pptx file) as the menu here: "
                  )
                ]),
                _c("ImageContainer", { attrs: { src: "faq/faq13.png" } }),
                _c("ImageContainer", { attrs: { src: "faq/faq14.png" } })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v(" 2. Problems with the Download of the report ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _c("b", [_vm._v("Speed in download:")]),
                  _vm._v(
                    " The new Download includes not only the report generation plotting but the aggregation of all answers, the calculation, and the Auto findings calculation and generation. Therefore depending on the Report setup, it could take some seconds until the file is downloaded. We have implemented a gray-out button activation (when clicking download) in order not to affect the current download or to increase the queue. Therefore please wait until the Report is downloaded. In any case, the speed of report download has increased from version 1.7.0 -> 2.0.0 to over 250% "
                  )
                ]),
                _c("p", [
                  _c("b", [_vm._v("The report file is not downloading:")]),
                  _vm._v(
                    " In some rare cases, we have experienced some reports not downloading. This was related to server connections and time-out before version 1.7.0. This has been corrected in version 2.0.0. In any case, if you experience this download issue, most of the time a second download action might solve the issue. If you keep experiencing this anyway, please contact second-level support using our HELP -> Contact us and feel the template or write us an E-mail (MAT_support@appliedai.de). appliedAI has the option to extract your report and generate for you. "
                  )
                ]),
                _c("ImageContainer", { attrs: { src: "faq/faq21.png" } })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v("3. Can I reopen a closed assessment?")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    " No. Once you close the assessment there is no option to change organizational settings, add groups, or re-open the assessment. "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v("4. Can I unmerge groups?")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    " No. To ensure the MAT Tool database is using your last merge selection, MAT tool does not allow you to save different merging settings. Therefore please download the original unmerged slides selection if you want to keep the original unmerged report, as you will be not able to download it after you select a specific merge. "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _vm._v(
                  "5. I have no Benchmark downloaded in my PowerPoint report. "
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    "If you have this text on page 26 of your downloaded report:"
                  )
                ]),
                _c("ImageContainer", { attrs: { src: "faq/faq51.png" } }),
                _c("p", [
                  _vm._v(
                    " This is because either you did not select anything in the Benchmark menu, or your selection did not enough assessment as the warning might have shown you as follows: "
                  )
                ]),
                _c("ImageContainer", { attrs: { src: "faq/faq52.png" } }),
                _c("p", [
                  _vm._v(
                    " In that case, please change the Benchmark selection in order to get this message: "
                  )
                ]),
                _c("ImageContainer", { attrs: { src: "faq/faq53.png" } }),
                _c("p", [
                  _vm._v(
                    " Your benchmark information will be then downloaded in your report. "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Panel", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v("6. I cannot log in to the MAT tool.")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    " Please use the reset option in the login or contact MAT_support@appliedai.de "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }