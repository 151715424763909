var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-legend" },
    _vm._l(_vm.groupNames, function(item) {
      return _c("div", { key: item, staticClass: "legend-row" }, [
        item === "all_participants"
          ? _c("div", [
              _c("span", {
                staticClass: "legend-icon square",
                staticStyle: { "background-color": "#808080", opacity: "0.5" }
              }),
              _c("span", {
                staticClass: "legend-icon square",
                staticStyle: { "background-color": "#008000", opacity: "0.5" }
              }),
              _c("label", [_vm._v("Total")])
            ])
          : _c("div", [
              _c("span", {
                staticClass: "legend-icon dot",
                style: { "background-color": _vm.color(item) }
              }),
              _c("label", [_vm._v(_vm._s(item))])
            ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }