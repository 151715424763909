<template>
  <div>
    <h2 class="text-center">AI maturity across dimensions</h2>
    <h4>Below you find your maturity score for different dimensions that are being assessed by the AI maturity tool. To access the detailed insights into each of the dimensions use the respective buttons on the tiles below.</h4>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-large-size-50" v-if="maturityData.length>0">
        <v-simple-table class="simple-table">
          <template v-slot:default>
            <thead class="hidden-md-and-down">
              <tr>
                <th></th>
                <th><div class="green-cell header-text">Current Level</div></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in maturityData.slice(0, 5)" :key="item.name">
                <td class="green-cell add-opacity">{{ item.name }}</td>
                <td>
                  <div class="borderedFlexContainer">
                    <div class="fillWidth hidden-md-and-down">
                      <loading-indicator v-if="isLoading && !powerPointDownloadLoading" :is-loading="isLoading"/>
                      <horizontal-arrow-bar-chart v-if="!isLoading || powerPointDownloadLoading" :matScoreValue="item.maturity_score" :selectorId="item.position"/>
                    </div>
                    <div class="right-square green-cell">
                      {{ Math.floor(item.maturity_score) }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="md-layout-item md-medium-size-100 md-large-size-50" v-if="maturityData.length>0">
        <v-simple-table class="simple-table">
          <template v-slot:default>
            <thead class="hidden-md-and-down">
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in maturityData.slice(5, 10)" :key="item.name">
                <td class="green-cell add-opacity">{{ item.name }}</td>
                <td>
                  <div class="borderedFlexContainer">
                  <div class="fillWidth hidden-md-and-down">
                    <loading-indicator v-if="isLoading && !powerPointDownloadLoading" :is-loading="isLoading" />
                    <horizontal-arrow-bar-chart v-if="!isLoading || powerPointDownloadLoading" :matScoreValue="item.maturity_score" :selectorId="item.position"/>
                  </div>
                  <div class="right-square green-cell">
                    {{ Math.floor(item.maturity_score) }}
                  </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <v-progress-circular indeterminate color="primary lighten-2" v-if="maturityDataLoading" />
    <div class="md-layout md-alignment-top-center" v-if="!isLoading || powerPointDownloadLoading">
        <div v-for="{id, name, maturity_score} in maturityData" :key="id" class="md-layout-item md-medium-size-100 md-large-size-33">
          <md-card md-with-hover class="modified-cards">
            <h3>{{ name }}</h3>
            <h4>Level {{ Math.floor(maturity_score) }} / 4</h4>
            <md-card-content>
              <div class="md-card-content">
                <maturity-pie-chart v-bind:name="name? name.replace(/\s+/g, '') : ''" v-bind:score="maturity_score" />
                <p v-if="maturityDataLoading" class="data-fetching-error">Chart could not be loaded.</p>
                <br />
                <v-btn color="primary" @click="goToDetailViewForClusterID(id, maturity_score)">Detailed Analysis</v-btn>
              </div>
            </md-card-content>
          </md-card>
        </div>
    </div>
    <div class="md-layout" v-if="!isLoading || powerPointDownloadLoading">
      <div class="md-layout-item">
        <h3>You are interested in discussing your assessment results with experts?</h3>
        <p>We offer in depth-workshops, that help you to build your strategic AI roadmap based on the results of your maturity assessment and exchange with leading AI experts on best practices and approaches. Just drop us a message via the button below!</p>
        <a :href="'mailto:info@appliedai.de?subject=Please%20get%20in%20touch%20with%20me%20regarding%20my%20maturity%20assessment%20results&body=Add%20a%20message%20here%20or%20simply%20hit%20send.%20We%20will%20get%20back%20to%20you!'">
          <md-button>Get in touch</md-button>
        </a>
      </div>
    </div>
</div>
</template>

<script>
import {
  LoadingIndicator,
  MaturityPieChart,
  HorizontalArrowBarChart
} from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'AiAcrossDimensions',
  components: {
    LoadingIndicator,
    MaturityPieChart,
    HorizontalArrowBarChart
  },
  props: {
    isLoading: {
      type: Boolean,
    },
    powerPointDownloadLoading: {
      type: Boolean,
    },
    assessment_id: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      maturityData: state => state.assessments.maturityData,
      maturityDataLoading: state => state.assessments.maturityDataLoading,
    }),
  },
  methods: {
    goToDetailViewForClusterID(cluster_id) {
      this.$router.push({
        name: 'clusterdetails',
        params: {
          assessment_id: this.assessment_id,
          cluster_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card {
  margin: 20px 0px;
  width: 100%;
  padding: 20px 0px;
  display: inline-block;
  vertical-align: center;
}
@media (max-width: 1279px) { 
  .md-card {
    margin: 20px 0;
    width: 100%;
    padding: 0;
  }
}
.v-data-table.simple-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table.simple-table>.v-data-table__wrapper>table>thead>tr>th {
  border-bottom: none !important;
  border-top: white solid 5px;
  border-bottom: white solid 5px;
  text-align: center;
  padding: 0;
  .borderedFlexContainer {
    border: #00000038 solid 1px;
    display: flex;
    height: 45px;
    min-width: 300px;
    .right-square {
      width: 41px;
      background-color: #00747a;
      float: right;
      right: 0px;
      padding: 10px;
    }
    @media (max-width: 1279px) { 
      .right-square {
        width: 100%;
      }
    }
    .fillWidth {
      width: 100%;
    }
  }
  &.green-cell, .green-cell {
    background-color: #00747a;
    color: white;
    font-weight: 500;
    padding: 0 40px;
    &.add-opacity {
      opacity: 0.7; 
      max-width: 210px;
    }
    &.header-text {
      background-color: #00747a;
      font-size: 22px;
      color: white;
      font-weight: 500;
    }
  }
}
</style>
