var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c("div", { staticClass: "main main-raised" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("v-responsive", { staticClass: "md-alignment-centered" }, [
              _c("div", { staticClass: "text-center" }, [
                _c("h2", [_vm._v("Ooops")])
              ]),
              _c("div", { staticClass: "md-layout text-justify" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-size-50 md-small-size-100 mx-auto"
                  },
                  [
                    _c("p", [
                      _vm._v(
                        " It seems that you don't have the right permissions to access this page "
                      )
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", plain: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.go(-1)
                          }
                        }
                      },
                      [_vm._v("← Back ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }