<template>
  <div class="wrapper">
    <Header />
    <confirm-dialogue ref="confirmDialogue" style="z-index: 100; position: fixed"></confirm-dialogue>
    <div class="main main-raised" v-if="this.assessment.assessment_id">
      <loading-indicator :is-loading="loading" />
      <div class="container">
        <v-responsive class="md-alignment-centered">
          <div class="md-layout md-gutter md-alignment-center">
            <div class="md-layout-item md-size-60 md-small-size-100 mx-auto">
              <h3>
                Great! You have successfully created your maturity assessment.
              </h3>
            </div>
          </div>
          <div class="md-layout md-gutter md-alignment-center">
            <div class="md-layout-item md-size-60 md-small-size-100 mx-auto">
              <p>
                To send out the links to the organization doing the maturity assessment you need to acquire their approval to the Terms of Use of the tool. To do so, please enter the customer's contact information below to send an e-mail and request approval to the Terms of Use. Once the customer agrees you can send out the links by copying the URL and/or sending them via e-mail.
              </p>
            </div>
          </div>
          <send-terms-of-service :assessment_id="assessment.assessment_id" :terms_of_use_accepted="assessment.terms_of_use_accepted"/>
          <div class="md-layout md-gutter md-alignment-center">
            <div class="md-layout-item md-size-80 md-small-size-100 mx-auto">
              <group-table
                ref="configGroupTable"
                :sme_version="sme_version"
                :assessment_id="this.assessment.assessment_id"
                :status="this.assessment.status"
                :areTermsAccepted="this.assessment.terms_of_use_accepted"
                :userRole="userRole"
              />
            </div>
          </div>
          <div class="md-layout md-gutter md-alignment-center">
            <div class="md-layout-item md-size-60 md-small-size-100 mx-auto">
              <h3>Send links via email</h3>
              <p>Please enter the email address of your contact person from the organization doing the assessment to send the links per email. A copy will be automatically sent to your address. To just send the reminder to your address leave the field blank.</p>
              <div class="md-small-size-100">
                <md-field>
                    <label>(separate multiple email addresses using ',')</label>
                    <md-input v-model="emailAddresses"></md-input>
                </md-field>
                <span v-if="errorMsg" style="color: red">{{ errorMsg }}</span>
              </div>
              <br />
              <br />
              <v-btn large color="success" @click="sendMailWithSurveyLinks()" :disabled="!this.assessment.terms_of_use_accepted">Send access links to me via E-Mail</v-btn>
              <br />
              <br />
            </div>
          </div>
        </v-responsive>
      </div>
    </div>
    <snackbar />
  </div>
</template>

<script>
import { LoadingIndicator, GroupTable, ConfirmDialogue, Header, Snackbar, SendTermsOfService } from '@/components';
import backend_connector from '../mixins/backend_connector';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'successfulCreation',
  mixins: [backend_connector],
  components: {
    Header,
    GroupTable,
    ConfirmDialogue,
    LoadingIndicator,
    Snackbar,
    SendTermsOfService
  },
  bodyClass: 'index-page',
  props: {
    isLoading: Boolean,
    userRole: String
  },
  data: () => ({
    sme_version: null,
    emailAddresses: null,
    errorMsg: null,
  }),
  computed: {
    ...mapState({
      assessment: state => state.assessments.assessment,
      loading: state => state.assessments.loading,
      groups: state => state.assessments.groups,
    }),
  },
  created() {
    this.getAssessment(this.$route.params.assessment_id);
  },
  methods: {
    ...mapActions({
      getAssessment: 'assessments/getAssessment',
      sendMailWithSurveyLinksAction: 'assessments/sendMailWithSurveyLinks',
      hideSnackBarAction: 'assessments/hideSnackBar',
    }),
    hideSnackbar(){
      this.hideSnackBarAction();
    },
    showDialog(title, message, okText, cancelText, showEmailInputPart){
      return this.$refs.confirmDialogue
        .show({
          title,
          message,
          okButton: okText,
          cancelButton: cancelText,
          showEmailInputPart,
        })
        .then();
    },
    validateEmail(value){
      if(value){
        let emailsArray = value.split(',');
        let isValid = true;
        emailsArray.forEach(email => {
        //eslint-disable-next-line
         if (!email || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.replace(/\s/g,''))) {
            isValid = true;
          } else {
            isValid = false;
          }
        });
        return isValid;
      }
      return true;
    },
    async sendMailWithSurveyLinks() {
      this.hideSnackBarAction();
      let isValid = this.validateEmail(this.emailAddresses);
      if (isValid) {
        this.errorMsg = null;
        this.sendMailWithSurveyLinksAction({ 
          assessment_id: this.assessment.assessment_id, 
          recipients: this.emailAddresses,
          is_reminder: false, 
          cb: this.showDialog });
        this.$router.push({ name: 'index' });
      } else {
        this.errorMsg = 'The email address(es) that you provided is (are) invalid';
        throw new Error('The email address(es) that you provided is (are) invalid');
      }
    },
  },
};
</script>
<style lang="scss">
.section-download {
  .v-btn + .v-btn {
    margin-left: 5px;
  }
}
@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
.main-button-bottom {
  margin-top: 30px;
}
.md-field {
  max-width: 300px;
}
ul {
  margin: 0 0 10px;
  font-size: 14px;
}
</style>
