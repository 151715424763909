
  
import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import user from './modules/user';
import assessments from './modules/assessments/';
import organizations from './modules/organizations';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    main: {
      state: {
        packageVersion: process.env.PACKAGE_VERSION
      }
    },
    assessments,
    user,
    organizations,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});

export default store;