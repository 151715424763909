<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-transparent md-absolute"
    :class="extraNavClasses"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <div id="white-box" class="white-box">
          <router-link to="/">
            <img
              id="logo_img"
              class="logo_img"
              src="../assets/img/aai_logo_CMYK_turquoise_black.svg"
              alt="appliedAI logo"
              width="200px"
            />
          </router-link>
        </div>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>
        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section-start="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <md-menu
                md-size="medium"
                md-align-trigger
                :md-active.sync="menuOpened"
              >
                <md-list-item @mouseover="open" @mouseleave="close">
                  <a>
                    <i class="material-icons">help</i>
                    <p>Help</p>
                  </a>
                </md-list-item>
                <div class="wrapper">
                  <md-menu-content class="menu-list">
                    <md-menu-item @mouseover="open" @mouseleave="close"
                      ><router-link to="/user-guide"
                      >
                        <i class="material-icons">local_library</i>
                        <p>User guide</p>
                    </router-link></md-menu-item
                    >
                    <md-menu-item @mouseover="open" @mouseleave="close"
                      ><router-link to="/faq">
                        <i class="material-icons">quiz</i>
                        <p>FAQ</p>
                      </router-link></md-menu-item
                    >
                    <md-menu-item @mouseover="open" @mouseleave="close"
                      ><a target="_blank" :href="emailHref">
                        <i class="material-icons">email</i>
                        <p>Contact us</p>
                      </a></md-menu-item
                    >
                  </md-menu-content>
                </div>
              </md-menu>
              <md-list-item v-if="!isAuthenticated" @click="$emit('doLogin')">
                <a>
                  <i class="material-icons">account_circle</i>
                  <p>Login</p>
                </a>
              </md-list-item>
              <md-list-item
                v-if="isAuthenticated && userRole !== Roles.Admin"
                @click="$emit('goToProfile')"
              >
                <a>
                  <i class="material-icons">account_circle</i>
                  <p>My account</p>
                </a>
              </md-list-item>
              <md-list-item v-if="isAuthenticated" @click="$emit('doLogout')">
                <a>
                  <i class="material-icons">account_circle</i>
                  <p>Logout</p>
                </a>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import MobileMenu from '@/layout/MobileMenu';
import { Roles } from '@/auth/roles';

let resizeTimeout;

function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

export default {
  components: {
    MobileMenu,
  },
  props: {
    type: {
      type: String,
      default: 'white',
      validator(value) {
        return [
          'white',
          'default',
          'primary',
          'danger',
          'success',
          'warning',
          'info',
        ].includes(value);
      },
    },
    authenticated: Boolean,
    userRole: String,
  },
  data() {
    return {
      extraNavClasses: '',
      toggledClass: false,
      menuOpened: false,
      timer: null,
    };
  },
  computed: {
    isAuthenticated: function () {
      return this.authenticated;
    },
    Roles: function () {
      return Roles;
    },
    emailHref() {
      const url = location.href;
      const userAgent = navigator.userAgent;
      const currentDate = new Date().toGMTString();
      const form =
        'Please%20fill%20out%20below%20data%0A%0AEmail%20address%20you%20used%20to%20login%3A%0ACompany%20name%3A%0AAssessment%20ID%20%28see%20the%20last%20number%20in%20the%20URL%29%3A%0ATime%20and%20date%20when%20you%20experienced%20the%20issue%3A%0AProblem%20description%3A%0A%0AFeel%20free%20to%20include%20snapshots%20or%20visual%20information%20about%20the%20problem.%20That%20will%20help%20our%20team%20to%20assess%20the%20issue%20faster';
      return `mailto:mat_support@appliedai.de?subject=Question%20regarding%20Maturity%20Assessment&body=${form}%0A------------%0APlease%20put%20your%20message%20above%20this%20line%0A%0AUser-Agent%3A%20${userAgent}%0AURL%3A%20${url}%0ATime%3A%20${currentDate}`;
    },
  },
  mounted() {
    document.addEventListener('scroll', this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    bodyClick() {
      const bodyClick = document.getElementById('bodyClick');
      if (bodyClick === null) {
        const body = document.querySelector('body');
        const elem = document.createElement('div');
        elem.setAttribute('id', 'bodyClick');
        body.appendChild(elem);
        const bodyClick = document.getElementById('bodyClick');
        bodyClick.addEventListener('click', this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      const scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      const navbarColor = document.getElementById('toolbar');
      const logo_img = document.getElementById('logo_img');
      const white_box = document.getElementById('white-box');
      const headerElement = document.getElementsByClassName('page-header')[0];
      if (headerElement && scrollValue * 2 + 60 > headerElement.clientHeight) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove('md-transparent');
        white_box.classList.remove('white-box');
        logo_img.classList.remove('logo_img');
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = '';
          navbarColor.classList.add('md-transparent');
          white_box.classList.add('white-box');
          logo_img.classList.add('logo_img');
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    open() {
      clearTimeout(this.timer);
      this.menuOpened = true;
    },
    close() {
      this.timer = setTimeout(() => {
        this.menuOpened = false;
      }, 300);
    },
  },
};
</script>
<style scoped>
.white-box {
  background-color: rgba(255, 255, 255, 0.9);
  width: fit-content;
  height: fit-content;
  border: 1px solid white;
  border-radius: 2px;
  position: relative;
  top: -30px;
}
.logo_img {
  padding-top: 30px;
}

#toolbar {
  z-index: 10;
}

.menu-list {
  z-index: 11;
}
</style>

<style lang="scss">
ul.md-list.md-theme-default {
  padding: 0;
}
li.md-list-item.md-menu-item.md-theme-default {
  margin-bottom: 0px !important;
}
</style>
