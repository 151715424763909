var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        { attrs: { color: "primary" }, on: { click: _vm.toggleGroupsTable } },
        [
          _c("v-icon", [
            _vm._v(
              _vm._s(
                _vm.showGroupTable ? "mdi-arrow-collapse" : "mdi-arrow-expand"
              )
            )
          ]),
          _vm._v(
            "  " +
              _vm._s(
                _vm.showGroupTable ? "Hide groups table" : "Show groups table"
              ) +
              " "
          )
        ],
        1
      ),
      _vm.showGroupTable
        ? _c("group-table", {
            ref: "configGroupTable",
            attrs: {
              sme_version: _vm.sme_version,
              assessment_id: this.assessment.assessment_id,
              status: this.assessment.status,
              areTermsAccepted: this.assessment.terms_of_use_accepted,
              userRole: _vm.userRole
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }