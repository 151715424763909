var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-toolbar",
    {
      staticClass: "md-transparent md-absolute",
      class: _vm.extraNavClasses,
      attrs: { id: "toolbar", "md-elevation": "0" }
    },
    [
      _c("div", { staticClass: "md-toolbar-row md-collapse-lateral" }, [
        _c("div", { staticClass: "md-toolbar-section-start" }, [
          _c(
            "div",
            { staticClass: "white-box", attrs: { id: "white-box" } },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c("img", {
                  staticClass: "logo_img",
                  attrs: {
                    id: "logo_img",
                    src: require("../assets/img/aai_logo_CMYK_turquoise_black.svg"),
                    alt: "appliedAI logo",
                    width: "200px"
                  }
                })
              ])
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "md-toolbar-section-end" },
          [
            _c(
              "md-button",
              {
                staticClass: "md-just-icon md-simple md-toolbar-toggle",
                class: { toggled: _vm.toggledClass },
                on: {
                  click: function($event) {
                    return _vm.toggleNavbarMobile()
                  }
                }
              },
              [
                _c("span", { staticClass: "icon-bar" }),
                _c("span", { staticClass: "icon-bar" }),
                _c("span", { staticClass: "icon-bar" })
              ]
            ),
            _c("div", { staticClass: "md-collapse" }, [
              _c(
                "div",
                { staticClass: "md-collapse-wrapper" },
                [
                  _c("mobile-menu", {
                    attrs: { "nav-mobile-section-start": "false" }
                  }),
                  _c(
                    "md-list",
                    [
                      _c(
                        "md-menu",
                        {
                          attrs: {
                            "md-size": "medium",
                            "md-align-trigger": "",
                            "md-active": _vm.menuOpened
                          },
                          on: {
                            "update:mdActive": function($event) {
                              _vm.menuOpened = $event
                            },
                            "update:md-active": function($event) {
                              _vm.menuOpened = $event
                            }
                          }
                        },
                        [
                          _c(
                            "md-list-item",
                            {
                              on: { mouseover: _vm.open, mouseleave: _vm.close }
                            },
                            [
                              _c("a", [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("help")
                                ]),
                                _c("p", [_vm._v("Help")])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "wrapper" },
                            [
                              _c(
                                "md-menu-content",
                                { staticClass: "menu-list" },
                                [
                                  _c(
                                    "md-menu-item",
                                    {
                                      on: {
                                        mouseover: _vm.open,
                                        mouseleave: _vm.close
                                      }
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        { attrs: { to: "/user-guide" } },
                                        [
                                          _c(
                                            "i",
                                            { staticClass: "material-icons" },
                                            [_vm._v("local_library")]
                                          ),
                                          _c("p", [_vm._v("User guide")])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-menu-item",
                                    {
                                      on: {
                                        mouseover: _vm.open,
                                        mouseleave: _vm.close
                                      }
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        { attrs: { to: "/faq" } },
                                        [
                                          _c(
                                            "i",
                                            { staticClass: "material-icons" },
                                            [_vm._v("quiz")]
                                          ),
                                          _c("p", [_vm._v("FAQ")])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-menu-item",
                                    {
                                      on: {
                                        mouseover: _vm.open,
                                        mouseleave: _vm.close
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            href: _vm.emailHref
                                          }
                                        },
                                        [
                                          _c(
                                            "i",
                                            { staticClass: "material-icons" },
                                            [_vm._v("email")]
                                          ),
                                          _c("p", [_vm._v("Contact us")])
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.isAuthenticated
                        ? _c(
                            "md-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("doLogin")
                                }
                              }
                            },
                            [
                              _c("a", [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("account_circle")
                                ]),
                                _c("p", [_vm._v("Login")])
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.isAuthenticated && _vm.userRole !== _vm.Roles.Admin
                        ? _c(
                            "md-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("goToProfile")
                                }
                              }
                            },
                            [
                              _c("a", [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("account_circle")
                                ]),
                                _c("p", [_vm._v("My account")])
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.isAuthenticated
                        ? _c(
                            "md-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("doLogout")
                                }
                              }
                            },
                            [
                              _c("a", [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("account_circle")
                                ]),
                                _c("p", [_vm._v("Logout")])
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }