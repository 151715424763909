<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
        <slot name="header"></slot>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="panel-content">
        <slot name="content"></slot>
      </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>

export default {
  name: 'Panel',
};
</script>
<style scoped>
.panel-content {
  padding-top: 26px;
}

.v-expansion-panel-header--active {
  background-color: #00747b;
  color: #fff !important;
  font-weight: 500;
}

.v-expansion-panel-header--active >>> .mdi-chevron-down {
  color: #fff !important;
}
</style>
