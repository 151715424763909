<template>
   <v-snackbar v-model="snackbar" :timeout="3000" >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="hideSnackBarAction">
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Snackbar',
  computed: {
    ...mapState({
      snackbarText: state => state.assessments.snackbarText,
      showSnackbar:state => state.assessments.showSnackbar,
    }),
    snackbar: {
      get: function() {
        return this.showSnackbar;
      },
      set: function() {
        this.hideSnackBarAction();
      }
    },
  },
  methods: {
    ...mapActions({
      hideSnackBarAction: 'assessments/hideSnackBar',
    }),
  }
};
</script>
