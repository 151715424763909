<template>
  <ExpansionPanels title="Online MAT User Guide (v.2.0)">
    <Panel>
      <template v-slot:header> 1.0 Maturity Assessment Tool </template>
      <template v-slot:content>
        <div>
          <Heading variant="h4">1.1 Introduction</Heading>
          <div class="image-text-frame">
            <p>
              This guide introduces you to the new Online appliedAI Maturity
              Assessment Tool (MAT) developed by appliedAI to assess the level
              of AI Maturity for companies. We will guide you through the main
              features, tool details, FAQ, and any recommendations you might
              need to successfully determine your customer's AI Maturity.
            </p>
            <ImageContainer src="guide/ug111.png" />
          </div>
        </div>
        <div>
          <Heading variant="h4">1.2 Product definition</Heading>
          <p>
            To help your customers on their transformational journey to increase
            their AI Maturity level, appliedAI has developed the Maturity
            Assessment Tool (MAT). This interactive tool allows your customer to
            assess their state of AI adoption throughout their AI Journey. The
            Maturity Assessment Tool helps to determine areas along the AI
            Journey in which your customer excel and those where they might
            still need to improve competencies or be unaware. The Maturity
            Assessment Tool helps your customer identify areas for improvement,
            build their AI roadmap and introduce AI at scale in the customer's
            company.
          </p>
        </div>
      </template>
    </Panel>
    <Panel>
      <template v-slot:header>
        2.0 Main Features/Specifications v 2.0
      </template>
      <template v-slot:content>
        <div>
          <p><b>New since V2.0 (released on 05.02.2024)</b></p>
          <ul>
            <li>Merge groups bug fixed</li>
            <li>
              Updated AF 2.5 productive, which is based on a more elaborate
              approach to find similar assessments. Also, key findings and
              updated formatting are now available.
            </li>
            <li>
              Significant speed increase (e.g. reduce download time ca.
              500-1000%) optimization
            </li>
            <li>
              New Menu help menu for User Guide 2.0, FAQs and new contact us Mail (MAT_support@appliedai.de)
            </li>
          </ul>
          <ImageContainer src="guide/ug201.png" width="200px" />
          <ul>
            <li>
              New grey-out download button when customers click to avoid
              overload queries
            </li>
            <li>
              Increased robustness and stability in the tool (download process,
              front-end improvements, Database and Infrastructure optimization)
            </li>
            <li>Other bug fixes related to N+1 queries and report download.</li>
          </ul>
          <br />
          <p>
            <b>
              The v 1.7 released on 01.07.23 includes the following minimum
              features:</b
            >
          </p>
          <ul>
            <li>
              Auth0 infrastructure implementation for security and anonymity of
              data (no e-mails, customer or company names are stored in the
              system)
            </li>
            <li>
              (Infrastructure) Implemented role management levels, including
            </li>
            <ul>
              <li>Admin account (appliedAI only) able to create/delete:</li>
              <li>
                Master and User Account (Collaborator). Creation of NH* and CP*
                roles. Within both levels:
              </li>
              <ul>
                <li>
                  Master can create/delete User roles per org. with different
                  rights.
                </li>
                <li>
                  Master can change User names (new function by 1.7 onward)
                </li>
              </ul>
            </ul>
            <li>(NH*, CP* role including Master and user accounts)</li>
            <ul>
              <li>Register in the online MAT Tool</li>
              <ul>
                <li>
                  Receive Reset Password Mail (and perform password creation)
                </li>
              </ul>
              <li>Login online to MAT Tool</li>
              <li>
                Generate MATs ( NH, CP role including Master and user accounts)
              </li>
              <ul>
                <li>Create MAT new Assessment including:</li>
                <ul>
                  <li>Add assessment name (and save)</li>
                  <li>
                    Add organization details (for Benchmark purposes) (and save)
                  </li>
                  <li>
                    Confirm if organization details data is accurate (for
                    quality data maintenance in aAI Benchmark)
                  </li>
                  <li>
                    Create a (not limited) group to perform assessments,
                    including:
                  </li>
                  <ul>
                    <li>Group name</li>
                    <li>
                      Group assessment Survey version (Technical or
                      Non-Technical, refer to section)
                    </li>
                  </ul>
                </ul>
                <li>Create survey links for partners and allow them to copy</li>
                <li>Manage Assessments management including:</li>
                <ul>
                  <li>
                    Send a reminder for the survey directly to the partner´s
                    Mail (It sends all group's links)
                  </li>
                  <li>
                    Check the number of assessments per group while running (New
                    UX design from v1.7 onward, including created on/by and
                    details bar together with complete/non-complete)
                  </li>
                  <li>
                    (1.1) Merge assessment groups (Limitation: Not available
                    until v.1.8)
                  </li>
                  <li>Closing assessment</li>
                  <li>Terms of Use acceptance feature</li>
                </ul>
              </ul>
            </ul>
            <li>Results visualization & download</li>
            <ul>
              <li>Online visualization of results</li>
              <li>
                Download raw assessment results as XLS ( v1.7 onward includes
                Maturity average per dimension and total N/A and per group)
              </li>
              <li>
                Download Assessment results (in Google Slides and PowerPoint
                mode since v1.7)
              </li>
            </ul>
            <li>Benchmark(BM) selection ( from v.1.4 onwards)</li>
            <ul>
              <li>New UX Accordion Menu selection (v1.7 onward)</li>
              <li>
                Selection of Industry and Size with Anonymity Function (Tool
                will not plot BM if not enough assessments are selected)
              </li>
              <li>
                Information warning in the menu if the Benchmark selection does
                not complete a minimum of assessment to be benchmarked.
              </li>
              <li>
                Download the embedded benchmark slide in slide 26 (or a warning
                message if not enough assessments were selected during the
                Benchmark menu. Number of selected assessment are not shown due
                to privacy function)
              </li>
            </ul>
          </ul>
          <p>
            If you want to see the full list of specifications, please check
            with your appliedAI contact.
          </p>
          <p>
            *NH=National Host as one type of user CP= Certified Partner as
            second type of user
          </p>
        </div>
        <div>
          <Heading variant="h4">2.1 Definition of every feature/specs</Heading>
          <p>
            The definition of the above specifications is defined through this
            guide. If you want to see details, click on every feature above to
            link you to the section.
          </p>
        </div>
      </template>
    </Panel>
    <Panel>
      <template v-slot:header> 3.0 Creating MAT Assessments </template>
      <template v-slot:content>
        <div>
          <p>
            In the following steps, you will learn to register, log in and
            create/modify the assessment.
          </p>
        </div>
        <div>
          <Heading variant="h4">3.1 Register to MAT</Heading>
          <p>
            You must contact your appliedAI representative to receive the
            invitation to use the tool. Please provide the organization's full
            name, the country/Area where you will be performing the MAT and the
            primary contact person mail. Be aware that this Person's Name will
            be the primary user for the tool. Therefore please consider
            selecting one of the leading responsible/managers in your company to
            be the main MAT responsible. You will receive the following mail to
            confirm your account:
          </p>
          <ImageContainer src="guide/ug311.png" width="300px" />
          <p>Please click on the button</p>
          <ImageContainer src="guide/ug312.png" />
          <p>
            You will be redirected to the following page to increase for reset
            your password:
          </p>
          <ImageContainer src="guide/ug313.png" />
          <p>
            (We use Auth0 to ensure the highest standards of security on our
            platform. For more detailed information on implemented security
            measures, contact your appliedAI contact.)
          </p>
          <p>Remember your new password should follow these requirements:</p>
          <ImageContainer src="guide/ug314.png" />
          <p>Enter your new password and re-enter and click on:</p>
          <ImageContainer src="guide/ug315.png" />
          <p>You will get a confirmation as follows when successful:</p>
          <ImageContainer src="guide/ug316.png" />
        </div>
        <div>
          <Heading variant="h4">3.2 Login to MAT</Heading>
          <p>
            The MAT can be accessed through a web-based interface. Please go to
            the following site in your browser:
          </p>
          <p>
            <a href="https://mattool.appliedai.de">mattool.appliedai.de</a>
          </p>
          <p>
            The login is the mail where you received the password reset mail.
            The password you just selected in the register action:
          </p>
          <ImageContainer src="guide/ug321.png" width="300px" />
          <p>That will redirect you to the main logged page:</p>
          <ImageContainer src="guide/ug322.png" />
          <p>Congratulations, you have successfully registered for the MAT!</p>
        </div>
        <div>
          <Heading variant="h4">3.3 Creating a New Assessment</Heading>
          <p>
            <b>Assessment definition:</b><br />To perform an assessment, you
            first need to understand the assessment structure. The assessment is
            based on a survey, containing 109 questions structured in 10
            dimensions. Each assessment can be performed in different groups.
            Every group can select a full assessment with technical questions
            (109 items) or a non-technical version (89 items).
          </p>
          <p></p>
          <p>
            <b>Assessment procedure:</b><br />Let's start creating a new
            assessment by clicking on the button as follows:
          </p>
          <ImageContainer src="guide/ug331.png" />
          <p>You will see that this page has three main sections:</p>
          <ul>
            <li>
              Assessment name (to name your evaluation). You can set your own
              format, including the date and number of assessments already
              finished for the company (in case the customer will do recurrent
              assessments through the years, for example Company X, 2024). In
              any case the data of the assessment name is encrypted at GCP and
              Database level.
            </li>
          </ul>
          <ImageContainer src="guide/ug332.png" />
          <ul>
            <li>
              Assessment details (to include the org information for benchmark
              purposes)
            </li>
          </ul>
          <ImageContainer src="guide/ug333.png" />
          <p>
            IMPORTANT: If you are unsure of the responses or do not want to
            share details, you can opt-out by selecting (The data are
            incorrect). This is important to contribute correctly to the
            anonymized Benchmark that appliedAI creates with the Database.
            Please remember to click SAVE ANSWERS. Otherwise, the data will not
            be saved. Here is the area to select anonymity:
          </p>
          <ImageContainer src="guide/ug334.png" />
          <ul>
            <li>
              <b>Groups definition</b> (to define the groups that you want to
              send the links for the survey) If the company has different
              departments or business units where you wish to assess maturity
              separately to understand differentiated perspectives, you can
              create several groups. Examples for groups: Management, Admin, IT,
              AI core team, Sales, etc.
            </li>
          </ul>
          <ImageContainer src="guide/ug335.png" />
          <p>
            Please consider there are two kinds of assessment lengths/types:
            Technical, including 109 questions and non-technical, including only
            89 questions:
          </p>
          <ImageContainer src="guide/ug336.png" />
          <p>
            <b>Including technical questions</b><br />Select this set of
            questions for a group that has a more comprehensive knowledge of AI
            technology, infrastructure, and implementation.
          </p>
          <p>
            <b>Not including technical questions.</b><br />
            To reduce the number of questions and time, you can send the general
            MAT question, not including the ones considered more AI technical.
            In that case, they are only 89.
          </p>
          <p>
            The pre-selected kind of question will be shown in blue when the tab
            is open:
          </p>
          <ImageContainer src="guide/ug337.png" />
          <p>
            Enter the group name, group description and Survey type and click:
          </p>
          <ImageContainer src="guide/ug338.png" />
          <p>
            Congratulations, you have created your first group for your
            assessment: You can always create and add additional groups to your
            assessment.
          </p>
          <ImageContainer src="guide/ug339.png" />
          <p>By clicking</p>
          <ImageContainer src="guide/ug3310.png" />
          <p>You can delete groups created</p>
          <p>
            Continue creating as many groups as you find convenient (no
            limitations, but if more than 4 are selected the names will be
            shortened in the presentation´s glossary).
          </p>
          <p>
            <b>Recommendation:</b> more than four groups might overlap in the
            later visualization. Therefore we do not recommend the above four
            groups in one single Assessment.
          </p>
          <p>
            Once you are finished with group creation, then click on Start
            Assessment:
          </p>
          <ImageContainer src="guide/ug3311.png" />
          <p>
            A warning will appear, as once the assessment and groups assessment
            after this confirmation, the data cannot be changed as follows:
          </p>
          <ImageContainer src="guide/ug3312.png" />
          <p>
            Therefore, please consider if any changes are needed (Information,
            groups, etc.). If nothing needs to be modified, then please click
            Yes.
          </p>
        </div>
        <div>
          <Heading variant="h4">3.4 Accepting the Terms of Use (ToU)</Heading>
          <p>
            On the next screen, to Confirm the Terms of Use (ToU) from the
            customer side, you need to mail them so they can confirm their
            acceptance. Please click on the ToU button as follows:
          </p>
          <ImageContainer src="guide/ug341.png" />
          <p>
            <b>Reminder:</b> The MAT survey COPY buttons and sending via mail
            are greyed out until the customer receives the mail and clicks
            accept the Terms of Use. (Refreshing the page after ToU has been
            accepted will show green buttons). See the following steps for more
            details.
          </p>
          <p>
            Please introduce the e-mail address of your contact person from the
            company (the company to be assessed) that will perform the MAT as
            follows:
          </p>
          <ImageContainer src="guide/ug342.png" />
          <p>
            After entering and clicking send, your customer should receive the
            following mail:
          </p>
          <ImageContainer src="guide/ug343.png" />
          <p>The link above leads to this page</p>
          <ImageContainer src="guide/ug344.png" />
          <p>
            Please ensure that the customer is aware of this mail and that they
            confirm the ToU. Once this is established from the customer side,
            they should receive a link confirmation.
          </p>
          <ImageContainer src="guide/ug345.png" />
          <p>
            If your customer confirms the Terms of Use during the process and
            you refresh the site and go to Configure assessment, you will be
            able to see the Collector URL activated as follows:
          </p>
          <ImageContainer src="guide/ug346.png" />
          <p>
            By clicking on Copy, you will see a notification that confirms the
            link was Copy to your clipboard as follows:
          </p>
          <ImageContainer src="guide/ug347.png" />
          <p>
            Once you have the link, you can use it for your mail notification to
            customers. Remember, every group is different.
          </p>
          <p>
            <b>NOTE:</b> Copying the link (Collector URL) and inputting it into
            your browser allows you to start on the MAT immediately
          </p>
          <p>
            <b>Recommendation:</b> If you want to send all assessment URL at
            once you can use always use the function “send reminder” (see
            section 4.1) in the main assessment page.
          </p>
        </div>
        <div>
          <Heading variant="h4">3.5 Account Settings</Heading>
          <ImageContainer src="guide/ug351.png" />
          <p>
            By clicking the “HELP” button, your email will be automatically
            opened to a draft email to our MAT support address. You can write
            directly there if you have any issues or questions with the MAT
            tool.
          </p>
          <p>
            By clicking the “MY ACCOUNT” button, you can see the different
            settings for your account and change of the downloaded ppt format as
            follows:
          </p>
          <ImageContainer src="guide/ug352.png" />
          <p>
            The “Download Report slides” allows you to optimize the format of
            the downloaded PowerPoint. If you target to open the slides with
            Microsoft PowerPoint, please select PowerPoint. If you plan to
            upload the downloaded slides to Google Drive, then select the Google
            slides option. In case the visualizations in the generated report
            are plotted wrongly, you might need to adjust the file format as
            stated above.
          </p>
        </div>
      </template>
    </Panel>
    <Panel>
      <template v-slot:header> 4.0 Managing Assessment </template>
      <template v-slot:content>
        <div>
          <p>
            Features such as sending reminders, configuring or closing the
            Maturity Assessment.
          </p>
        </div>
        <div>
          <Heading variant="h4">4.1 Sending reminder</Heading>
          <p>
            New online MAT allows you to send reminders, including the link per
            group. Please remember the mail will include all groups, so the user
            must be aware of which group she/she/she/it belongs to. You can find
            the reminder option in the preliminary assessment list as follow:
          </p>
          <ImageContainer src="guide/ug411.png" />
          <p>
            To start sending reminders per Assessment, please click on the
            following:
          </p>
          <ImageContainer src="guide/ug412.png" />
          <p>
            It will ask you to include the mail you want to send the reminder to
            as follows:
          </p>
          <ImageContainer src="guide/ug413.png" />
          <p>
            Please enter the mail you want to send the reminder mail to and
            click on SEND THE LINKS VIA E-MAIL. The system will send the mail as
            follows:
          </p>
          <ImageContainer src="guide/ug414.png" />
          <p><b>Limitation 1:</b> Available in English and German</p>
          <p>
            <b>Limitation 2:</b> Send reminder include all groups, so secure to
            highlight to the surveyed person to which group they belong.
          </p>
        </div>
        <div>
          <Heading variant="h4">4.2 Configure Assessment</Heading>
          <p>
            You can configure specific assessments on the pipeline in the
            primary assessment list window.
          </p>
          <ImageContainer src="guide/ug421.png" />
          <p>
            By clicking on Configure, you can see the selected groups to copy
            separate links as follows:
          </p>
          <ImageContainer src="guide/ug422.png" />
          <p>
            <b>Warning:</b> You cannot add new groups once the Assessment is
            created.
          </p>
        </div>
        <div>
          <Heading variant="h4">4.3 Closing assessment</Heading>
          <p>To be able to close the assessments, the following can be done:</p>
          <ImageContainer src="guide/ug431.png" />
          <p>
            Please remember that once you complete the evaluation, you cannot
            include groups/evaluation surveys/etc. Therefore seal the review
            ONLY when you are sure all surveys have been filled for the
            organization members. A warning will appear:
          </p>
          <ImageContainer src="guide/ug432.png" />
          <p>
            Recalling the database and showing the MAT has been calculated might
            take some seconds (10-15 sec)
          </p>
        </div>
      </template>
    </Panel>
    <Panel>
      <template v-slot:header>5.0 Assessment Results</template>
      <template v-slot:content>
        <div>
          <Heading variant="h4">5.1 Opening results:</Heading>
          <p>
            Once the assessment is closed, you might see a change in the
            Assessment Results as follows:
          </p>
          <ImageContainer src="guide/ug511.png" />
          <p>By clicking on RESULTS, you will see the tracking data:</p>
          <ImageContainer src="guide/ug512.png" />
          <p>
            By every dimension (and clicking in Detailed analysis), you will be
            able to visualize the results as follows:
          </p>
          <ImageContainer src="guide/ug513.png" />
          <p>
            (Limitation: This information can be copied/pasted for your final
            customer report)
          </p>
          <p>
            Not only will you be able to view the results by every dimension,
            but you will also get a Maturity level explanation and some
            resources recommendations by scrolling down:
          </p>
          <ImageContainer src="guide/ug514.png" />
          <p>
            The recommendations are just a general guide, and we always
            recommend creating tailored Key findings/recommendations for your
            custom reports. National Hosts and Certified Partners should be able
            to develop these recommendations based on Report Results from the
            different groups surveyed.
          </p>
          <ImageContainer src="guide/ug515.png" />
          <p>And also appliedAI further support to keep growing:</p>
          <ImageContainer src="guide/ug516.png" />
        </div>
        <div>
          <Heading variant="h4">5.2 Downloading report slides:</Heading>
          <p>You can download the report slides with the below button:</p>
          <ImageContainer src="guide/ug521.png" />
          <p>
            You can also go into your account settings and change how the
            presentation should be optimized (either for Google Slides or
            Microsoft PowerPoint). Also, consider selecting the Benchmark you
            prefer to have in your report (See Section 5.3)
          </p>
          <p>
            After choosing your preferred method of delivery, you can download
            the summary results. Here is an example:
          </p>
          <ImageContainer src="guide/ug522.png" />
          <ImageContainer src="guide/ug523.png" />
        </div>
        <div>
          <Heading variant="h4">5.3 Selecting benchmark:</Heading>
          <p>
            In order to select the Benchmark, you can filter out by industry and
            size in the Benchmark Menu:
          </p>
          <ImageContainer src="guide/ug531.png" />
          <p>
            By default, the size and Industry of the initial assessment
            information are pre-filtered (shown in blue) when you open the
            size/industry drop-down menu as follows:
          </p>
          <ImageContainer src="guide/ug532.png" />
          <p>
            You can select the one you want to include in your Benchmark
            comparison. The apply button will not be selectable until you change
            the settings:
          </p>
          <ImageContainer src="guide/ug533.png" />
          <p>
            If your selection includes too few assessments a warning will be
            shown as follows:
          </p>
          <ImageContainer src="guide/ug534.png" />
          <p>
            If you download the slides after getting this message, no Benchmark
            will be plotted for anonymity reasons. To correct this you can
            change your selection in the Benchmark menu in order to get this
            message:
          </p>
          <ImageContainer src="guide/ug535.png" />
        </div>
        <div>
          <Heading variant="h4">5.4 Merging Groups:</Heading>
          <p>
            If some of the assessed groups did not collect sufficient answers to
            guarantee anonymity (n&lt;5), groups with the same set of questions
            can be merged. This will collect answers from several groups and
            display them as the answers of one group in the report, to assure
            anonymity. To achieve this, one simply has to go to the group to be
            merged (can be seen in the menu), and select the group(s) to merge
            into in the drop-down menu next to the group.
          </p>
          <p>
            <b
              >Note: Merging is IRREVERSIBLE, therefore proceed with caution!</b
            >
          </p>
          <ImageContainer src="guide/ug541.png" />
        </div>
        <div>
          <Heading variant="h4">5.5 Autofindings:</Heading>
          <p>
            The feature “Autofindings” which has been newly implemented in the
            Maturity Assessment Tool, uses maturity assessments from past
            projects, to automatically generate findings and recommendations for
            incoming assessments. This is based on the highest similarity
            between the new assessment and all the past assessments, selecting
            the observations and recommendations from the most similar past
            assessment for the incoming assessment. This significantly reduces
            the time spent analyzing the maturity of companies internally and
            helps onboarding of new colleagues into the assessment process, as a
            first set of observations and recommendations is already provided.
          </p>
          <p>
            To use the automatic generation of observations and recommendations,
            select the “Autofindings included” button. This will populate the
            result slides with fitting observations and recommendations. Make
            sure to thoroughly double-check and adjust these observations and
            recommendations before using them for clients.
          </p>
          <ImageContainer src="guide/ug551.png" />
        </div>
        <div>
          <Heading variant="h4">5.6 Downloading summary data:</Heading>
          <p>
            Following up on the industry benchmark, in case you’d like to do
            your own manual searching/operations on the data, you are able to
            download the summary file as an Excel document.
          </p>
          <ImageContainer src="guide/ug561.png" />
          <p>
            This produces an Excel document that documents the scores for each
            section, the total average for each question and then the score for
            each question by the user.
          </p>
          <ImageContainer src="guide/ug562.png" />
          <p>
            In some cases, customers request raw data to see detailed insight of
            the report (Number of un-answered items, when a participant stopped
            answering, dedicated maturity levels. In case you have any other
            questions, do not hesitate to contact your partner at appliedAI or
            reach us at
          </p>
          <p>
            <a href="mailto:MAT_support@appliedai.de"
              >MAT_support@appliedai.de</a
            >
          </p>
          <p>
            V2.0 User guide for mattool.appliedai.de, February 2024. appliedAI
            all rights reserved.
          </p>
        </div>
      </template>
    </Panel>
  </ExpansionPanels>
</template>

<script>
import ImageContainer from '@/components/ImageContainer';
import Heading from '@/components/Heading';
import ExpansionPanels from '@/components/ExpansionPanels';
import Panel from '@/components/Panel';

export default {
  name: 'userGuide',
  components: {
    ImageContainer,
    Heading,
    ExpansionPanels,
    Panel,
  },
};
</script>

<style scoped>
li {
  font-size: 1rem;
  color: black;
  font-weight: 400;
}

p {
  padding: 5px;
}

.image-text-frame {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.image-text-frame >>> .image-wrapper {
  padding: 5px;
  padding-right: 10px;
}
</style>
