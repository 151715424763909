<template>
  <div>
    <div class="statusViz" v-bind:id="`status_${item.position}`">
      <div class="tooltip"></div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3/dist/d3.min';

// const getGroupNames = (data) => Object.keys(data.rating);

/** Transform API response into format suitable for presentation */
const processRatingAverages = (data, groupNames) => {
  let statusData = [];
  // const groupNames = getGroupNames(data);
  for (const groupName of groupNames.filter((g) => g !== 'all_participants')) {
    const rating = data.rating[groupName]['rating__avg'] || 0;
    if (rating <= 0) {
      continue;
    }
    statusData.push({
      group: groupName,
      /*
       TODO: Maybe make the backend return the rating directly under the group name field
        instead of under the rating__avg field inside the group name field
      */
      rating,
    });
  }
  return statusData;
};

/** Position callout tooltip for a group at the element */
const moveCallout = (callout, x, y, fill, label, rating) => {
  // Offset high ratings to the left to prevent clipping
  const xOffset = rating > 3.8 ? -32 : 16;
  const left = parseFloat(x) + xOffset;
  const top = parseFloat(y) - 28;

  callout
    .style('top', `${top}px`)
    .style('left', `${left}px`)
    .style('visibility', 'visible')
    .style('z-index', 9999)
    .style('background-color', fill)
    .text(label);
};

export default {
  bodyClass: 'landing-page',
  data: () => ({
    data: {},
  }),
  props: {
    item: {
      type: Object,
      required: true,
    },
    groupNames: {
      type: Array,
      required: true,
    },
    color: {
      type: Function,
      required: true,
    }
  },
  created() {
    this.data = this.item;
  },
  mounted() {
    this.createStatus(this.data);
  },
  methods: {
    determineStartingPointFromTop(numberOfElementsWithTheSameResult) {
      return numberOfElementsWithTheSameResult===2? 16 : (numberOfElementsWithTheSameResult===3? 5 : 0);
    },
    determineVerticalOffset(element, averageRating, numberOfElementsWithTheSameResult, numberOfGroups) {
      // if there are only 2 groups and they have the same result, position the dots on each edge of the thick line (that shows the 'total' result)
      if (numberOfGroups===2 && (Math.abs(element.rating - averageRating) < 0.09)){
        return { 
          offsetForElement: 7*numberOfElementsWithTheSameResult, offsetForOtherElement: -7*numberOfElementsWithTheSameResult
        };
      } 
      return { 
        offsetForElement: numberOfElementsWithTheSameResult, 
        offsetForOtherElement: 11*numberOfElementsWithTheSameResult
      };
    },
    createStatus: function (data) {
      // const groupNames = getGroupNames(data);
      let statusData = processRatingAverages(data, this.groupNames);
      let averageRating = [
        {
          group: 'All participants',
          rating: data.rating['all_participants']['rating__avg'] || 0,
        },
      ];

      const domItem = `#status_${data.position}`; // get current dom element
      const width = 290;
      const height = 80;
      const paddingX = 10;
      const minRating = 1;
      const maxRating = 4;
      const midRating = (minRating + maxRating) / 2;

      // removes previously drawn data
      const svgRemove = d3.selectAll(domItem);
      svgRemove.selectAll('svg').remove();

      // create svg
      const parent = d3.select(domItem);
      const callout = parent.select('.tooltip');
      const svg = parent
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .attr('viewbox', [0, 0, width, height])
        .append('g');

      // calculate xAxis scale
      let x = d3
        .scaleLinear()
        .domain([minRating, maxRating])
        .range([paddingX, width - paddingX]);

      // define color scale
      // add xAxis labels
      if (this.data.position === 1) {
        const addLabels = (labels, y) => {
          svg
            .selectAll('mylabels')
            .data(labels)
            .enter()
            .append('text')
            .attr('x', (d, i) => x(i + 1))
            .attr('y', y)
            .style('fill', 'black')
            .text((d) => d)
            .attr(
              'text-anchor',
              (d, i) => ['left', 'middle', 'middle', 'end'][i]
            )
            .attr('font-size', '11');
        };
        addLabels(['strongly', 'mostly', 'mostly', 'strongly'], height * 0.15);
        addLabels(
          ['disagree', 'disagree', 'agree', 'agree'],
          height * 0.15 + 12
        );
      }

      // draw xAxis grid lines
      for (let i = 1; i <= 4; i++) {
        svg
          .append('line')
          .style('stroke', 'black')
          .style('opacity', 0.1)
          .style('stroke-width', 1)
          .attr('x1', x(i))
          .attr('y1', height * 0.41)
          .attr('x2', x(i))
          .attr('y2', height);
      }

      // draw single bold xAxis grid line at center
      svg
        .append('line')
        .style('stroke', 'black')
        .style('stroke-width', 1.5)
        .attr('x1', x(midRating))
        .attr('y1', height * 0.41 + 10)
        .attr('x2', x(midRating))
        .attr('y2', height - 10);

      // draw bar for overall status
      if (averageRating[0].rating) {
        const avgBarDefaultOpacity = 0.5;
        const avgBarHighlightOpacity = 0.75;
        svg
          .selectAll('rect')
          .data(averageRating)
          .enter()
          .append('rect')
          .attr('x', (d) => Math.min(x(d.rating), x(midRating)))
          .attr('y', height / 2 + 8)
          .attr('width', (d) => Math.abs(x(d.rating) - x(midRating)))
          .attr('height', height * 0.2)
          .attr('fill', (d) => (d.rating > midRating ? '#008000' : '#808080'))
          .attr('opacity', avgBarDefaultOpacity)
          .on('mouseover', function (ev, d) {
            const me = d3.select(this);
            me.transition()
              .duration(200)
              .attr('opacity', avgBarHighlightOpacity);

            // Position tooltip next to the item
            moveCallout(
              callout,
              me.attr('x'),
              me.attr('y'),
              me.style('fill'),
              d.group,
              d.rating
            );
          })
          .on('mouseout', function () {
            d3.select(this)
              .transition()
              .duration(200)
              .attr('opacity', avgBarDefaultOpacity);
            callout.style('visibility', 'hidden');
          });
      }

      statusData.forEach((element) => {
        let numberOfElementsWithTheSameResult = 1;

        statusData.forEach((otherElement) => {
          if (element.group === otherElement.group) {
            return;
          }
          // if two elements have very close values for the rating, the dots should not overlap
          if (Math.abs(otherElement.rating - element.rating) < 0.09) {
            let offsets = this.determineVerticalOffset(element, averageRating[0].rating, numberOfElementsWithTheSameResult, statusData.length);
            if(!element.y_shift) {
              element.y_shift = offsets.offsetForElement;
            }
            if(!otherElement.y_shift) {
              otherElement.y_shift = offsets.offsetForOtherElement;
            }
            numberOfElementsWithTheSameResult++;
          }
        });

        if (!element.y_shift) {
          element.y_shift= 16;
        }
        element.startAt = this.determineStartingPointFromTop(numberOfElementsWithTheSameResult);
      });
      // draw dots for group specific status
      svg
        .selectAll('mycircle')
        .data(statusData)
        .enter()
        .append('circle')
        .attr('class', 'node')
        .attr('cx', (d) => x(d.rating))
        .attr(
          'cy',
          (d) => height / 2 + d.startAt + d.y_shift
        )
        .attr('r', '5')
        .style('fill', (d) => this.color(d.group))
        .attr('stroke', 'black')
        .style('stroke-width', 1)
        .on('mouseover', function (ev, d) {
          const me = d3.select(this);
          me.transition().duration(200).attr('opacity', 0.6);
          moveCallout(
            callout,
            me.attr('cx'),
            me.attr('cy'),
            me.style('fill'),
            d.group,
            d.rating
          );
        })
        .on('mouseout', function () {
          const me = d3.select(this);
          me.transition().duration(200).attr('opacity', 1);
          callout.style('visibility', 'hidden');
        });
    },
  },
};
</script>

<style scoped>
.tooltip {
  position: absolute;
  visibility: hidden;
  color: white;
  background-color: #0d47a1;
  padding: 2px;
  border-radius: 4px;
  z-index: 99999;
  width: 70px;
  overflow-wrap: break-word;
  text-align: left;
}
</style>
