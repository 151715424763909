var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "main main-raised" },
        [
          _c("loading-indicator", { attrs: { "is-loading": _vm.loading } }),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "v-responsive",
                { staticClass: "md-alignment-centered" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_vm._v("← Back")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-50 md-small-size-100 mx-auto"
                    },
                    [
                      _c("h3", { staticClass: "text-primary" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _c("h4", { staticClass: "text-primary" }, [
                        _vm._v(
                          _vm._s(
                            _vm.organization && _vm.organization.details
                              ? _vm.organization.details.name
                              : ""
                          )
                        )
                      ]),
                      _c(
                        "form",
                        {
                          staticClass: "md-layout",
                          attrs: { novalidate: "" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.createAccount.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "md-card",
                            [
                              _c(
                                "md-card-content",
                                [
                                  _c(
                                    "md-field",
                                    {
                                      class:
                                        !_vm.$v.name.required &&
                                        _vm.submitStatus === "ERROR"
                                          ? "md-invalid"
                                          : ""
                                    },
                                    [
                                      _c("label", [_vm._v("Name:")]),
                                      _c("md-input", {
                                        model: {
                                          value: _vm.name,
                                          callback: function($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name"
                                        }
                                      }),
                                      !_vm.$v.name.required &&
                                      _vm.submitStatus === "ERROR"
                                        ? _c(
                                            "span",
                                            { staticClass: "md-error" },
                                            [_vm._v("Name is a required field")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-field",
                                    {
                                      class:
                                        (!_vm.$v.email.required ||
                                          !_vm.$v.email.email) &&
                                        _vm.submitStatus === "ERROR"
                                          ? "md-invalid"
                                          : ""
                                    },
                                    [
                                      _c("label", [_vm._v("E-mail address:")]),
                                      _c("md-input", {
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      }),
                                      !_vm.$v.email.required &&
                                      _vm.submitStatus === "ERROR"
                                        ? _c(
                                            "span",
                                            { staticClass: "md-error" },
                                            [
                                              _vm._v(
                                                "E-mail address is a required field"
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.$v.email.email &&
                                      _vm.submitStatus === "ERROR"
                                        ? _c(
                                            "span",
                                            { staticClass: "md-error" },
                                            [
                                              _vm._v(
                                                "Please insert valid email address"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-field",
                                    {
                                      class:
                                        !_vm.$v.comment.required &&
                                        _vm.submitStatus === "ERROR"
                                          ? "md-invalid"
                                          : ""
                                    },
                                    [
                                      _c("label", [_vm._v("Comments:")]),
                                      _c("md-input", {
                                        model: {
                                          value: _vm.comment,
                                          callback: function($$v) {
                                            _vm.comment = $$v
                                          },
                                          expression: "comment"
                                        }
                                      }),
                                      !_vm.$v.comment.required &&
                                      _vm.submitStatus === "ERROR"
                                        ? _c(
                                            "span",
                                            { staticClass: "md-error" },
                                            [
                                              _vm._v(
                                                "Comment is a required field"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "md-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        type: "submit",
                                        loading: _vm.savingData,
                                        disabled: _vm.savingData
                                      }
                                    },
                                    [_vm._v("Save")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("br")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("snackbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }