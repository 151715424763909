import Vue from 'vue';

// import START_LOCATION from 'vue-router';
import { 
  MainPage, 
  Landing, 
  Config, 
  SuccessfullCreation, 
  UserCreateAccount, 
  OrganizationCreate, 
  OrganizationView,
  AccountDetails, 
  Licenses, 
  InvalidPage, 
  PageNotFound, 
  TermsOfUse,
  UserChangeAccountType,
  PreviewTermsOfUse,
  ClusterDetails,
  UserGuide,
  FAQ,
} from './views/';
import Router from 'vue-router';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import { authGuard } from './auth/authGuard';
import { Roles } from './auth/roles';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.AccountMaster, Roles.AccountUser, Roles.Admin] },
      components: { default: MainPage, header: MainNavbar, footer: MainFooter },
      props: {
        default: true,
        header: true,
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/config/:assessment_id',
      name: 'config',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.AccountMaster, Roles.AccountUser, Roles.Admin] },
      components: { default: Config, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/landing/:assessment_id',
      name: 'landing',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.AccountMaster, Roles.AccountUser] },
      components: { default: Landing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/clusterdetails/:assessment_id/:cluster_id',
      name: 'clusterdetails',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.AccountMaster, Roles.AccountUser] },
      components: {
        default: ClusterDetails,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/success/:assessment_id',
      name: 'successfulCreation',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.AccountMaster, Roles.AccountUser] },
      components: {
        default: SuccessfullCreation,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/terms-of-use/:assessment_id',
      name: 'previewTermsOfUse',
      components: {
        default: PreviewTermsOfUse,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/accepted-terms-of-use/:assessment_id',
      name: 'termsOfUse',
      components: {
        default: TermsOfUse,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/create-organization/:organization_type',
      name: 'createOrganization',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.Admin] },
      components: {
        default: OrganizationCreate,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/account',
      name: 'accountDetails',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.AccountMaster, Roles.AccountUser] },
      components: {
        default: AccountDetails,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/organization/:id',
      name: 'organizationView',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.Admin] },
      components: {
        default: OrganizationView,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/change-account-type/:user_id',
      name: 'UserChangeAccountType',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.Admin] },
      components: {
        default: UserChangeAccountType,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/licenses',
      name: 'licenses',
      components: {
        default: Licenses,
        header: MainNavbar,
        footer: MainFooter,
      },
    },
    {
      path: '/create/:organization_id/:account_type',
      name: 'userAccountCreate',
      beforeEnter: authGuard,
      meta: { authorize: [Roles.Admin, Roles.AccountMaster] },
      components: {
        default: UserCreateAccount,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/faq',
      name: 'faq',
      components: {
        default: FAQ,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/invalid',
      name: 'invalid',
      components: {
        default: InvalidPage,
        header: MainNavbar,
        footer: MainFooter,
      },
    },
    {
      path: '/user-guide',
      name: 'userGuide',
      components: { default: UserGuide, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    { path: '*', name: 'pageNotFound', component: PageNotFound },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next)=> {
  if(!from.name) {
    //if reload page, set main table pagination to defaults
    sessionStorage.removeItem('perPage');
    sessionStorage.removeItem('currentPage');
    sessionStorage.removeItem('searchByValue');
  }
  next();
});
export default router;
