export const questionsAndRatingsByClusterId = (state, cluster_id) => { 
  let tt = state.maturityData.filter(matData => matData.id==cluster_id);
  return tt.questionsAndRatings;
};
  
export const getIsDataReal = (state) => {
  return state.assessment.is_data_real;
};

export const getAssessmentName = (state) => {
  return state.assessment.name;
};

export const getOrganizationForAssessment = (state) => {
  return state.assessment.organization;
};
