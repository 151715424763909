var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.userRole !== "Admin"
      ? _c(
          "div",
          [
            _c("Index", {
              attrs: { userRole: _vm.userRole, isLoading: _vm.isLoading }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.userRole === "Admin"
      ? _c(
          "div",
          [_c("AccountDetails", { attrs: { userRole: _vm.userRole } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }